import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentUtilsService } from '../services/component-utils.service';
import { RssFeedValidationService } from '../services/rss-feed-validation.service';

@Component({
    selector: 'template-component-rss',
    templateUrl: './rss.component.html',
    styleUrls: ['./rss.component.scss'],
    standalone: false
})
export class RssComponent {

  spinner = false;
  componentId;
  maxItems;
  feedUrl;
  validationResult;

  constructor( private elementRef: ElementRef,
      private componentsFactory: ComponentsService, 
      private attributeDataFactory: AttributeDataService,
      private componentUtils: ComponentUtilsService,
      private rssFeedValidation: RssFeedValidationService,
      private changeDetectorRef: ChangeDetectorRef) {
    componentsFactory.registerDirective({
      type: 'rise-data-rss',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
        this.saveFeed(); // validate Feed URL
      }
    });
  }

  saveFeed () {
    if (this._validateFeedUrl()) {
      this._isFeedParsable();
    }
    this.changeDetectorRef.detectChanges();
  }

  saveMaxItems() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'maxitems', parseInt(this.maxItems, 10));
  }

  _load() {
    var maxItems = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'maxitems');

    this.feedUrl = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'feedurl');
    this.maxItems = maxItems ? maxItems.toString() : '1';
  }

  _validateFeedUrl() {
    //clear the error
    this.validationResult = '';

    var _feed = !this.feedUrl ? '' : this.feedUrl.trim();
    if (_feed === '') {
      //empty string is allowed
      return true;
    }

    //check if feed url is valid format
    if (this.componentUtils.isValidUrl(_feed)) {
      return true;
    }

    this.validationResult = 'INVALID_URL';
    return false;
  }

  _isFeedParsable() {
    this.spinner = true;

    this.rssFeedValidation.isParsable(this.feedUrl)
      .then((result) => {
        if (result === 'VALID') {
          this.attributeDataFactory.setAttributeData(this.componentId, 'feedurl', this.feedUrl);

          this._isFeedValid();
        } else {
          this.validationResult = result;
        }
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  _isFeedValid() {
    this.rssFeedValidation.isValid(this.feedUrl)
      .then((result) => {
        this.validationResult = result;
      });
  }

}
