import { Component, ElementRef } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

@Component({
    selector: 'template-component-branding',
    templateUrl: './branding.component.html',
    styleUrls: ['./branding.component.scss'],
    standalone: false
})
export class BrandingComponent {
  public componentId: string;
  public defaultType: string;
  public type: string;
  public timezoneType: string;
  public timezone: string;
  public timeFormat: string;
  public dateFormat: string;

  brandingAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_BRANDING);

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private featuresService: FeaturesService) {

    this.componentsFactory.registerDirective({
      type: 'rise-branding',
      element: this.elementRef.nativeElement
    });
  }

  editLogo() {
    if (this.brandingAvailable) {
      this.componentsFactory.editComponent({
        type: 'rise-image-logo'
      });
    }
  }

  editColors() {
    if (this.brandingAvailable) {
      this.componentsFactory.editComponent({
        type: 'rise-branding-colors'
      });
    }
  }

  showUpgradePlanModal () {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_BRANDING);
  }

}
