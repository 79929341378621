import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, ReplaySubject, take } from 'rxjs';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';

export interface YoutubeValidationResult {
  status: 'VALID' | 'INVALID' | 'NOT_PUBLIC' | 'VIDEO_NOT_FOUND' | 'NOT_EMBEDDABLE' | 'PLAYLIST_NOT_FOUND' | 'CHANNEL_NOT_FOUND';
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class YouTubeValidationService {
  private static readonly API_KEY = 'AIzaSyDsKn1Xo09KnO_vVatbPlqJ7cwZgI5wsyk';
  static readonly VIDEO_URL = `https://youtube.googleapis.com/youtube/v3/videos?part=contentDetails%2Cstatus&key=${YouTubeValidationService.API_KEY}&id=`;
  static readonly PLAYLIST_URL = `https://youtube.googleapis.com/youtube/v3/playlists?part=contentDetails%2Cstatus&key=${YouTubeValidationService.API_KEY}&id=`;
  static readonly LIVESTREAM_URL = `https://youtube.googleapis.com/youtube/v3/channels?part=snippet&key=${YouTubeValidationService.API_KEY}&forHandle=`;

  constructor(private httpClient: HttpClient) {}

  async validate(url: string): Promise<YoutubeValidationResult> {
    if (!url) return Promise.resolve({ status: 'VALID' });

    const videoData = this.parseYouTubeUrl(url);

    if (videoData.videoId) {
      return await this.validateVideoId(videoData.videoId);
    } else if (videoData.playlistId) {
      return await this.validatePlaylistId(videoData.playlistId);
    } else if (videoData.userHandle) {
      return await this.validateUserHandle(videoData.userHandle);
    } else {
      return Promise.resolve({ status: 'INVALID' });
    }
  }

  private async validateVideoId(videoId: string): Promise<YoutubeValidationResult> {
    try {
      const response = await lastValueFrom(this.httpClient.get<{ items: any[] }>(YouTubeValidationService.VIDEO_URL + videoId));

      const video = response.items?.[0];
      if (!video) return { status: 'VIDEO_NOT_FOUND' };

      if (video.kind === 'youtube#video' && video.status && !video.status.embeddable) {
        return { status: 'NOT_EMBEDDABLE' };
      }

      return { status: 'VALID' };
    } catch (err) {
      return this.validateApiError(err);
    }
  }

  private async validatePlaylistId(playlistId: string): Promise<YoutubeValidationResult> {
    try {
      const response = await lastValueFrom(this.httpClient.get<{ items: any[] }>(YouTubeValidationService.PLAYLIST_URL + playlistId));

      const playlist = response.items?.[0];
      if (!playlist) return { status: 'PLAYLIST_NOT_FOUND' };

      return { status: 'VALID' };
    } catch (err) {
      return this.validateApiError(err);
    }
  }

  private async validateUserHandle(userHandle: string): Promise<YoutubeValidationResult> {
    try {
      const response = await lastValueFrom(this.httpClient.get<{ items: any[] }>(YouTubeValidationService.LIVESTREAM_URL + userHandle));

      const channel = response.items?.[0];
      if (!channel) return { status: 'CHANNEL_NOT_FOUND' };

      return { status: 'VALID', data: channel };
    } catch (err) {
      return this.validateApiError(err);
    }
  }

  private validateApiError(err: { status: number }): YoutubeValidationResult {
    if (err.status === 401) return { status: 'NOT_PUBLIC' };
    return { status: 'INVALID' };
  }

  private parseYouTubeUrl(url: string) {
    let videoId = this.getUrlParam(url, 'v');
    const playlistId = this.getUrlParam(url, 'list');

    // Video ID can be part of the URL path. Examples:
    // "https://youtu.be/11122233344",
    // "https://www.youtube.com/embed/11122233344"
    // "https://www.youtube.com/live/HvzFWKqcKLU?si=TadMutm9146bTRs2"
    //
    // Channel Livestream can be set as the channel URL with the User Handle. Example:
    // https://www.youtube.com/@RiseVision
    if (url && !videoId) {
      const regExpUserHandle = /youtube.com\/(@[^#&?]*)/;
      const matchUserHandle = url.match(regExpUserHandle);
      if (matchUserHandle && matchUserHandle[1]) return { userHandle: matchUserHandle[1] };

      const regExpVideoId = /^.*((youtu.be\/)|(v\/)|(live\/)|(embed\/(?!videoseries)))([^#&?]*).*/;
      const matchVideoId = url.match(regExpVideoId);
      videoId = matchVideoId && matchVideoId[6].length === 11 ? matchVideoId[6] : null;
    }

    return { videoId, playlistId };
  }

  private getUrlParam(url: string, param: string) {
    try {
      return new URL(url).searchParams.get(param);
    } catch (error) {
      return null;
    }
  }
}
