import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'initials-badge',
    templateUrl: './initials-badge.component.html',
    styleUrl: './initials-badge.component.scss',
    standalone: false
})
export class InitialsBadgeComponent implements OnChanges {

  private static readonly COLORS = [
    // Derived from OKLCH with C=0.18 and L=0.562 (L-adjusted so WACG white contrast ~ 4.55)
    '#cf4063',
    '#d14241',
    '#ce4906',
    '#c55200',
    '#b75d00',
    '#a56800',
    '#8d7300',
    '#717c00',
    '#4b8400',
    '#00881b',
    '#008845',
    '#008663',
    '#00837f',
    '#008099',
    '#007db2',
    '#007ac8',
    '#0075d8',
    '#446ee0',
    '#6a66e0',
    '#865dd7',
    '#9d55c8',
    '#b04db4',
    '#bf479c',
    '#ca4280'
  ];

  @Input() name: string;
  @Input() size: number = 24;
  background: string = '#fff';
  initials: string = '';

  get fontSize() {
    return Math.max(Math.round(this.size * 0.4), 10) + 'px';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['name'] && this.name) {
      this.initials = this.name
        .split(' ')
        .filter(n => n.length > 0)
        .slice(0, 2)
        .map(n => n[0].toUpperCase()).join('');

      // Reduce the name to a value between 0 and 24
      let n = 0;
      for (let i = 0; i < this.name.length; i++) {
        n += this.name.charCodeAt(i);
      }
      // Multiply by 7 so that single-character differences don't map to adjacent colors
      n = n * 7 % 24;

      this.background = InitialsBadgeComponent.COLORS[n];
    }
  }
}
