import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TaxExemptionService } from '../../services/tax-exemption.service';

@Component({
    selector: 'tax-exemption',
    templateUrl: './tax-exemption.component.html',
    styleUrls: ['./tax-exemption.component.scss'],
    standalone: false
})
export class TaxExemptionComponent {

  @Input() showCancel!: any;

  @ViewChild('taxExemptionForm')
  public taxExemptionForm: NgForm;

  @ViewChild('inputExemption')
  private inputExemption: ElementRef;

  taxExemption: any = {};

  constructor(private changeDetectorRef : ChangeDetectorRef,
    public taxExemptionFactory: TaxExemptionService) {
      this.taxExemption = this.taxExemptionFactory.taxExemption;
  }

  submit() {
    if (this.taxExemptionForm.invalid) {
      return;
    }

    this.taxExemptionFactory.submitTaxExemption();
  }

  selectFile() {
    this.inputExemption.nativeElement.click();
  }

  removeFile() {
    this.taxExemption.file = null;
    this.inputExemption.nativeElement.value = '';
  }

  setFile() {
    this.taxExemption.file = this.inputExemption.nativeElement.files[0];

    this.changeDetectorRef.detectChanges();
  }

  isFieldInvalid(fieldName) {
    if (!this.taxExemptionForm) {
      return false;
    }
    var field = this.taxExemptionForm.form.controls[fieldName];

    return !!field && (field.dirty || this.taxExemptionForm.submitted) && field.invalid;
  }

}