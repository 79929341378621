import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UpdateSubscriptionService } from '../../services/update-subscription.service';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';

@Component({
    selector: 'update-subscription-success',
    templateUrl: './update-subscription-success.component.html',
    styleUrls: ['./update-subscription-success.component.scss'],
    standalone: false
})
export class UpdateSubscriptionSuccessComponent {

  @Input() purchaseAction;
  @Input() planName;
  @Output() closeEvent = new EventEmitter<any>();

  constructor(public updateSubscriptionService: UpdateSubscriptionService,
    public subscriptionService: SubscriptionService) { }

}
