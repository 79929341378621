import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  static readonly PLANS_LIST = [{
      name: 'Free',
      type: 'free',
      productId: '000',
      productCode: '000',
      status: 'Active',
      proLicenseCount: 0,
      tier: 0
    },
    // 2022 plans
    {
      name: 'Basic Display Licenses',
      type: 'volume basic 2022',
      isDisplayLicensePlan: true,
      productId: '2400',
      productCode: 'e239fe83aa3203eab670e54434eb4285204d6f51',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 10
    }, {
      name: 'Education/Non-Profit Basic Display Licenses',
      type: 'volume basic education 2022',
      isDisplayLicensePlan: true,
      productId: '2400',
      productCode: '5e261e0fc26d4d019271aa42998ac99349fb02a3',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 10
    }, {
      name: 'Advanced Display Licenses',
      type: 'volume advanced 2022',
      isDisplayLicensePlan: true,
      productId: '2410',
      productCode: 'be0882ea2bb856937e223809c08fc65fc98bdbde',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 20
    }, {
      name: 'Education/Non-Profit Advanced Display Licenses',
      type: 'volume advanced education 2022',
      isDisplayLicensePlan: true,
      productId: '2410',
      productCode: '1099cb4300f1e8072c694b23afd5771882b93f08',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 20
    }, {
      name: 'Enterprise Display Licenses',
      type: 'volume enterprise 2022',
      isDisplayLicensePlan: true,
      productId: '2420',
      productCode: '72f8d32e9cc37fa10fd174515221b53e042a7f70',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 30
    }, {
      name: 'Education/Non-Profit Enterprise Display Licenses',
      type: 'volume enterprise education 2022',
      isDisplayLicensePlan: true,
      productId: '2420',
      productCode: 'aa446723ff72b6b8c1b423e2710d64bf3e97d9f6',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 30
    },
    // 2025 plans
    {
      name: 'Basic Display Licenses',
      type: 'volume basic 2025',
      isDisplayLicensePlan: true,
      productId: '2400',
      productCode: '8d195c2b1b7b8ce1f0b65309f80cf5313a265548',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 10
    }, {
      name: 'Education/Non-Profit Basic Display Licenses',
      type: 'volume basic education 2025',
      isDisplayLicensePlan: true,
      productId: '2400',
      productCode: '9907dfd0eef2234d1c07f012d2c1759219fda5c3',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 10
    }, {
      name: 'Advanced Display Licenses',
      type: 'volume advanced 2025',
      isDisplayLicensePlan: true,
      productId: '2410',
      productCode: 'a9559c6722938aa968f042b6bedfeb2ccc8ad152',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 20
    }, {
      name: 'Education/Non-Profit Advanced Display Licenses',
      type: 'volume advanced education 2025',
      isDisplayLicensePlan: true,
      productId: '2410',
      productCode: '8fddd032916d289e11e1bc2d18c54f417813fbfc',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 20
    }, {
      name: 'Enterprise Display Licenses',
      type: 'volume enterprise 2025',
      isDisplayLicensePlan: true,
      productId: '2420',
      productCode: 'dc6bbae152c5aa43f7bed50c3b68c7ab982b9192',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 30
    }, {
      name: 'Education/Non-Profit Enterprise Display Licenses',
      type: 'volume enterprise education 2025',
      isDisplayLicensePlan: true,
      productId: '2420',
      productCode: 'b897e89073304013cfc277fa424aaff0ce8f026c',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14,
      tier: 30
    },
    // Old Display Licenses
    {
      name: 'Display Licenses',
      type: 'volume',
      isDisplayLicensePlan: true,
      productId: '2317',
      productCode: '34e8b511c4cc4c2affa68205cd1faaab427657dc',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14
    }, {
      name: 'Display Licenses',
      type: 'volume 2022',
      isDisplayLicensePlan: true,
      productId: '2327',
      productCode: '6dc959e7c483bfd5af3dbbc7ccba2282de6b7336',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10.5,
        billAmount: 10.5,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10.5,
        priceDisplayYear: 115.5,
        billAmount: 115.5,
        save: 10
      },
      trialPeriod: 14
    }, {
      name: 'Display Licenses for Education',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for education',
      productId: '2320',
      productCode: '88725121a2c7a57deefcf06688ffc8e84cc4f93b',
      proLicenseCount: 3,
      monthly: {
        priceDisplayMonth: 10,
        billAmount: 10,
        save: 0
      },
      yearly: {
        priceDisplayMonth: 10,
        priceDisplayYear: 110,
        billAmount: 110,
        save: 10
      },
      trialPeriod: 14
    },
    // Unlimited plans
    {
      name: 'Unlimited License',
      type: 'unlimited',
      productId: '336',
      productCode: '154d8443702f3b6bcf2eaf33ac73a407b0d447cb',
      proLicenseCount: 999,
      yearly: {
        billAmount: 999
      },
      trialPeriod: 14,
      tier: 40
    }, {
      name: 'Unlimited Licenses',
      type: 'volume unlimited',
      productId: '1336',
      productCode: '4e7ec14e8a24a0de4b5e4b9829327ff8f9d11986',
      proLicenseCount: 999,
      yearly: {
        billAmount: 999
      },
      trialPeriod: 14,
      tier: 40
    }, {
      name: 'Unlimited Licenses',
      type: 'volume unlimited 2025',
      productId: '1336',
      productCode: '016c6a9b114ada15016c3d0387b5b82d05bcbd21',
      proLicenseCount: 999,
      yearly: {
        billAmount: 999
      },
      trialPeriod: 14,
      tier: 40
    },
    // Deprecated plans
    {
      name: 'Starter',
      type: 'starter',
      productId: '335',
      productCode: '019137f7bb35f5f90085a033c013672471faadae',
      proLicenseCount: 1
    }, {
      name: 'Basic',
      type: 'basic',
      productId: '289',
      productCode: '40c092161f547f8f72c9f173cd8eebcb9ca5dd25',
      proLicenseCount: 3
    }, {
      name: 'Advanced',
      type: 'advanced',
      productId: '290',
      productCode: '93b5595f0d7e4c04a3baba1102ffaecb17607bf4',
      proLicenseCount: 11
    }, {
      name: 'Enterprise',
      type: 'enterprise',
      productId: '301',
      productCode: 'b1844725d63fde197f5125b58b6cba6260ee7a57',
      proLicenseCount: 70
    }, {
      name: 'Enterprise',
      type: 'enterprisesub',
      productId: '303',
      productCode: 'd521f5bfbc1eef109481eebb79831e11c7804ad8',
      proLicenseCount: 0
    },
    // Misc non-plans
    {
      name: 'Rise Vision Media Player',
      productCode: 'fce0e436a89a2e683a0897e3f04dd375da1bef8f',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for haas',
    }, {
      name: 'Rise Vision Media Player',
      productCode: 'aee0a236e87bade04d8a4ecda8414885ac066f45',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for haas 2025',
    }, {
      name: 'Avocor R Series 43"',
      productCode: '9e1b6f276fbb08b0c138b1862fe10eb3a62ae867',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for rseries43',
    }, {
      name: 'Avocor R Series 55"',
      productCode: 'f4a45eb14d4b2e2c5edcb5ea03cd88d73e2a5a3c',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for rseries55',
    }, {
      name: 'Avocor R Series 43"',
      productCode: '87fe0e0208f512793f76b23e5a2abe5ba2ffb060',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for rseries43 2025',
    }, {
      name: 'Avocor R Series 55"',
      productCode: '48105c78d47914bec003f81743b3b90873c5ecef',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for rseries55 2025',
    }, {
      name: 'Avocor K Series 55"',
      productCode: 'c3194322652a96186b8595cff018480db71ac289',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for kseries55 2025',
    }, {
      name: 'Avocor K Series 65"',
      productCode: '3b460474b85227992a10967384bb29f587d33ade',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for kseries65 2025',
    }, {
      name: 'Avocor K Series 75"',
      productCode: 'bea43d17f825c3bc90e05ed749682ed0334554cf',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for kseries75 2025',
    }, {
      name: 'Avocor K Series 85"',
      productCode: 'ad67c0a9d53952a5cfd8130570ca3244e5ea4640',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for kseries85 2025',
    }, {
      name: 'Avocor K Series 98"',
      productCode: '8aa0b2674a9d60c723ac3f0ad33e598da3e8f5bf',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for kseries98 2025',
    }, {
      name: 'Financial Data License',
      productCode: '356ab5e0541a41e96e4ef0b45ecac9f72af454ac',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for financial',
    }, {
      name: 'Financial Data License',
      productCode: 'a1e3c2b410fd8c15a5aeaefe0ea49c64cddda6df',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for financial 2025',
    }, {
      name: 'Education/Non-Profit Financial Data License',
      productCode: '997df4d53edec8af411498bd52903439a6dfe456',
      // cannot use type 'volume', it may interfere with the other plan
      type: 'volume for financial education 2025',
    }, {
      name: 'C-Scape iCandy LED Ticker License',
      productCode: '2374db80249aa3b862df6feea177a55e97015319'
    }, {
      name: 'LED Ticker License',
      // productCode: 'led-ticker-license',
      productCode: 'led',
      type: 'volume for led ticker'
    }, {
      name: 'LED Ticker License',
      productCode: '78cbefb4a5a96f315d0c6d9f52dcb32b478d3d45',
      type: 'volume for led ticker 2025'
    }, {
      name: 'Education/Non-Profit LED Ticker License',
      productCode: 'fbf643012f9628b4eb17275f6fb14b9bfe25fe83',
      type: 'volume for led ticker education 2025'
    },
    // Archived:
    {
      name: 'Premium Financial MarketWall',
      productCode: '0c583c663655c246c3e7b3c1be0ec05a442211aa',
      type: 'volume for premium marketwall'
    }, {
      name: 'Basic Financial MarketWall',
      productCode: '0dbb19f8394612730c2673b092d811e46413b132',
      type: 'volume for basic marketwall'
    }, {
      name: 'Premium LED Ticker License',
      productCode: 'c91e5b9762036cb6f0f0d7b93032c11897a9da1b',
      type: 'volume for premium ticker'
    }, {
      name: 'Basic LED Ticker License',
      productCode: '74eb12a1c0ade021f875213bf796b2ef8b174753',
      type: 'volume for basic ticker'
    }
  ];

  constructor() { }

  _getProductCode (planId) {
    return planId && planId.split('-')[0];
  }

  getPlan (productCode) {
    return PlansService.PLANS_LIST.find((plan) => plan.productCode === productCode);
  }

  getPlanById (planId?) {
    var productCode = this._getProductCode(planId);

    return this.getPlan(productCode);
  }

  getPlanByType (planType: string) {
    return PlansService.PLANS_LIST.find((plan) => plan.type === planType);
  }

  getPlansByType (planType: string) {
    return PlansService.PLANS_LIST.filter((plan) => plan.type && plan.type.indexOf(planType) !== -1);
  }

  getFreePlan () {
    return this.getPlanByType('free');
  }

  getUnlimitedPlan () {
    return this.getPlanByType('volume unlimited 2025');
  }

  getBasicPlan (discounted: boolean) {
    return this.getPlanByType(discounted ? 'volume basic education 2025' : 'volume basic 2025');
  }

  getAdvancedPlan (discounted: boolean) {
    return this.getPlanByType(discounted ? 'volume advanced education 2025' : 'volume advanced 2025');
  }

  getEnterprisePlan (discounted: boolean) {
    return this.getPlanByType(discounted ? 'volume enterprise education 2025' : 'volume enterprise 2025');
  }

  isVolumePlan (plan?) {
    return plan && plan.type && plan.type.indexOf('volume') !== -1;
  }

  isDisplayLicensePlan (plan?) {
    return plan && !!plan.isDisplayLicensePlan;
  }

  isBasicPlan (plan?) {
    return plan && plan.type && plan.type.indexOf('basic') >= 0;
  }

  isAdvancedPlan (plan?) {
    return plan && plan.type && plan.type.indexOf('advanced') >= 0;
  }

  isEnterprisePlan (plan?) {
    return plan && plan.type && plan.type.indexOf('enterprise') >= 0;
  }

  isUnlimitedPlan (plan?) {
    return plan && plan.type && plan.type.indexOf('unlimited') >= 0;
  }

  getPlanType(plan?) {
    if (plan) {
      if (this.isBasicPlan(plan)) {
        return 'basic';
      }
      if (this.isAdvancedPlan(plan)) {
        return 'advanced';
      }
      if (this.isEnterprisePlan(plan)) {
        return 'enterprise';
      }
      if (this.isUnlimitedPlan(plan)) {
        return 'unlimited';
      }
    }
    return '';
  }

  isDiscounted (plan?) {
    return plan && plan.type && plan.type.indexOf('education') >= 0;
  }

  isDowngrade (plan, currentPlan) {
    return !plan.tier || currentPlan.tier && currentPlan.tier > plan.tier;
  }
}
