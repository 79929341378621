import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { ModalService } from 'src/app/components/modals/modal.service';

import { AttributeDataService } from '../../services/attribute-data.service';
import { GoogleCalendarService } from '../services/google-calendar.service';
import { MicrosoftOutlookService } from '../services/microsoft-outlook.service';
import { ComponentsService } from '../../services/components.service';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import {} from '@angular/material/checkbox';

const PROVIDER_GOOGLE_CALENDAR = 'google';
const PROVIDER_OUTLOOK_CALENDAR = 'ms-graph';
const SCOPE_GOOGLE_CALENDAR = 'https://www.googleapis.com/auth/calendar.readonly';
const SCOPE_OUTLOOK_CALENDAR = 'Calendars.Read Calendars.Read.Shared';

enum States {
  NotConfigured,
  ConfiguredByMe,
  ConfiguredByOthers,
}

@Component({
    selector: 'template-component-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    standalone: false
})
export class CalendarComponent {
  public states: typeof States = States;
  public googleProvider = PROVIDER_GOOGLE_CALENDAR;
  public outlookProvider = PROVIDER_OUTLOOK_CALENDAR;

  public componentId: string;

  public spinner: boolean;
  public revokeFailed: boolean;
  public revokeFailedProvider: string;
  public authenticateFailed: boolean;
  public userAccount: string;
  public userAuthorized: boolean;
  public componentAccount: string;
  public componentAccountUsername: string;
  public provider: string;

  public source: string;
  public selectedCalendar: string;
  public selectedName: string;
  public range: number;
  public period: string;
  public completed: boolean;

  public rangeMin: number;
  public rangeMax: number;

  public sourceResult: string;
  public calendars = [];
  public outlookGroups = [];
  public calendarsFailed: boolean;
  public outlookGroupsFailed: boolean;

  public selectedOutlookGroup: string;
  public selectedOutlookGroupName:string;
  public outlookGroupId: string;

  get sameAccount(): boolean {
    return !!this.userAccount && this.userAccount === this.componentAccount;
  }

  get state(): States {
    // Chart: https://docs.google.com/document/d/183wUIRdwQdiQE9ur0u7VVcrPnkk1tkxX82Fa8d27bj8/edit#bookmark=id.ka9y6zeavuqg

    if(!this.userAuthorized && (!this.componentAccount || this.componentAccount && this.sameAccount)) {
      return States.NotConfigured;
    }

    if(this.userAuthorized && (!this.componentAccount || this.componentAccount && this.sameAccount)) {
      return States.ConfiguredByMe;
    }

    return States.ConfiguredByOthers;
  }

  get revokeFailedProviderName(): string {
    switch (this.revokeFailedProvider) {
      case PROVIDER_GOOGLE_CALENDAR:
        return 'Google';
      case PROVIDER_OUTLOOK_CALENDAR:
        return 'Microsoft';
      default:
        return '';
    }
  }

  constructor(
    private modalService: ModalService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsFactory: AnalyticsFactory,
    private attributeDataService: AttributeDataService,
    private componentsFactory: ComponentsService,
    private templateEditorService: TemplateEditorService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private googleCalendarService: GoogleCalendarService,
    private microsoftOutlookService: MicrosoftOutlookService,
    private authService: OAuthService) {

    this.range = 1;
    this.period = 'weeks';
    this.completed = true;
    this.selectedCalendar = '';
    this.selectedName = '';
    this.calendars = [];

    this._updateValidRange();

    this.spinner = false;
    this.revokeFailed = false;
    this.revokeFailedProvider = '';
    this.authenticateFailed = false;
    this.sourceResult = '';
    // provider can be null given this is not Twitter
    this.userAccount = this.authService.getUserIdentifier(null);
    this.userAuthorized = false;
    this.componentAccount = null;
    this.componentAccountUsername = null;
    this.provider = null;

    this.outlookGroupsFailed = false;
    this.calendarsFailed = false;
    this.outlookGroups = [];
    this.selectedOutlookGroup = '';
    this.selectedOutlookGroupName = '';

    this.componentsFactory.registerDirective({
      type: 'rise-data-calendar',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.load();
      }
    });
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this._directiveIsVisible() && !document.hidden) {
      this.sourceChanged();
    }
  }

  load() {
    this.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');
    this.range = this.attributeDataService.getAvailableAttributeData(this.componentId, 'range') || 1;
    this.period = this.attributeDataService.getAvailableAttributeData(this.componentId, 'period');
    this.completed = this.attributeDataService.getAvailableAttributeData(this.componentId, 'completed');
    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');
    this.selectedName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'name') || '';
    this.provider = this.attributeDataService.getAvailableAttributeData(this.componentId, 'provider');
    this.outlookGroupId = this.attributeDataService.getAvailableAttributeData(this.componentId, 'groupId');

    if (this.outlookGroupId) {
      this.selectedOutlookGroup = this.outlookGroupId || '';
      this.selectedOutlookGroupName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'groupName') || '';
    } else {
      this.selectedOutlookGroup = '';
      this.selectedOutlookGroupName = '';
    }

    if (this.selectedName) {
      this.selectedCalendar = this.source || '';
    } else {
      this.selectedCalendar = '';
    }

    if (!this.provider) {
      this._populate();
    } else {
      this._validateCredentials();
    }

    this._updateValidRange();
  }

  isPublicUrl() {
    return this.provider !== PROVIDER_OUTLOOK_CALENDAR && !this.selectedCalendar;
  }

  selectedCalendarChanged() {
    // if selected calendar has value then it is not public URL, save selected calendarId
    if (!this.isPublicUrl()) {
      const calendarItem = this.calendars.find(calendar => calendar.id === this.selectedCalendar);

      this.source = this.selectedCalendar;
      this.selectedName = calendarItem.name;
      this._track();
      this._saveAttributeData();
    } else {
      this.source = "";
      this.selectedName = "";
      this.sourceChanged();
    }

    this.changeDetectorRef.detectChanges();
  }

  sourceChanged() {
    if (!this.isPublicUrl()) {
      return;
    }

    let oldSource = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');

    // Reset validation to avoid showing the success icon on invalid input
    this.sourceResult = '';
    this.spinner = true;

    return this.googleCalendarService.validate(this.source)
      .then((result) => {
        this.sourceResult = result;

        if (result === 'VALID' && oldSource !== this.source) {
          this._track();
          this._saveAttributeData();
        }
      })
      .finally(() => {
        this.spinner = false;

        this.changeDetectorRef.detectChanges();
      });
  }

  periodChanged() {
    this._updateValidRange();

    if (this.isValidRange()) {
      this._saveAttributeData();
    }

    this.changeDetectorRef.detectChanges();
  }

  rangeChanged() {
    if (this.isValidRange()) {
      this._saveAttributeData();
    }

    this.changeDetectorRef.detectChanges();
  }

  completedChanged() {
    this.completed = !this.completed;
    this._saveAttributeData();

    this.changeDetectorRef.detectChanges();
  }

  isValidRange() {
    return this.range >= this.rangeMin && this.range <= this.rangeMax;
  }

  googleCalendarLink() {
    let calendarId = this.googleCalendarService.extractCalendarId(this.source);

    return `https://calendar.google.com/calendar/embed?src=${calendarId}`;
  }

  connectAccount(provider) {
    this.spinner = true;
    this.userAuthorized = false;

    return this.authService.getConnectionStatus(provider, this._getScopes(provider))
    .then(() => this._onAuthorized(provider))
    .catch(() => {
      console.log('Calendar Account not connected');

      return this._authenticate(provider);
    });
  }

  confirmDisconnect() {
    const providerTitle = this.provider === PROVIDER_GOOGLE_CALENDAR ? 'Google Calendar' : 'Microsoft Outlook';
    let disconnectMessage = `Any content that is using this ${providerTitle} account will stop working.`;

    if (this.provider === PROVIDER_GOOGLE_CALENDAR && !this.userStateService.isRiseAuthUser()) {
      disconnectMessage += ' If this account is the same as the one you use to Sign In to Rise Vision,'
        + ' it will also log you out of the application. You will be prompted to Sign In again if that happens.';
    }

    this.modalService.confirm(`Disconnect from ${providerTitle}`, disconnectMessage)
      .then(() => {
        this._resetAccount(true);
      })
      .catch(() => {});
  }

  switchSource() {
    this._resetAccount(false);

    this.spinner = false;
    this.changeDetectorRef.detectChanges();
  }

  selectedOutlookGroupChanged() {
    this._updateOutlookGroup(this.selectedOutlookGroup);
    this._resetCalendars();

    if (this.selectedOutlookGroup) {
      this.spinner = true;

      this._getOutlookCalendars()
      .then(() => {
        this._saveAttributeData();

        this.changeDetectorRef.detectChanges();
      })
      .catch((err) => {
        console.log('Failed to populate calendars', err);
        this.calendarsFailed = true;
      })
      .finally(() => {
        this.spinner = false;
      });
    } else {
      this._saveAttributeData();

      this.changeDetectorRef.detectChanges();
    }
  }

  _getSourceTypeName(provider) {
    switch(provider) {
      case PROVIDER_GOOGLE_CALENDAR:
        if (this.isPublicUrl()) {
          return 'Public';
        }

        return 'Google';
      case PROVIDER_OUTLOOK_CALENDAR:
        return 'Microsoft Outlook';
      default:
        return 'Public';
    }
  }

  _track() {
    this.analyticsFactory.track('Calendar Component Updated', {
      companyId: this.companyStateService.getSelectedCompanyId(),
      presentationId: this.templateEditorService.presentation.id,
      presentationName: this.templateEditorService.presentation.name,
      componentId: this.componentId,
      sourceType: this._getSourceTypeName(this.provider)
    });
  }

  _saveAccount(account) {
    this.componentAccount = account;
    this._saveAttributeData();
  }

  private _updateValidRange() {
    this.rangeMin = 1;

    if (this.period === 'days') {
      this.rangeMax = 7;
    } else if (this.period === 'weeks') {
      this.rangeMax = 4;
    } else if (this.period === 'months') {
      this.rangeMax = 12;
    } else {
      this.period = 'weeks';
      this._updateValidRange();
    }
  }

  private _directiveIsVisible() {
    // This directive is instantiated once by templateAttributeEditor
    // It becomes visible when <rise-data-calendar> is selected
    return this.componentsFactory.selected && (this.componentsFactory.selected.type === 'rise-data-calendar');
  }

  private _getScopes(provider) {
    switch (provider) {
      case PROVIDER_GOOGLE_CALENDAR:
        return SCOPE_GOOGLE_CALENDAR;
      case PROVIDER_OUTLOOK_CALENDAR:
        return SCOPE_OUTLOOK_CALENDAR;
      default:
        return null;
    }
  }

  private _validateCredentials() {
    let componentDisconnected = false;

    this.spinner = true;
    this.userAuthorized = false;

    // get username of a user who configured the component
    return this.authService.getUsername(this.provider, this.componentAccount)
      .then(({authenticated, username}) => {
        this.componentAccountUsername = username;

        if (this.componentAccount && !authenticated) {
          componentDisconnected = true;
        }
      })
      .catch(() => {
        console.log('Calendar failed to get username');
      })
      // get auth status of the signed in user
      .then(() => this.authService.getConnectionStatus(this.provider, this._getScopes(this.provider)))
      .then((status) => {
        this.userAuthorized = true;

        return this._populate();
      })
      .catch(() => {
        console.log('Calendar Account not connected');

        if (componentDisconnected && this.sameAccount) {
          return this.switchSource();
        }

        return this._populate();
      })
      .finally(() => {
        this.spinner = false;

        this.changeDetectorRef.detectChanges();
      });
  }

  private _authenticate(provider) {
    this.spinner = true;

    return this.authService.authenticate(provider, this._getScopes(provider))
      .then((authResult) => this._onAuthorized(provider, authResult))
      .catch((err) => {
        console.log('Failed to connect', err);
        this.authenticateFailed = true;
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  private _getAuthorizedUsername(authResult?) {
    if (authResult && authResult.username) {
      return Promise.resolve({authenticated: true, username: authResult.username});
    }

    return this.authService.getUsername(this.provider, this.componentAccount);
  }

  private _onAuthorized(provider, authResult?) {
    this.provider = provider;
    this.userAuthorized = true;
    this.authenticateFailed = false;

    if (!this.isPublicUrl()) {
      this._resetCalendars();
    }
    this._resetOutlookGroup();

    this.userAccount = this.authService.getUserIdentifier(this.provider);
    this._saveAccount(this.userAccount);

    return this._getAuthorizedUsername(authResult)
    .then(({authenticated, username}) => {
      this.componentAccountUsername = username;

      return this._populate();
    })
    .catch(() => {
      console.log('Calendar failed to get username');

      return this._populate();
    })
    .finally(() => {
      this.spinner = false;

      this.changeDetectorRef.detectChanges();
    });
  }

  private _populate() {
    this.calendars = [];
    this.outlookGroups = [];

    if (this.provider === PROVIDER_GOOGLE_CALENDAR) {
      return this._populateGoogle();
    }

    if (this.provider === PROVIDER_OUTLOOK_CALENDAR) {
      return this._populateOutlook();
    }
  }

  private _populateOutlook() {
    if (!this.sameAccount) {
      return;
    }

    this.outlookGroupsFailed = false;
    this.calendarsFailed = false;

    return this._getOutlookGroups()
      .then(() => this._getOutlookCalendars())
      .catch((err) => {
        console.log('Failed to populate Outlook selections', err);
        this.outlookGroupsFailed = this.outlookGroups.length === 0;
        this.calendarsFailed = !this.outlookGroupsFailed && this.calendars.length === 0;
      });
  }

  private _populateGoogle() {
    if (!this.sameAccount) {
      return;
    }

    this.calendarsFailed = false;

    return this.googleCalendarService.getCalendars(this.userAccount)
      .then((result : any) => {
        this.calendars = result;
      })
      .catch((err) => {
        console.log('Failed to populate Google selections', err);
        this.calendarsFailed = this.calendars.length === 0;
      });
  }

  private _getOutlookGroups() {
    return this.microsoftOutlookService.getGroups(this.userAccount)
    .then((groups: any) => {
      this.outlookGroups = groups;
    });
  }

  private _getOutlookCalendars() {
    if (!this.selectedOutlookGroup) {
      return Promise.resolve();
    }

    return this.microsoftOutlookService.getCalendars(this.userAccount, this.selectedOutlookGroup)
    .then((calendars: any) => {
      this.calendars = calendars;

      if (this.calendars.length === 1 && !this.selectedCalendar) {
        this.selectedCalendar = this.calendars[0].id;
        this.selectedCalendarChanged();
      }
    });
  }

  private _saveAttributeData() {
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
    this.attributeDataService.setAttributeData(this.componentId, 'provider', this.provider);
    this.attributeDataService.setAttributeData(this.componentId, 'account', this.componentAccount);

    if (!this.isPublicUrl() || (this.sourceResult === 'VALID' || this.source === '' )) {
      this.attributeDataService.setAttributeData(this.componentId, 'source', this.source);
    }

    this.attributeDataService.setAttributeData(this.componentId, 'name', this.selectedName || '');
    this.attributeDataService.setAttributeData(this.componentId, 'groupId', this.outlookGroupId || '');
    this.attributeDataService.setAttributeData(this.componentId, 'groupName', this.selectedOutlookGroupName || '');

    if (this.isValidRange()) {
      this.attributeDataService.setAttributeData(this.componentId, 'range', this.range);
      this.attributeDataService.setAttributeData(this.componentId, 'period', this.period);
    }

    this.attributeDataService.setAttributeData(this.componentId, 'completed', this.completed);
  }

  private _revokeGoogleAccount() {
    return this.googleCalendarService.revoke(this.authService.getUserIdentifier(PROVIDER_GOOGLE_CALENDAR))
      .then((revoked: boolean) => {
        this.userAuthorized = false;

        if (!revoked) {
          console.log('Google token could not be revoked');

          this.revokeFailed = true;
          this.revokeFailedProvider = PROVIDER_GOOGLE_CALENDAR;
        } else if (!this.userStateService.isRiseAuthUser()) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log('Failed to revoke account', err.message);
      });
  }

  private _revokeOutlookAccount() {
    return this.microsoftOutlookService.revoke(PROVIDER_OUTLOOK_CALENDAR)
      .then((revoked: boolean) => {
        this.userAuthorized = false;

        if (!revoked) {
          console.log('Outlook token could not be revoked');

          this.revokeFailed = true;
          this.revokeFailedProvider = PROVIDER_OUTLOOK_CALENDAR;
        }
      })
      .catch((err) => {
        console.log('Failed to revoke Outlook account', err.message);
      });
  }

  private _resetConnection() {
    this.provider = null;
    this.userAuthorized = false;
    this.authenticateFailed = false;

    // this also triggers attribute data save
    this._saveAccount(null);
  }

  private _resetAccount(revoke) {
    const originalProvider = this.provider;

    if (!this.isPublicUrl()) {
      this._resetOutlookGroup();
      this._resetCalendars();
    }

    this._resetConnection();

    this.spinner = true;

    if (!revoke) {
      return;
    }

    this.templateEditorService.hasUnsavedChanges = true;

    this.templateEditorService.save()
      .then(() => {
        if (originalProvider === PROVIDER_GOOGLE_CALENDAR) {
          return this._revokeGoogleAccount();
        }

        if (originalProvider === PROVIDER_OUTLOOK_CALENDAR) {
          return this._revokeOutlookAccount();
        }

      })
      .catch((err) => {
        console.log('Failed to save presentation', err.message);
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  private _updateOutlookGroup(selectedGroup) {
    let name = '';

    if (selectedGroup) {
      const selected = this.outlookGroups.find(group => group.id === selectedGroup);
      if (selected) {
        name = selected.name;
      }
    }

    this.outlookGroupId = selectedGroup;
    this.selectedOutlookGroupName = name;
  }

  private _resetOutlookGroup() {
    this.outlookGroups = [];
    this.outlookGroupsFailed = false;
    this.outlookGroupId = '';
    this.selectedOutlookGroup = '';
    this.selectedOutlookGroupName = '';
  }

  private _resetCalendars() {
    this.calendars = [];
    this.calendarsFailed = false;
    this.source = '';
    this.selectedCalendar = '';
    this.selectedName = '';
  }
}
