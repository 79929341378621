import { Component, ElementRef, ViewChildren } from '@angular/core';

import { ScheduleSelectorService } from 'src/app/schedules/services/schedule-selector.service';

import { TemplateEditorService } from '../../services/template-editor.service';
import { ComponentsService } from '../../services/components.service';
import { BlueprintService } from '../../services/blueprint.service';
import { BrandingService } from '../../template-components/services/branding.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'template-attribute-list',
    templateUrl: './template-attribute-list.component.html',
    styleUrls: ['./template-attribute-list.component.scss'],
    standalone: false
})
export class TemplateAttributeListComponent {
  public brandingComponent;
  public schedulesComponent;
  public colorsComponent;

  @ViewChildren('ComponentName') componentNameInputs: ElementRef[];

  constructor(private userStateService: UserStateService,
    private templateEditorService: TemplateEditorService,
    public componentsService: ComponentsService,
    private blueprintService: BlueprintService,
    private brandingService: BrandingService,
    private scheduleSelectorService: ScheduleSelectorService,
  ) {

    this.brandingComponent = this.brandingService.getBrandingComponent();

    if (this.userStateService.hasPermission('cp ap')) {
      this.schedulesComponent = this.scheduleSelectorService.getSchedulesComponent(this.templateEditorService
        .presentation);
    }

    if (this.blueprintService.hasBranding()) {
      this.colorsComponent = {
        type: 'rise-override-brand-colors'
      };
    }
  }

  showUserComponentSelector() {
    this.componentsService.editComponent({
      type: 'rise-playlist-item-selector'
    },
    {
      userComponents: true
    });
  }

}
