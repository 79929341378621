import { Component, Input } from '@angular/core';
import { UserStateService } from '../../services/user-state.service';
import { UserauthApiService } from 'src/app/api/services/userauth-api.service';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { StateService } from '@uirouter/angular';
import { CustomAuthService } from '../../services/custom-auth.service';

@Component({
    selector: 'confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrl: './confirm-email.component.scss',
    standalone: false
})
export class ConfirmEmailComponent {
  @Input() username: string;
  @Input() errorMessage: string = null;
  emailSending: boolean = false;
  emailSent: boolean = false;

  constructor (
    private userauth: UserauthApiService,
    private userState: UserStateService,
    private apiUtils: ApiUtilsService,
    private stateService: StateService,
    private customAuthService: CustomAuthService) {

    if (!this.username) {
      this.username = this.userState.getUsername();
    }
  }

  requestConfirmationEmail() {
    this.emailSending = true;

    return this.userauth.requestConfirmationEmail(this.username)
      .then(() => {
        this.emailSent = true;
      })
      .catch(e => {
        var defaultMessage = 'An error occurred while trying to resend the confirmation email. Please try again.';
        var error = this.apiUtils.getError(e);

        this.errorMessage = error.message || defaultMessage;
      })
      .finally(() => {
        this.emailSending = false;
      });
  };

  changeAccount(): void {
    this.stateService.go('apps.auth.unauthorized', {}, { reload: true });
  }
}
