import { Directive, HostListener, NgZone } from '@angular/core';

@Directive({
    selector: '[runZone]',
    standalone: false
})
export class RunZoneDirective {

  constructor(private ngZone: NgZone) { }

  @HostListener('mouseenter') onMouseEnter() {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }
}
