import { Component, Input } from '@angular/core';

@Component({
    selector: 'last-revised',
    templateUrl: './last-revised.component.html',
    styleUrls: ['./last-revised.component.scss'],
    standalone: false
})
export class LastRevisedComponent {

  @Input() changeDate: Date;
  @Input() changedBy: string;
  @Input() revisionStatusName: string;

}
