import { Injectable } from '@angular/core';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { DisplayApiService } from 'src/app/api/services/display-api.service';

@Injectable({
  providedIn: 'root'
})
export class DisplaySummaryService {

  apiError?: string;
  summary?: DisplaySummary;
  summaryLoading?: boolean;

  constructor(
    private displayApiService: DisplayApiService,
    private processErrorCode: ProcessErrorCode
  ) { }

  loadSummary (): Promise<void> {
    this.summary = undefined;
    this.summaryLoading = true;

    return this.displayApiService.summary()
      .then((summary) => {
        this.summary = summary;
      })
      .catch((err) => {
        this.apiError = this.processErrorCode(err);
        console.log('Error requesting display summary', err);
      })
      .finally(() => {
        this.summaryLoading = false;
      });
  }
}

export interface DisplaySummary {
  online: number;
  offline: number;
  scheduledUnlicensed: number;
}
