import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { OutsideClickHandler } from 'src/app/ajs-upgraded-providers';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScheduleSelectorService } from '../../services/schedule-selector.service';

@Component({
    selector: 'schedule-selector',
    templateUrl: './schedule-selector.component.html',
    styleUrls: ['./schedule-selector.component.scss'],
    standalone: false
})
export class ScheduleSelectorComponent {

  @ViewChild('tooltipTrigger') tooltipTrigger: ElementRef;
  @Input() additionalTooltipClass: string;

  filterConfig = {
    placeholder: 'Search schedules'
  };
  search: any = {
    sortBy: 'changeDate',
    reverse: true
  };

  constructor(
    private outsideClickHandler: OutsideClickHandler,
    private bsModalService: BsModalService,
    public scheduleSelectorService: ScheduleSelectorService
  ) { }

  showScheduleSelector() {
    this.outsideClickHandler.bind('schedule-selector', '#schedule-selector, #schedule-selector-tooltip', this.toggleTooltip.bind(this));
    this.scheduleSelectorService.load();
  }

  hideScheduleSelector() {
    const focusElement = document.getElementById('schedule-selector');
    if (focusElement) focusElement.focus();
    this.outsideClickHandler.unbind('schedule-selector');
  }

  toggleTooltip() {
    (this.tooltipTrigger as unknown as TooltipDirective).toggle();
  }

  closeModal() {
    this.bsModalService.hide();
  }

  select() {
    this.scheduleSelectorService.select();
    this.toggleTooltip();
  }

  addSchedule() {
    this.closeModal();

    this.scheduleSelectorService.addSchedule();
  }

  filterSchedule(schedule: any): boolean {
    let query = this.scheduleSelectorService.search?.query;

    return !query || schedule?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }
}
