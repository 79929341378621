import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ScrollingListService, OutsideClickHandler } from 'src/app/ajs-upgraded-providers';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'preview-selector',
    templateUrl: './preview-selector.component.html',
    styleUrls: ['./preview-selector.component.scss'],
    standalone: false
})
export class PreviewSelectorComponent {

  @ViewChild('tooltipTrigger') tooltipTrigger: ElementRef;
  @Input() selectedItem: any;
  @Input() label: string;
  @Input() additionalClass: string;
  @Input() additionalTooltipClass: string;
  @Input() placement: string;
  @Output() selectedItemChange = new EventEmitter<{}>();
  filterConfig = {
    placeholder: 'Search schedules'
  };
  search = {
    sortBy: 'changeDate',
    reverse: true
  };
  schedules: any;

  constructor(
    private scheduleApiService: ScheduleApiService,
    private ScrollingListService: ScrollingListService,
    private outsideClickHandler: OutsideClickHandler,
    private bsModalService: BsModalService
  ) { }

  showPreviewSelector () {
    this.outsideClickHandler.bind('preview-selector', '#preview-selector, #preview-selector-tooltip', this.toggleTooltip.bind(this));
    this.schedules = this.ScrollingListService(this.scheduleApiService.list.bind(this.scheduleApiService), this.search);
  }

  hidePreviewSelector () {
    this.outsideClickHandler.unbind('preview-selector');
  }

  toggleTooltip () {
    (this.tooltipTrigger as unknown as TooltipDirective).toggle();
  }

  select (schedule) {
    this.selectedItem = schedule;
    this.selectedItemChange.emit(schedule);
    this.toggleTooltip();
  }

  closeModal () {
    this.bsModalService.hide();
  }

}
