import { Component, OnInit } from '@angular/core';

import { CompanyAssetsService } from 'src/app/ajs-upgraded-providers';
import { DisplayService } from '../../services/display.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { EditorService } from 'src/app/editor/services/editor.service';
import { Transition } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'activation-notice',
    templateUrl: './activation-notice.component.html',
    styleUrls: ['./activation-notice.component.scss'],
    standalone: false
})
export class ActivationNoticeComponent implements OnInit {
  isFirstDisplay: boolean;
  hasPresentations: boolean;

  get showActivationNotice() {
    // Check that things are loaded & that display is activated
    if (this.isFirstDisplay === undefined || this.hasPresentations === undefined ||
      !this.displayService.display?.lastActivityDate) {
      return false;
    }

    // Show notice if the user has just completed the activation flow
    if (this.transition.params().activated) {
      return true;
    }

    // Show notice if content needs to be set up
    return this.showAddScheduleNotice || this.showAddPresentationNotice;
  }

  get showAddScheduleNotice() {
    return !(this.displayService.hasSchedule(this.displayService.display)) &&
      this.hasPresentations && this.userStateService.hasRole('cp');
  }

  get showAddPresentationNotice() {
    return !this.hasPresentations && this.userStateService.hasRole('ce');
  }

  constructor(private transition: Transition,
    private userStateService: UserStateService,
    private displayService: DisplayService,
    public editorService: EditorService,
    private displayApiService: DisplayApiService,
    private companyAssetsService: CompanyAssetsService) { }

  ngOnInit(): void {
    this.displayApiService.summary().then((summary) => {
      this.isFirstDisplay = summary.online + summary.offline <= 1;
    });

    this.companyAssetsService.hasPresentations().then((result) => {
      this.hasPresentations = result;
    });
  }

}
