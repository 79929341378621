import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { StateService } from '@uirouter/angular';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { MoveCompanyComponent } from '../move-company/move-company.component';
import { ActionsMenu } from 'src/app/components/common-actions/common-actions.component';

@Component({
    selector: 'company-add',
    templateUrl: './company-add.component.html',
    styleUrls: ['./company-add.component.scss'],
    standalone: false
})
export class CompanyAddComponent {

  company = {};
  loading = false;
  formError = null;
  apiError = null;
  isAddressError = false;

  @ViewChild('companyForm')
  public companyForm: NgForm;

  actions: ActionsMenu = {
    saveAction: {
      id: 'saveButton',
      label: 'Save',
      action: () => this.save(),
      requireRole: 'ua sa',
      disabled: false
    },
    cancelAction: {
      id: 'cancelButton',
      label: 'Cancel',
      action: () => this.cancel()
    },
    moreOptions: [{
      id: 'moveButton',
      label: 'Move a Company Under Your Company',
      action: () => this.moveCompany()
    }]
  };

  constructor(private _location: Location,
    private stateService: StateService,
    private modalService: ModalService,
    private companyStateService: CompanyStateService,
    private companyApiService: CompanyApiService,
    private addressFactory: AddressService,
    private tracker: TrackerService,
    private processErrorCode: ProcessErrorCode
  ) { }

  save() {
    this._clearErrorMessages();

    if (!this.companyForm.valid) {
      console.log('Form not valid');
    } else {
      this.loading = true;

      this.addressFactory.validateAddressIfChanged(this.companyForm.form.controls, this.company)
        .then(() => {
          this.companyApiService.createCompany(this.companyStateService.getSelectedCompanyId(), this.company)
            .then((company) => {
              this.tracker.companyEvent('Company Created', company.id, company.name);

              this.companyStateService.switchCompany(company.id).then(() => {
                this.stateService.go('apps.company.details', { cid: company.id });
              });

            })
            .catch((err) => {
              this.formError = 'Failed to Add Sub-Company.';
              this.apiError = this.processErrorCode(err);
            })
            .finally(() => {
              this.loading = false;
            });

        })
        .catch((error) => {
          this.loading = false;

          this.formError = 'We couldn\'t update your address.';
          this.apiError = this.processErrorCode(error);
          this.isAddressError = true;
        });
    }
  }

  // Show Move Company Modal
  moveCompany() {
    return this.modalService.showMediumDialog(
      MoveCompanyComponent,
      null,
      null,
      'moveButton'
    );
  }

  _clearErrorMessages() {
    this.formError = null;
    this.apiError = null;
    this.isAddressError = false;
  }

  cancel() {
    this._location.back();
  }

}
