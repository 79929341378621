import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'storageFileSize',
    standalone: false
})
export class StorageFileSizePipe implements PipeTransform {

  transform(size?: number): string {
    let sizeString = '';
    if (size === 0) {
      return '0 bytes';
    }

    if (!size) {
      return '';
    }

    if (size < 1024) {
      sizeString = size + ' bytes';
    } else if (size >= 1024 && size < Math.pow(1024, 2)) {
      sizeString = Math.floor(size / (1024 / 10)) / 10.0 + ' KB';
    } else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) {
      sizeString = Math.floor(size / (Math.pow(1024, 2) / 10)) / 10.0 + ' MB';
    } else if (size >= Math.pow(1024, 3)) {
      sizeString = Math.floor(size / (Math.pow(1024, 3) / 10)) / 10.0 + ' GB';
    }

    return sizeString;
  }

}
