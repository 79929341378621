import { Component } from '@angular/core';

import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';

@Component({
    selector: 'help-widget-button',
    templateUrl: './help-widget-button.component.html',
    styleUrls: ['./help-widget-button.component.scss'],
    standalone: false
})
export class HelpWidgetButtonComponent {

  constructor(public helpWidgetFactory: HelpWidgetFactory) { }

}