import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { NgForm, NgModel, ControlContainer } from '@angular/forms';

@Component({
    selector: 'edit-monitoring',
    templateUrl: './edit-monitoring.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./edit-monitoring.component.scss'],
    standalone: false
})
export class EditMonitoringComponent implements OnInit {

  bulkEditForm: NgForm;

  @ViewChild('monitoringEmails')
  monitoringEmails: NgModel;

  @Input() model: any = {};

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.bulkEditForm = <NgForm>this.controlContainer;
  }
}
