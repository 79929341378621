import { Component, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { GadgetsApi } from 'src/app/ajs-upgraded-providers';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
    selector: 'widget-settings',
    templateUrl: './widget-settings.component.html',
    styleUrls: ['./widget-settings.component.scss'],
    standalone: false
})
export class WidgetSettingsComponent extends ModalComponent implements OnInit {
  widgetUrl;
  additionalParams;
  loading;

  @Input() widget: any;

  constructor(public modalRef: BsModalRef,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private gadgetsApi: GadgetsApi) {
    super(modalRef);
  }

  ngOnInit() {
    this.loading = true;

    setTimeout(this._stopLoader.bind(this), 3000);

    this.widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.widget.url);
    this.additionalParams = this.widget.additionalParams;

    this._registerRpc();

    this.changeDetectorRef.detectChanges();
  }

  _registerRpc() {
    if (this.gadgetsApi) {
      setTimeout(() => {
        this.gadgetsApi.rpc.register('rscmd_saveSettings',
          this._saveSettings.bind(this));
        this.gadgetsApi.rpc.register('rscmd_closeSettings',
          this._closeSettings.bind(this));
        this.gadgetsApi.rpc.register('rscmd_getAdditionalParams',
          this._getAdditionalParams.bind(this));

        this.gadgetsApi.rpc.setupReceiver('widget-modal-frame');
      });
    }
  }

  _stopLoader() {
    this.loading = false;
  }

  _getAdditionalParams() {
    this._stopLoader();

    return this.additionalParams;
  }

  _saveSettings(data) {
    super.resolve(data);
  }

  _closeSettings() {
    super.reject();
  }

}
