import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgForm, FormGroup, ControlContainer } from '@angular/forms';

import { COUNTRIES } from 'src/app/ajs-upgraded-providers';

import { GeodataService } from 'src/app/shared/services/geodata.service';

@Component({
    selector: 'address-form',
    templateUrl: './address-form.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./address-form.component.scss'],
    standalone: false
})
export class AddressFormComponent implements OnInit {

  @Input() addressObject: any = {};
  @Input() hideCompanyName: boolean;

  public parentForm: NgForm;

  @ViewChild('addressForm')
  public addressForm: NgForm;

  regionsCA = GeodataService.REGIONS_CA;
  regionsUS = GeodataService.REGIONS_US;

  constructor(private controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef,
    public countries: COUNTRIES,
  ) { }

  ngOnInit() {
    this.parentForm = <NgForm>this.controlContainer;
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  isFieldInvalid(fieldName) {
    var form = this.parentForm;
    var formGroup = this.parentForm.form.controls['addressForm'];
    var field = formGroup && (<FormGroup>formGroup).controls[fieldName];

    if (!field) {
      return false;
    }

    return (field.dirty || form.submitted) && field.invalid;
  }

  isProvinceInvalid() {
    var form = this.parentForm;
    var formGroup = this.parentForm.form.controls['addressForm'];
    var field = formGroup && (<FormGroup>formGroup).controls['province'];

    if (!formGroup || !field) {
      return false;
    }

    return (field.dirty || form.submitted) && !!formGroup.errors?.validProvince;
  }

}
