import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    standalone: false
})
export class ConfirmModalComponent {

  protected title: string;
  protected message: string;
  protected cancelButton: string;
  protected confirmButton: string;
  protected confirmButtonClass: 'primary' | 'warn';
  
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ConfirmModalData
  ) {
    this.title = data.title;
    this.message = data.message;
    this.cancelButton = data.cancelButton;
    this.confirmButton = data.confirmButton;
    this.confirmButtonClass = data.confirmButtonClass ?? 'primary';
  }

  confirm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(null);
  }

}

export interface ConfirmModalData {
  title: string;
  message: string;
  confirmButton: string;
  cancelButton: string;
  confirmButtonClass: 'primary' | 'warn';
}
