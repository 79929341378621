@if (countOnly) {
  <div>
    <span>
      {{ playerLicenseService.getUsedLicenseCountString() }}
    </span>
  </div>
} @else {
  <div class="tw-flex tw-gap-16 tw-pb-10 md:!tw-pb-0 tw-w-full tw-max-w-full tw-overflow-x-auto">
    @for (item of (items | async); track $index) {
      <div class="count-item">
        <div class="icon" [ngClass]="item.iconClass">
          <mat-icon class="medium" [svgIcon]="item.icon"></mat-icon>
        </div>
        <div class="count">
          <span class="tw-font-semibold">{{item.text}}</span>
          <span class="tw-text-gray-500">{{item.subtext}}</span>
        </div>
      </div>
    }
  </div>
}