<div class="display-preview">
  <div class="display-preview-main" [ngClass]="{ 'zoom-in': zoomIn }">
    <div class="display-preview-close">
      <button type="button" aria-label="Close virtual display preview" class="text-white p-4" mat-button [mat-dialog-close]="data.selectedSchedule">
        <streamline-icon ariaLabel="Click to close this modal" name="close" height="20" width="20"></streamline-icon>
      </button>
    </div>
    <div class="display-preview-bg"></div>
    <div class="tv-panel">
      <div class="tv-position">
        <div class="tv-frame">
          <div class="display-preview-image">
            @if (data.selectedSchedule?.id) {
              <div class="preview-embed">
                <iframe frameborder="0" [src]="santitizedPreviewUrl"></iframe>
              </div>
            }
            @else {
              <img class="img-fluid" src="/images/sign-up/preview1.jpg" alt="Slide with New York weather forecast">
            }
          </div>
        </div>
      </div>
      <div class="tv-spacer"></div>
    </div>
  </div>
  <div class="display-preview-footer flex-row space-between">
    <div class="display-preview-selector">
      <preview-selector [(selectedItem)]="data.selectedSchedule" label="Schedule:" placement="top left"></preview-selector>
      <div class="button-flex-row">
        <a class="btn btn-default btn-toolbar mb-4" href="#" (click)="toggleZoom()">{{ zoomIn ? 'Zoom Out' : 'Zoom In'}}</a>
        <a class="btn btn-primary btn-toolbar mb-4 text-white" href="#" mat-button [mat-dialog-close]="data.selectedSchedule">
          I'm Done
        </a>
      </div>
    </div>
    <div>
      <img alt="Rise Vision logo" class="rise-logo mt-3" width="134" height="50" src="https://widgets.risevision.com/apps/logos/rise-logo.svg">
    </div>
  </div>
</div>
