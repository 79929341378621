import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CompanyService } from '../../services/company.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { SafeDeleteModalComponent } from '../safe-delete-modal/safe-delete-modal.component';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
    standalone: false
})
export class CompanyDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('companyForm') companyForm: NgForm;

  @Input() companyId: string;

  actions = {
    saveAction: {
      id: 'saveButton',
      label: 'Save',
      action: () => this.save(),
      requireRole: 'ua sa',
      disabled: false
    },
    cancelAction: {
      id: 'cancelButton',
      label: 'Cancel',
      action: () => this.cancel()
    },
    deleteAction: {
      id: 'deleteButton',
      label: 'Delete',
      action: () => this.delete(),
      requireRole: 'ua sa'
    },
    moreOptions: [
      {
        id: 'exportButton',
        label: 'Export',
        action: () => this.export(),
        requireRole: 'sa'
      },
    ]
  };

  constructor(
    private _location: Location,
    public companyService: CompanyService,
    private userStateService: UserStateService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    if (this.companyId) {
      this.companyService.getCompany(this.companyId);
    }
  }

  ngAfterViewInit() {
    if (this.companyForm) {
      this.companyForm.statusChanges.subscribe(() => {
        this.actions.saveAction.disabled = this.companyForm.invalid;
      });
    }
  }

  save() {
    if (!this.companyForm.valid) {
      console.log('Form not valid');
      return;
    }

    this.companyService.save(this.companyForm);
  }

  delete() {
    return this.modalService.showMediumModal(SafeDeleteModalComponent, {
      initialState: {
        name: this.companyService.company.name
      }
    }).then(() => {
      this.companyService.deleteCompany(this.companyService.company);
    });
  }

  export() {
    return this.companyService.exportCompany();
  }

  resetAuthKey() {
    var type = 'authKey';
    var title = 'Reset Authentication Key',
      message = 'Resetting the Company Authentication Key will cause existing Data Gadgets ' +
        'to no longer report data until they are updated with the new Key.';
    var focusId = 'resetAuthKey';

    this._resetCompanyField(type, title, message, focusId)
      .then((resp) => {
        this.companyService.company.authKey = resp.item;
      });
  }

  resetClaimId() {
    var type = 'claimId';
    var title = 'Reset Claim Id',
      message = 'Resetting the Company Claim Id will cause existing installations to no ' +
        'longer be associated with your Company.';
    var focusId = 'claimId';

    this._resetCompanyField(type, title, message, focusId)
      .then((resp) => {
        this.companyService.company.claimId = resp.item;
      });
  }

  _resetCompanyField(type, title, message, focusId) {
    return this.modalService.confirm(title, message, 'Ok', 'Cancel', null, focusId)
      .then(() => {
        return this.companyService.resetCompanyField(type);
      });
  }

  cancel() {
    this._location.back();
  }

}
