import { Component, Input } from '@angular/core';

@Component({
    selector: 'edit-reboot-time',
    templateUrl: './edit-reboot-time.component.html',
    styleUrls: ['./edit-reboot-time.component.scss'],
    standalone: false
})
export class EditRebootTimeComponent {

  @Input() model: any = {};

}
