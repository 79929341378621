import { Component, ElementRef, ViewChild } from '@angular/core';

import { TooltipDirective } from 'ngx-bootstrap/tooltip';

import { OutsideClickHandler } from 'src/app/ajs-upgraded-providers';

@Component({
    selector: 'share-url-button',
    templateUrl: './share-url-button.component.html',
    styleUrls: ['./share-url-button.component.scss'],
    standalone: false
})
export class ShareUrlButtonComponent {

  @ViewChild('tooltipTrigger') tooltipTrigger: ElementRef;

  constructor(private outsideClickHandler: OutsideClickHandler) { }

  getUrl() {
    return window.location.href;
  }

  copyToClipboard(text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  onTextFocus(event) {
    event.target.select();
  }

  showPopover () {
    this.outsideClickHandler.bind('share-url-popover', '#share-url-popover, #share-url-button', this.toggleTooltip.bind(this));
  }

  hidePopover () {
    this.outsideClickHandler.unbind('share-url-popover');
  }

  toggleTooltip () {
    (this.tooltipTrigger as unknown as TooltipDirective).toggle();
  }

}