import { Pipe, PipeTransform } from '@angular/core';

import { PlansService } from 'src/app/components/plans/plans.service';

@Pipe({
    name: 'subscriptionDescription',
    standalone: false
})
export class SubscriptionDescriptionPipe implements PipeTransform {

  constructor(public plansService: PlansService) { }

  _getPeriod(subscription) {
    if (subscription.billing_period > 1) {
      return (subscription.billing_period + ' ' + (subscription.billing_period_unit === 'month' ?
        'Month' : 'Year'));
    } else {
      return subscription.billing_period_unit === 'month' ? 'Monthly' : 'Annual';
    }
  }

  transform(subscription: any): string {
    if (!subscription) {
      return '';
    }

    var prefix = subscription.plan_quantity > 1 ? subscription.plan_quantity + ' x ' : '';
    var plan = this.plansService.getPlanById(subscription.plan_id);
    if (plan) {
      var name = plan.name;

      // Show `1` plan_quantity for Per Display subscriptions
      if (plan && this.plansService.isVolumePlan(plan) && subscription.plan_quantity > 0) {
        prefix = subscription.plan_quantity + ' x ';
      }

      var period = this._getPeriod(subscription);

      if (this.plansService.isVolumePlan(plan)) {
        name = name + ' ' + period + ' Plan';
      } else {
        name = name + ' Plan ' + period;
      }

      return prefix + name;
    } else {
      return subscription.plan_id;
    }
  }

}
