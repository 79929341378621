import { Component, OnInit, OnDestroy } from '@angular/core';

import { EditorService } from '../../services/editor.service';
import { DistributionParserService } from '../../services/distribution-parser.service';
import { PresentationParserService } from '../../services/presentation-parser.service';

@Component({
    selector: 'html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
    standalone: false
})
export class HtmlEditorComponent implements OnInit, OnDestroy {

  codemirrorOptions = {
    lineNumbers: true,
    theme: 'twilight',
    lineWrapping: false,
    mode: 'htmlmixed'
  };

  constructor(public editorService: EditorService,
    private presentationParserService: PresentationParserService,
    private distributionParser: DistributionParserService) { }

  ngOnInit(): void {
    this.presentationParserService.updatePresentation(this.editorService.presentation);
    this.distributionParser.updateDistribution(this.editorService.presentation);
  }

  ngOnDestroy(): void {
    this.presentationParserService.parsePresentation(this.editorService.presentation);
    this.distributionParser.parseDistribution(this.editorService.presentation);
  }

}
