import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'company-id-modal',
    templateUrl: './company-id-modal.component.html',
    styleUrls: ['./company-id-modal.component.scss'],
    standalone: false
})
export class CompanyIdModalComponent {

  title: string;
  description: string;
  cancelButton: string;
  applyButton: string;
  companyId: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyIdModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  apply () {
    this.dialogRef.close(this.companyId);
  }

  dismiss () {
    this.dialogRef.close(false);
  }
}