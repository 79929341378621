import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CreditCardService } from 'src/app/purchase/services/credit-card.service';
import { AddPaymentSourceService } from '../../services/add-payment-source.service';
import { SubscriptionService } from '../../services/subscription.service';
import { StateService } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'add-payment-source',
    templateUrl: './add-payment-source.component.html',
    styleUrls: ['./add-payment-source.component.scss'],
    standalone: false
})
export class AddPaymentSourceComponent {

  contactEmail = this.userStateService.getUserEmail();

  @ViewChild('paymentMethodsForm')
  public paymentMethodsForm: NgForm;

  constructor(private stateService: StateService,
    private userStateService: UserStateService,
    public subscriptionService: SubscriptionService,
    public addPaymentSourceService: AddPaymentSourceService,
    private creditCardService: CreditCardService) {
      this.addPaymentSourceService.init();
    }

  _goToSubscriptionPage(subscriptionId) {
    this.stateService.go('apps.billing.subscription', {
      subscriptionId: subscriptionId
    });
  }

  addPaymentMethod(subscriptionId) {
    if (this.creditCardService.paymentMethods.paymentMethod === 'invoice') {
      var purchaseOrderNumber = this.creditCardService.paymentMethods.purchaseOrderNumber;

      this.addPaymentSourceService.changePaymentToInvoice(subscriptionId, purchaseOrderNumber)
        .then(() => {
          this._goToSubscriptionPage(subscriptionId);
        });
    } else {
      if (!this.paymentMethodsForm.valid) {
        return;
      }

      this.addPaymentSourceService.changePaymentSource(subscriptionId)
        .then(() => {
          this._goToSubscriptionPage(subscriptionId);
        });
    }
  }
}
