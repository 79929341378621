import { Component } from '@angular/core';

@Component({
    selector: 'onedrive-file-picker',
    templateUrl: './onedrive-file-picker.component.html',
    styleUrls: ['./onedrive-file-picker.component.scss'],
    standalone: false
})
export class OnedriveFilePickerComponent {
}
