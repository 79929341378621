import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { CompanyIcpService } from '../../services/company-icp.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
    selector: 'company-role-modal',
    templateUrl: './company-role-modal.component.html',
    styleUrls: ['./company-role-modal.component.scss'],
    standalone: false
})
export class CompanyRoleModalComponent extends ModalComponent {

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  user: any = {};
  COMPANY_ROLE_FIELDS = CompanyIcpService.EDUCATION_COMPANY_ROLE_FIELDS;

  save() {
    super.resolve({
      user: this.user
    });
  }

}
