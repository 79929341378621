import { Component, DoCheck, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { AutoSaveService } from '../../services/auto-save.service';
import { ComponentsService } from '../../services/components.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { StateService, TransitionService } from '@uirouter/angular';


export function AutoSaveServiceFactory(templateEditorService: TemplateEditorService) {
  return new AutoSaveService(templateEditorService.save.bind(templateEditorService));
}
@Component({
    selector: 'app-template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss'],
    providers: [
        { provide: AutoSaveService, useFactory: AutoSaveServiceFactory, deps: [TemplateEditorService] }
    ],
    standalone: false
})
export class TemplateEditorComponent implements DoCheck, OnDestroy {
  private subscription: any;
  private transitionHook: any;
  private _oldPresentation: any;

  private _bypassUnsaved = false;

  constructor(
    private stateService: StateService,
    private transitionService: TransitionService,
    private broadcaster: BroadcasterService,
    public componentsFactory: ComponentsService,
    private templateEditorService: TemplateEditorService,
    private autoSaveService: AutoSaveService,
    private presentationUtils: PresentationUtilsService) {

    this.templateEditorService.hasUnsavedChanges = false;

    this.transitionHook = this.transitionService.onStart({
      to: (state: any) => state.name.indexOf('apps.editor.templates') === -1
    }, (trans: any) => {
      if (this._bypassUnsaved) {
        this._bypassUnsaved = false;
        return;
      }

      this.autoSaveService.clearSaveTimeout();

      if (this.templateEditorService.isUnsaved() && this.templateEditorService.hasContentEditorRole()) {
        trans.abort();

        this.templateEditorService.save()
          .finally(() => {
            this._bypassUnsaved = true;
            this.stateService.go(trans.to().name, trans.to().params);
          });
      }
    });

    window.onbeforeunload = (e: Event) => {
      if (this.templateEditorService.isUnsaved()) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    };

    this.subscription = this.broadcaster.subscribe({
      next: (event: String) => {
        switch (event) {
        case 'presentationCreated':
        case 'presentationUpdated':
        case 'presentationPublished':
          if (!this.autoSaveService.pendingSave) {
            this._setUnsavedChangesAsync(false);
          }
          break;
        case 'presentationDeleted':
          this._setUnsavedChanges(false);
          break;
        case 'risevision.template-editor.brandingUnsavedChanges':
          this._setUnsavedChangesAsync(true);
          break;
        default:
          return;
        }
      }
    });

  }

  private _checkPresentationChanged() {
    if (!this.templateEditorService.hasContentEditorRole()) {
      return;
    }

    if (_.isEqual(this.templateEditorService.presentation, {})) {
      return;
    } else if (!_.isEqual(
        _.omit(this.templateEditorService.presentation, TemplateEditorService.IGNORED_FIELDS),
        _.omit(this._oldPresentation, TemplateEditorService.IGNORED_FIELDS)
    )) {
      this._oldPresentation = _.cloneDeep(this.templateEditorService.presentation);

      this.broadcaster.emit('risevision.template-editor.presentationUnsavedChanges');
      this._setUnsavedChangesAsync(true);
    }

  }

  ngDoCheck(): void {
    if (!this.templateEditorService.presentation) {
      return;
    } else if (!this._oldPresentation) {
      this._oldPresentation = _.cloneDeep(this.templateEditorService.presentation);
    } else {
      this._checkPresentationChanged();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.transitionHook();

    window.onbeforeunload = undefined;
  }

  considerChromeBarHeight() {
    const userAgent = window.navigator.userAgent;

    // Firefox and Samsung browser require desktop rule
    return this.presentationUtils.isMobileBrowser() &&
      !(/Firefox|SamsungBrowser/i.test(userAgent));
  }

  private _setUnsavedChanges (state: any) {
    this.templateEditorService.hasUnsavedChanges = state;

    if (this.templateEditorService.hasUnsavedChanges && this.templateEditorService.hasContentEditorRole()) {
      this.autoSaveService.save();
    }
  }

  private _setUnsavedChangesAsync (state: any) {
    setTimeout(() => {
      this._setUnsavedChanges(state);
    });
  }

}
