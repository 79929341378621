import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import { Transition } from '@uirouter/angular';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';
import { PlansService } from 'src/app/components/plans/plans.service';
import { UpdateSubscriptionService } from '../../services/update-subscription.service';
import { BillingUtilsService } from 'src/app/billing/services/billing-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
    selector: 'update-plan',
    templateUrl: './update-plan.component.html',
    styleUrls: ['./update-plan.component.scss'],
    standalone: false
})
export class UpdatePlanComponent implements OnInit {

  redirectTo: string | undefined;

  billingPeriod = 'annually';
  plan: any;
  purchaseAction = 'change';

  couponCode = null;
  addCoupon;

  get downgrade(): boolean {
    const currentPlan = this.subscriptionService.getItemSubscription().plan_id &&
      this.plansService.getPlanById(this.subscriptionService.getItemSubscription().plan_id);

    if (!currentPlan) {
      return false;
    }

    return this.plansService.isDowngrade(this.plan, currentPlan);
  }

  @ViewChild('purchaseLicensesForm')
  public purchaseLicensesForm: NgForm;

  constructor(private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    public updateSubscriptionService: UpdateSubscriptionService,
    public subscriptionService: SubscriptionService,
    private plansService: PlansService,
    public billingUtils: BillingUtilsService,
    private transition: Transition,
    private companyStateService: CompanyStateService
  ) { }

  ngOnInit(): void {
    const productCode = this.transition.params().productCode;
    this.plan = this.plansService.getPlan(productCode);
    if (this.plansService.isUnlimitedPlan(this.plan)) {
      this.purchaseAction = 'unlimited';
    }
    this.updateSubscriptionService.init(
      this.purchaseAction,
      productCode,
      this.transition.params().monthly,
      this.downgrade
    );

    this.redirectTo = this.transition.params().redirectTo;
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  _isFormValid() {
    var form = this.purchaseLicensesForm;
    return !form || form.valid;
  }

  applyCouponCode() {
    if (!this._isFormValid()) {
      return;
    }

    if (this.couponCode) {
      this.updateSubscriptionService.couponCode = this.couponCode;

      this.updateSubscriptionService.getEstimate()
        .then(() => {
          if (!this.updateSubscriptionService.apiError) {
            this.addCoupon = false;
          }
        });
    }
  }

  clearCouponCode() {
    this.couponCode = null;
    this.updateSubscriptionService.couponCode = null;
    this.addCoupon = false;

    if (this.updateSubscriptionService.apiError) {
      this.updateSubscriptionService.getEstimate();
    }
  }

  getEstimate() {
    if (!this._isFormValid()) {
      return;
    }

    return this.updateSubscriptionService.getEstimate();
  }

  completePayment() {
    if (!this._isFormValid()) {
      return;
    }

    return this.updateSubscriptionService.preparePayment()
      .then(() => this.updateSubscriptionService.completePayment())
      .then(() => {
        this.subscriptionService.getItemSubscription().has_scheduled_changes = this.downgrade;
        this.subscriptionService.getItemSubscription().changed_plan_id = this.downgrade ? this.plan.productCode : undefined;
        this.subscriptionService.getItemSubscription().changed_plan_quantity = undefined;

        if (!this.redirectTo) {
          this.redirectTo = '/billing?cid=' + (this.transition.params().cid || this.companyStateService.getSelectedCompanyId());
        }
      });
  }

  close() {
    if (this.redirectTo) {
      this.location.go(this.redirectTo);
    } else {
      this.transition.router.stateService.go(
        this.transition.from(),
        this.transition.params('from')
      );
    }
  }

}
