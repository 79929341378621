import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/angular';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { environment } from 'src/environments/environment';
import { ScheduleService } from '../../services/schedule.service';

@Component({
    selector: 'shared-schedule-popover',
    templateUrl: './shared-schedule-popover.component.html',
    styleUrl: './shared-schedule-popover.component.scss',
    standalone: false
})
export class SharedSchedulePopoverComponent {

  @Output() dismissEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('linkText') linkText: ElementRef;
  currentTab = 'link';
  isScheduleDetails = this.uiRouterGlobals.current.name === 'apps.schedules.details';

  @HostListener('window:resize')
  onResize() {
    this.dismiss();
  }

  constructor(
    public scheduleService: ScheduleService,
    private trackerService: TrackerService,
    private uiRouterGlobals: UIRouterGlobals
  ) {}


  getLink() {
    return this.scheduleService.schedule
      ? environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', this.scheduleService.schedule.id)
      : '';
  }

  copyToClipboard(text: string) {
    this.trackScheduleShared();

    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  trackScheduleShared(extraProperties?) {
    const properties = extraProperties || {};
    properties.source = properties.source || this.currentTab;

    this.trackerService.scheduleEvent(
      'schedule shared',
      this.scheduleService.schedule.id,
      this.scheduleService.schedule.name,
      properties
    );
  }

  focusInput() {
    this.linkText.nativeElement.focus();
  }

  onTextFocus(event) {
    event.target.select();
  }

  dismiss() {
    this.dismissEvent.emit();
  }
}
