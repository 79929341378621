import { Component, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { OneDriveFilePickerService } from '../services/one-drive-file-picker.service';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { DocumentService } from 'src/app/storage/services/document.service';
import { MicrosoftOneDriveService } from '../services/microsoft-onedrive.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { FileExistenceCheckService } from '../services/file-existence-check.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';

const PROVIDER_RISE_STORAGE = 'rise-storage';
const PROVIDER_MS_GRAPH = 'ms-graph';
// User.Read scope is needed for detecting user account type: 'personal'/'business'
const SCOPE_ONE_DRIVE = 'Files.Read.All Sites.Read.All User.Read';

@Component({
    selector: 'template-component-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
    standalone: false
})

export class DocumentComponent {

  public componentId: string;
  public spinner: boolean;
  public isDropdownOpen: boolean;
  public componentAccount: string;
  public componentAccountUsername: string;
  public provider: string;
  public selectedName: string;
  public fileAccessFailed: boolean;
  public revokeFailed: boolean;
  public authenticateFailed: boolean;
  public source: string;
  public conversionError: string;
  public fileType: string;
  public fileUploaderId: string = 'document-file-uploader';
  public storageUploadManager;
  public isUploading;

  public values = {
    duration: 0,
    transition: null,
    refresh: null,
    assetCount: null,
    fileMissing: false,
  };

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    private templateEditorUtilsService: TemplateEditorUtilsService,
    private oneDriveFilePickerService: OneDriveFilePickerService,
    private authService: OAuthService,
    private documentService: DocumentService,
    private microsoftOneDriveService: MicrosoftOneDriveService,
    private companyStateService: CompanyStateService,
    private storageManagerService: StorageManagerService,
    private storageUtilsService: StorageUtilsService,
    private fileExistenceCheckService: FileExistenceCheckService,
    private analyticsFactory: AnalyticsFactory,
    public currentPlanService: CurrentPlanService
  ) {
    this.spinner = false;
    this.isDropdownOpen = false;
    this.componentAccount = null;
    this.componentAccountUsername = null;
    this.provider = null;
    this.fileType = null;
    this.selectedName = null;
    this.fileAccessFailed = false;
    this.revokeFailed = false;
    this.authenticateFailed = false;
    this.conversionError = '';

    this.componentsService.registerDirective({
      type: 'rise-document',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.componentId = this.componentsService.selected.id;

        this.initType(options);
        this._initStorageManager();
        this.load();
      },
      getName: (componentId: string): string => {
        const source = this.attributeDataService.getAvailableAttributeData(componentId, 'source');
        const selectedName = this.attributeDataService.getAvailableAttributeData(componentId, 'name');

        if (source && selectedName) {
          return selectedName;
        }

        if (this.isPowerpoint(componentId)) {
          return 'Powerpoint';
        }
      },
      getIcon: (componentId: string): string => {
        const source = this.attributeDataService.getAvailableAttributeData(componentId, 'source');
        const provider = this.attributeDataService.getAvailableAttributeData(componentId, 'provider');

        if (source && provider === PROVIDER_RISE_STORAGE) {
          return this.storageManagerService.getDocumentType(source);
        }

        if (this.isPowerpoint(componentId)) {
          return StorageManagerService.DOCUMENT_TYPE.POWERPOINT;
        }
      },
      getLabel: (componentId: string): string => {
        if (this.isPowerpoint(componentId)) {
          return 'Powerpoint';
        }
      },
    });

    this.storageUploadManager = {
      uploadedFiles: [],
      folderPath: '',
      isSingleFileSelector: () => true,
      onUploadStatus: (isUploading) => {
        const wasUploading = this.isUploading;
        this.isUploading = isUploading;

        if (wasUploading && !isUploading) {
          this._onStorageFileSelected(this.storageUploadManager.uploadedFiles);

          this.storageUploadManager.uploadedFiles = [];
        }
      },
      addFile: (file) => {
        this.storageUploadManager.uploadedFiles.push(file);
      }
    };
  }

  get sameAccount(): boolean {
    const userAccount = this.authService.getUserIdentifier(PROVIDER_MS_GRAPH);
    return userAccount === this.componentAccount;
  }

  load() {
    this.spinner = false;
    const original = this.source;
    this.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');
    this.componentAccount = this.attributeDataService.getAvailableAttributeData(this.componentId, 'account');
    this.selectedName = this.attributeDataService.getAvailableAttributeData(this.componentId, 'name') || '';
    this.provider = this.attributeDataService.getAvailableAttributeData(this.componentId, 'provider');

    this.values.duration = this.attributeDataService.getAvailableAttributeData(this.componentId, 'duration') || 10;
    this.values.transition = this.attributeDataService.getAvailableAttributeData(this.componentId, 'transition-type') || '';
    this.values.refresh = this.attributeDataService.getAvailableAttributeData(this.componentId, 'refresh') || 720;
    this.values.fileMissing = false;

    if (this.source !== original) {
      this.values.assetCount = null;
    }

    if (this.provider === PROVIDER_MS_GRAPH) {
      this.spinner = true;

      return this._validateCredentials()
      .then(() => {
        return this.microsoftOneDriveService.getFileInfo(PROVIDER_MS_GRAPH, this.componentAccount, this.source)
      })
      .catch(() => {
        this.fileAccessFailed = true;
      })
      .then(() => {
        return this._getAssetCount();
      })
      .finally(() => {
        this.spinner = false;
        this.changeDetectorRef.detectChanges();
      });
    } else if (this.provider === PROVIDER_RISE_STORAGE) {
      this._checkStorageFileExists(this.source);
      return this._convertStorageFile();
    }
  }

  initType(options?: any) {
    const optionsType = options?.fileType ? options.fileType : '';
    const attributeDataType = this.attributeDataService.getAvailableAttributeData(this.componentId, 'type');

    this.fileType = attributeDataType ? attributeDataType : optionsType;

    if (this.fileType && !attributeDataType) {
      // make sure we save this immediately in case user backs out of component without choosing anything
      this.attributeDataService.setAttributeData(this.componentId, 'type', this.fileType);
    }
  }

  private _initStorageManager() {
    this.storageManagerService.isSingleFileSelector = () => true;

    this.storageManagerService.onSelectHandler = selectedItems => this._onStorageFileSelected(selectedItems);
  }

  isPowerpoint(componentId?: string): boolean {
    const type = this.fileType ? this.fileType : this.attributeDataService.getAvailableAttributeData(componentId, 'type');

    return type === StorageManagerService.DOCUMENT_TYPE.POWERPOINT;
  }

  private _checkStorageFileExists(path: string) {
    this.fileExistenceCheckService.checkFileExists(path).then((exists) => {
      this.values.fileMissing = !exists;
    });
  }

  private _convertStorageFile() {
    this.conversionError = '';

    if (!this.values.assetCount) {
      let path = this.storageUtilsService.parseFilePath(this.source);

      if (!path) {
        return;
      }

      this.spinner = true;
      return this.documentService.convertFile(path[2], path[1]).then((count) => {
        this.values.assetCount = count;
      }).catch((error) => {
        console.error(error);

        this.conversionError = error.message;
      }).finally(() => {
        this.spinner = false;
      });
    }
  }

  private _saveAttributeData() {
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
    this.attributeDataService.setAttributeData(this.componentId, 'provider', this.provider);
    this.attributeDataService.setAttributeData(this.componentId, 'account', this.componentAccount);
    this.attributeDataService.setAttributeData(this.componentId, 'source', this.source);
    this.attributeDataService.setAttributeData(this.componentId, 'name', this.selectedName);
    this.attributeDataService.setAttributeData(this.componentId, 'duration', this.values.duration);
    this.attributeDataService.setAttributeData(this.componentId, 'transition-type', this.values.transition);
    this.attributeDataService.setAttributeData(this.componentId, 'refresh', this.values.refresh);

  }

  private _validateCredentials() {

    // get username of a user who configured the component
    return this.authService.getUsername(this.provider, this.componentAccount)
      .then(({authenticated, username}) => {
        this.componentAccountUsername = username;
      })
      .catch(() => {
        console.log('Failed to get username');
      });
  }

  private _connectAccount(provider, scope) {
    return this.authService.getConnectionStatus(provider, scope)
    .catch(() => {
      return this.authService.authenticate(provider, scope);
    });
  }

  private _reset() {
    this.source = null;
    this.selectedName = null;
    this.componentAccount = null;
    this.provider = null;
    this.fileAccessFailed = false;
    this.values.fileMissing = false;
    this.conversionError = '';
  }

  private _getAssetId() {
    return `${this.componentAccount}_${this.source}`;
  }

  private _getAssetCount() {
    return this.documentService.getAssetCount(this._getAssetId(), "onedriveImages")
    .then(count => {
      this.values.assetCount = count;
    })
    .catch(() => {
      this.values.assetCount = 0;
    });
  }

  private _getProviderName(provider: string) {
    if (provider === 'ms-graph') {
      return "OneDrive";
    }

    if (provider === 'rise-storage') {
      return "Rise Storage";
    }

    return '';
  }

  _track() {
    this.analyticsFactory.track('Document Component Updated', {
      companyId: this.companyStateService.getSelectedCompanyId(),
      fileType: this.fileType, // i.e. powerpoint
      provider: this._getProviderName(this.provider) // i.e. onedrive, rise-storage
    });
  }

  _onStorageFileSelected(selectedItems) {
    const value = this.storageUtilsService.getFilePath(selectedItems[0]);

    this.source = value;
    this.selectedName = this.storageUtilsService.parseFolderName(value);
    this.provider = PROVIDER_RISE_STORAGE;
    this.values.fileMissing = false;
    this._checkStorageFileExists(value);

    this.values.assetCount = null;
    this._convertStorageFile();
    this._saveAttributeData();
    this._track();
    // Necessary for playlist component to update fileMissing on playlist item
    this.componentsService.updateComponentValue(value);
  }

  selectFromOneDrive() {
    this.authenticateFailed = false;
    this.fileAccessFailed = false;
    this.spinner = true;

    return this._connectAccount(PROVIDER_MS_GRAPH, SCOPE_ONE_DRIVE)
    .then((authResult) => {
      this.componentAccountUsername = authResult.username;
      const userAccount = this.authService.getUserIdentifier(PROVIDER_MS_GRAPH);
      return this.microsoftOneDriveService.getUserType(userAccount);
    })
    .then((userTypeResponse) => {
      return this.oneDriveFilePickerService.open(this.componentAccountUsername, userTypeResponse?.userType);
    })
    .then(selectedFile => {
      if (selectedFile) {
        this.selectedName = selectedFile['name'];
        this.source = selectedFile['id'];
        this.provider = PROVIDER_MS_GRAPH;
        this.componentAccount = this.authService.getUserIdentifier(null);

        return this.microsoftOneDriveService.subscribe(PROVIDER_MS_GRAPH, this.componentAccount, this.source, this.companyStateService.getSelectedCompanyId())
        .then(() => this._getAssetCount())
        .then(() => {
          this._saveAttributeData();
          this._track();
        })
        .catch(error => {
          this.conversionError = error.message;
        });
      }
    })
    .catch(error => {
      console.log('error', error);
      this.authenticateFailed = true;
    })
    .finally(() => {
      this.spinner = false;

      this.changeDetectorRef.detectChanges();
    });
  }

  private _revoke() {
    this.spinner = true;
    this.revokeFailed = false;

    return this.authService.revoke(this.provider)
      .catch(() => {
        this.revokeFailed = true;
      })
      .finally(() => {
        this.spinner = false;
      });
  }

  selectFromStorage() {
    this.storageManagerService.fileType = this.fileType;

    this.componentsService.editComponent({
      type: 'rise-storage-selector'
    });
  }

  uploadFromComputer() {
    const uploader = document.getElementById(this.fileUploaderId + '-uploader') as HTMLInputElement;
    if (uploader) {
      uploader.click();
    }
  }

  changeFile() {
    this._reset();
    this._saveAttributeData();
  }

  openInPowerPoint() {
    // open window right away (on click) to prevent browser blocking it
    const win = window.open();
    return this.microsoftOneDriveService.getFileInfo(PROVIDER_MS_GRAPH, this.componentAccount, this.source)
    .then(result => {
      if (!result.webUrl) {
        throw new Error("Cannot retrieve PowerPoint URL");
      }
      win.location.href = result.webUrl;
    })
    .catch(error => {
      // ignore the error and just close window
      win.close();
    });
  }

  disconnect() {
    this._revoke();
    this._reset();
    this._saveAttributeData();
  }

  onDropdownOpen() {
    this.isDropdownOpen = true;
  }

  onDropdownClose() {
    this.isDropdownOpen = false;
  }

  saveDuration() {
    this._saveAttributeData();
  }

  saveTransition() {
    this._saveAttributeData();
  }

  saveRefresh() {
    this._saveAttributeData();
  }

}
