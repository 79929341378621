@if (hideNavigation !== undefined) {
  <mat-sidenav-container>
    <mat-sidenav
      [fixedInViewport]="true"
      [mode]="isMobile() ? 'over' : 'side'"
      [opened]="!hideNavigation && sidenavState !== 'closed'"
      (openedChange)="onOpenedChange($event)"
      [@sidenavAnimation]="sidenavState"
      [disableClose]="!isMobile()"
      (@sidenavAnimation.start)="onAnimationStart($event)"
      (@sidenavAnimation.done)="onAnimationDone($event)"
    >
      <sidebar
        [state]="sidenavState"
        [parentAnimating]="isAnimating"
        [currentRoute]="currentRoute"
        [hideNavigation]="hideNavigation"
        (companyMenuOpened)="companyMenuOpened = $event">
      </sidebar>
      <button
        mat-mini-fab
        id="sidenav-toggle"
        [@sidenavToggleAnimation]="toggleAnimationState"
        [@hideToggleAnimation]="sidenavState"
        (click)="toggleSidenav()"
        aria-label="Toggle sidebar"
      >
        <mat-icon class="small medium" svgIcon="chevron-left"></mat-icon>
      </button>
    </mat-sidenav>
    <mat-sidenav-content [@sidenavContentAnimation]="sidenavState">
      <div id="content-layout">
        <div id="content-layout-header" resizeObserver (resizeEvent)="onHeaderResize($event)">
          <common-header [hideNavigation]="hideNavigation" (openMobileMenu)="openMobileMenu()"></common-header>
        </div>
        <div #contentLayoutBody id="content-layout-body">
          <ui-view></ui-view>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
