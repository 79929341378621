import { NgTemplateOutlet } from "@angular/common";
import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, forwardRef, inject, Injector, Input, OnInit, Output, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { debounceTime, merge } from "rxjs";
import { StorageUtilsService } from "src/app/storage/services/storage-utils.service";
import { StorageService } from "src/app/storage/services/storage.service";
import { UrlFieldValidators } from "./url-field-validators";

const URL_FIELD_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UrlFieldComponent),
  multi: true,
};

@Component({
  standalone: true,
  selector: "rv-url-field",
  templateUrl: "./url-field.component.html",
  styleUrls: ["./url-field.component.scss"],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    NgTemplateOutlet,
    MatSlideToggleModule
  ],
  providers: [URL_FIELD_ACCESSOR],
})
export class UrlFieldComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() acceptedFileTypes: keyof typeof StorageUtilsService.SELECTOR_FILTERS;
  @Input() allowSkipValidation = false;
  @Output() removeValidationChange = new EventEmitter<boolean>();

  protected onTouch: () => void;
  protected onChange: (value: string) => void;
  protected fileValidator: ValidatorFn;
  protected disabled = false;
  protected value = '';
  protected control: NgControl;
  protected inputControl = new FormControl('');
  protected destroyRef = inject(DestroyRef);
  protected inputId = '';
  protected showSkipValidation = signal(false);
  protected forcedValid = false;

  constructor(
    private injector: Injector,
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService,
    elementRef: ElementRef
  ) {
    this.inputId = (elementRef.nativeElement['id'] || 'url-field') + '-input';
  }

  ngOnInit() {
    // init control onInit to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.control = this.injector.get(NgControl);
  }

  ngAfterViewInit(): void {
    merge(
      this.control.valueChanges,
      this.control.statusChanges
    ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.inputControl.clearValidators();
      this.inputControl.updateValueAndValidity({emitEvent: false});
      this.checkControlErrors(this.control.control);
      this.checkSkipValidation();
    });
    this.inputControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(300)
    ).subscribe((value) => {
      this.control.control.removeValidators(this.fileValidator);
      this.control.control.updateValueAndValidity({emitEvent: false});
      this.inputControl.valid && this.onChange && this.onChange(value);
      this.control.control.markAsDirty();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  writeValue(obj: string): void {
    this.inputControl.setValue(obj, {emitEvent: false});
    this.inputControl.updateValueAndValidity({emitEvent: false});
  }

  private checkSkipValidation() {
    if (!this.allowSkipValidation) return;

    const skipValidation = this.forcedValid
      || (this.control.control.dirty && this.control.control.invalid);
    const { errors } = this.control.control;

    if (!errors && !this.forcedValid) {
      this.showSkipValidation.set(false);
      return;
    }
    if (!errors) return;
    const shouldShowSkipValidation = skipValidation
      && !errors['required']
      && !errors['url']
      && !errors['noPreviewUrl']
      && (
        !errors['responseHeader']
        || (errors['responseHeader'] !== 'not-reachable')
      );

    this.showSkipValidation.set(shouldShowSkipValidation);
    if (!shouldShowSkipValidation && this.forcedValid) {
      this.forcedValid = false;
      this.removeValidationChange.emit(false);
    }
  }

  private checkControlErrors(control: AbstractControl): void {
    if(control.errors && this.inputControl.dirty) {
      const { errors } = control;

      setTimeout(() => {
        this.inputControl.markAsTouched();
        this.inputControl.markAsDirty();
        this.inputControl.updateValueAndValidity({emitEvent: false});
        this.inputControl.setErrors(errors);
      });
    }
  }

  protected onBlur() {
    if (!this.inputControl.value?.trim()) {
      this.inputControl.setValue('');
      this.inputControl.markAsTouched();
      this.inputControl.markAsDirty();
      this.control.control.markAsDirty();
      this.inputControl.updateValueAndValidity();
      this.control.control.updateValueAndValidity();
    }
    this.onTouch();
  }

  protected openFileSelector(): void {
    this.storageService.showStorageModal(this.acceptedFileTypes).then((files) => {
      if (files) {
        this.fileValidator = UrlFieldValidators.file(this.acceptedFileTypes);
        this.control.control.addValidators(this.fileValidator);
      }
      else this.control.control.removeValidators(this.fileValidator);
      const [fileUrl] = this.storageUtilsService.getFileUrls(files);

      this.inputControl.setValue(fileUrl, {emitEvent: false});
      this.onChange(fileUrl);
    });
  }
}
