@if (!hideNavigation) {
  <div id="commonHeaderDiv">
    <!-- Plan Banner -->
    <plan-banner [hidden]="isAssignedPublisher"></plan-banner>
    <!-- END Plan Banner -->

    <!-- Common Header Navbar -->
    <nav class="navbar navbar-default hidden-print tw-flex" role="navigation">
      <div class="tw-flex tw-justify-between tw-items-center tw-flex-grow">
        <div class="tw-flex tw-gap-2 tw-px-2 tw-items-center">
          @if (!isScreenShareApp) {
            <a mat-icon-button class="navbar-brand lg:!tw-hidden text-center tw-cursor-pointer" id="mobileMenuButton" (click)="openMobileMenu.emit()">
              <mat-icon svgIcon="menu" class="large medium"></mat-icon>
            </a>
          } @else {
            <a mat-icon-button class="navbar-brand text-center tw-cursor-pointer" id="mobileMenuButton" (click)="openMobileMenu.emit()">
              <mat-icon svgIcon="menu" class="large medium"></mat-icon>
            </a>
          }
          <div [ngClass]="{ 'lg:!tw-ml-8': !isScreenShareApp }">
            <page-title></page-title>
          </div>
        </div>

        <div class="tw-flex tw-items-center">
          <!-- Nav Links -->
          @if (ENV_NAME) {
            <div class="visible-xs visible-sm">
              <ul class="nav navbar-nav">
                <li class="env-name-nav-item">
                  <span class="env-name-label">{{ENV_NAME}}</span>
                </li>
              </ul>
            </div>
          }

          <div class="navbar-collapse hidden-xs hidden-sm">
            <ul id="commonHeaderNav" class="nav navbar-nav">
              @if (ENV_NAME) {
                <li class="env-name-nav-item visible-md visible-lg">
                  <span class="env-name-label">{{ENV_NAME}}</span>
                </li>
              }
            </ul>
          </div>

          <!-- Action Nav -->
          <ul class="nav navbar-nav actions-nav lg:tw-gap-1">
            @if (!isScreenShareApp) {
              <!-- Help Widget -->
              <help-widget-button></help-widget-button>

              <!-- Appcues Launchpad -->
              <li id="appcues-launchpad"></li>
            }

            <!-- Auth -->
            <auth-buttons></auth-buttons>
          </ul>
          <!-- END Action Nav -->
        </div>

        <!-- END Nav Links -->
      </div>

    </nav>
  </div>
}

@if (!cookieEnabled) {
  <div class="bg-warning text-center u_padding-sm">
    <small><strong>Cookies Are Disabled.</strong> Rise Vision needs to use cookies to properly function. Please enable cookies in your web browser and refresh this page.</small>
  </div>
}

