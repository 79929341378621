import { Component, ElementRef, AfterViewInit, Output, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { CompanyAccessService } from 'src/app/common-header/services/company-access.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { StateService } from '@uirouter/angular';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'select-company',
    templateUrl: './select-company.component.html',
    styleUrls: ['./select-company.component.scss'],
    standalone: false
})
export class SelectCompanyComponent implements AfterViewInit, OnInit, OnDestroy {

  @Output() closeMenu = new EventEmitter<void>();

  filterConfig = {
    placeholder: 'Search Companies'
  };

  allCompanies = true;
  search = {
    companyId: this.companyStateService.getSelectedCompanyId(),
    sortBy: 'name',
    reverse: false,
    name: 'Companies',
    includeSubcompanies: this.allCompanies
  };

  companies = this.ScrollingListService(this.companyApiService.getCompanies.bind(this.companyApiService), this.search);

  companySelectedSubscription: Subscription;

  constructor(
    private broadcasterService: BroadcasterService,
    protected companyAccessService: CompanyAccessService,
    protected companyStateService: CompanyStateService,
    private ScrollingListService: ScrollingListService,
    private companyApiService: CompanyApiService,
    private featuresService: FeaturesService,
    private elementRef: ElementRef,
    private stateService: StateService
  ) {
  }

  ngOnInit() {
    this.companySelectedSubscription = this.broadcasterService.on('risevision.company.selectedCompanyChanged', () => {
      this.search.companyId = this.companyStateService.getSelectedCompanyId();
      this.companies.doSearch();
    });
  }

  ngOnDestroy() {
    this.companySelectedSubscription.unsubscribe();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.calculateListHeight();
  }

  ngAfterViewInit() {
    setTimeout(() => this.calculateListHeight(), 0);
  }


  calculateListHeight() {
    const viewportHeight = window.innerHeight;
    const headerElement = this.elementRef.nativeElement.querySelector('.company-menu-header');
    const footerElement = this.elementRef.nativeElement.querySelector('.company-menu-footer');

    if (headerElement) {
      const headerHeight = headerElement.offsetHeight;
      const footerHeight = footerElement ? footerElement.offsetHeight : 40;
      const padding = 40;
      const availableHeight = viewportHeight - headerHeight - footerHeight - padding;
      const maxHeight = Math.max(availableHeight, 150);

      document.documentElement.style.setProperty(
        '--company-list-max-height',
        `${maxHeight}px`
      );
    }
  }

  setCompany(company) {
    this.companyStateService.switchCompany(company.id);
    this.closeMenu.emit();
  }

  toggleAllCompanies(value) {
    this.allCompanies = value;

    this.search.includeSubcompanies = value;
    this.companies.doSearch();
  }

  licenseManagement() {
    if (this.companyAccessService.accessCompanyLicenses) {
      this.stateService.go('apps.company.licenses');
      this.closeMenu.emit();
    } else {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_LICENSE_MANAGEMENT);
    }
  }

  resetCompany() {
    this.companyStateService.resetCompany();
    this.closeMenu.emit();
  }
}
