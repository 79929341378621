import { Component, EventEmitter, Input, Output, ViewChild, QueryList } from '@angular/core';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'upgrade-notice',
    templateUrl: './upgrade-notice.component.html',
    styleUrl: './upgrade-notice.component.scss',
    standalone: false
})
export class UpgradeNoticeComponent {

  static hidePopovers(notices?: QueryList<UpgradeNoticeComponent>) {
    if (notices) {
      notices.forEach((notice) => {
        notice.popover.onShown.subscribe(() => {
          notices.filter(n => n !== notice).forEach(n => n.popover.hide());
        });
      });
    }
  }

  @Input() feature: string;
  @Input() overlay: boolean = false;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('popoverDirective') popover: PopoverDirective;

  get requiredPlan() {
    return this.featuresService.featureRequiresEnterprise(this.feature) ?
      this.companyStateService.isK12Customer() ?
        'Unlimited'
      : 'Enterprise'
    : 'Advanced';
  }

  constructor(
    private companyStateService: CompanyStateService,
    public featuresService: FeaturesService
  ) {}

  showUpgradeModal() {
    this.featuresService.showUpgradePlanModal(this.feature).then((result) => {
      if (result) {
        this.closeEvent.emit();
      }
    });
  }
}
