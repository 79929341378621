import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { CompanyTreeItem } from '../../services/company';
import { CompanyAllocationService } from '../../services/company-allocation.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
    selector: 'tr[company-license-item]',
    templateUrl: './company-license-item.component.html',
    styleUrls: ['./company-license-item.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class CompanyLicenseItemComponent {

  @Input() company: CompanyTreeItem;
  @Input() last: boolean;
  @Input() root: boolean;
  @Output() selectCompany: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public companyAllocationService: CompanyAllocationService,
    public companyStateService: CompanyStateService,
    private parentForm: NgForm
  ) { }

  updateCount(value: number): void {
    this.companyAllocationService.allocateCount(this.company, value);
    this.markFormDirty();
  }

  allocateType(value: string): void {
    this.companyAllocationService.allocateType(this.company, value);
    this.markFormDirty();
  }

  private markFormDirty(): void {
    this.parentForm.form.markAsDirty();
    this.parentForm.form.updateValueAndValidity();
  }
}
