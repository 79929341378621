<div rvSpinner="user-settings" [rvShowSpinner]="loading">
  <!-- body -->
  <div id="user-settings">
    <form #userForm="ngForm" id="forms.userForm" role="form" name="forms.userForm" autocomplete="on" (submit)="save()" novalidate>
      <common-actions
        [actions]="actions"
        [saving]="loading">
      </common-actions>

      <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
        <strong>{{formError}}</strong> {{apiError}}
      </div>

      <div class="tw-px-8">
        <div class="form-group required"
          [ngClass]="{ 'has-error' : username.invalid && (!username.pristine || userForm.submitted) }">
          <label>
            Username:
          </label>
          <input #username="ngModel" id="user-settings-username"
            type="email" required name="username"
            class="form-control"
            [(ngModel)]="user.username"
            [pattern]="EMAIL_REGEX"
            />
            <p [shown]="username.invalid && (!username.pristine || userForm.submitted)"
              class="help-block validation-error-message-email">User name must be a valid email address.</p>
        </div>
  
        <user-fields [user]="user"></user-fields>
      </div>
    </form><!-- userAdd -->
  </div>
</div><!--container-->