import { Component, Input } from '@angular/core';
import { BillingUtilsService } from 'src/app/billing/services/billing-utils.service';

@Component({
    selector: 'status-name',
    templateUrl: './status-name.component.html',
    styleUrls: ['./status-name.component.scss'],
    standalone: false
})
export class StatusNameComponent {

  @Input() subscription;

  constructor(public billingUtils: BillingUtilsService) {

  }

}
