import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CalendarMonthYearHeaderComponent } from '../../calendar-month-year-header/calendar-month-year-header.component';
import { ModalComponent } from '../../modals/modal.component';
import { TimelineService } from '../timeline.service';

@Component({
    selector: 'timeline-modal',
    templateUrl: './timeline-modal.component.html',
    styleUrls: ['./timeline-modal.component.scss'],
    standalone: false
})
export class TimelineModalComponent extends ModalComponent implements OnInit {

  timeline: {
    startDate: Date;
    endDate?: Date;
    recurrence: any;
    allDay: boolean;
    startTime: Date;
    endTime: Date;
    recurrenceType: string;
    always: boolean;
    useLocaldate: boolean;
  };
  recurrence: any;
  /** Material Design calendar header component for date picker customization */
  calendarMonthYearHeader = CalendarMonthYearHeaderComponent;

  processing = false;
  today = new Date();
  matDatepickerConfig = {
    startView: 'month',
    startAt: new Date(),
  };

  constructor(public modalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private timelineService: TimelineService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.timelineService.timeline = this.timeline;
    this.recurrence = this.timelineService.recurrence;

    if (this.timeline.startDate && this.timeline.startDate.getTime() < this.today.getTime()) {
      this.today = new Date(this.timeline.startDate.getTime());
    }
  }

  detectChanges () {
    this.changeDetectorRef.detectChanges();
  }

  apply () {
    this.processing = true;
    this.timelineService.save();
    super.resolve(this.timeline);
  }

  dismiss () {
    super.reject();
  }

}