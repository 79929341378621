import { Component, ElementRef } from '@angular/core';

import { CalendarMonthYearHeaderComponent } from 'src/app/components/calendar-month-year-header/calendar-month-year-header.component';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';

@Component({
    selector: 'template-component-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
    standalone: false
})
export class CounterComponent {
  
  /** Material Design calendar header component for date picker customization */
  protected calendarMonthYearHeader = CalendarMonthYearHeaderComponent;
  protected matDatepickerConfig = {
    startView: 'month',
    startAt: new Date(),
  };
  public componentId: string;
  public counterType;
  public nonCompletion;
  public completionMessage;
  public targetUnit;
  public targetDate;
  public targetDateTime;
  public targetTime;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService,
    private templateEditorUtils: TemplateEditorUtilsService) {

    this.componentsFactory.registerDirective({
      type: 'rise-data-counter',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
      },
      getTitle: component => {
        return 'Count ' + this.templateEditorUtils.capitalizeFirstLetter(component.attributes.type.value);
      }
    });
  }

  _load() {
    var counterType = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'type');
    var targetDate = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'date');
    var targetTime = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'time');
    var nonCompletion = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'non-completion');
    var completion = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'completion');

    this.counterType = counterType;
    this.nonCompletion = nonCompletion === '';
    this.completionMessage = completion;

    if (targetDate) {
      var localDate = new Date(targetDate);
      localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());

      this.targetUnit = 'targetDate';

      this.targetDate = localDate;
      this.targetDateTime = targetTime;
    } else if (targetTime) {
      this.targetTime = targetTime;
      this.targetUnit = 'targetTime';
    } else {
      this.targetDate = null;
      this.targetTime = null;
      this.targetUnit = null;
    }
  }

  save() {
    if (this.targetUnit === 'targetDate' && this.targetDate) {
      var localDate = new Date(this.targetDate);
      localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());

      this.attributeDataFactory.setAttributeData(this.componentId, 'date', this.templateEditorUtils.formatISODate(localDate));
      this.attributeDataFactory.setAttributeData(this.componentId, 'time', this.targetDateTime);
    } else if (this.targetUnit === 'targetTime' && this.targetTime) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'date', null);
      this.attributeDataFactory.setAttributeData(this.componentId, 'time', this.targetTime);
    }

    if (this.counterType === 'down' && !this.nonCompletion) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'completion', this.completionMessage);
    }
  }

}
