import { Component } from '@angular/core';

@Component({
    selector: 'viewport',
    templateUrl: './viewport.component.html',
    styleUrls: ['./viewport.component.scss'],
    standalone: false
})
export class ViewportComponent {
}
