import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'upgrade-feature-modal',
    templateUrl: './upgrade-feature-modal.component.html',
    styleUrl: './upgrade-feature-modal.component.scss',
    standalone: false
})
export class UpgradeFeatureModalComponent {

  cta = '';
  title = '';
  description = '';
  imageSource = '';
  background = '';
  helpLink = '';

  constructor(
    public dialogRef: MatDialogRef<UpgradeFeatureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpgradeFeatureModalData
  ) {
    this.cta = data.cta;
    this.title = data.title;
    this.description = data.description;
    this.imageSource = data.imageSource;
    this.background = data.background;
    this.helpLink = data.helpLink;
  }

  dismiss() {
    this.dialogRef.close(false);
  }

  upgrade() {
    this.dialogRef.close(true);
  }
}

interface UpgradeFeatureModalData {
  cta: string;
  title: string;
  description: string;
  imageSource: string;
  background: string;
  helpLink: string;
}