import { Component } from '@angular/core';
import { BlobDownloaderFactory } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
    selector: 'bulk-activation',
    templateUrl: './bulk-activation.component.html',
    styleUrls: ['./bulk-activation.component.scss'],
    standalone: false
})
export class BulkActivationComponent {
  timeZone = "America/New_York";
  useClaimId = "yes";
  isEthernet = false;
  password = "";
  ssid = "";

  constructor(
    private companyStateService: CompanyStateService,
    private blobDownloaderFactory: BlobDownloaderFactory
  ) {

  }

  getTimezones() {
    return Intl.supportedValuesOf("timeZone");
  }

  generateConfigFile() {
    const filename = "risevision.ini";
    const claimId = this.companyStateService.getCopyOfUserCompany(true).claimId;

    const config = `
      wifi_ssid=${this.ssid}
      wifi_password=${this.password}
      timezone=${this.timeZone}
      claim_id=${this.useClaimId === "yes" ? claimId : ''}
    `;

    this.blobDownloaderFactory(new Blob([config]), filename);

    return config;
  }

  toggleEthernet() {
    this.isEthernet = !this.isEthernet;
    this.password = "";
    this.ssid = "";
  }
}
