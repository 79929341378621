import { Component, ElementRef, Input, OnInit, OnDestroy, DoCheck } from '@angular/core';

import * as _ from 'lodash';
import * as $ from 'jquery';

import { WidgetRendererService } from '../../services/widget-renderer.service';

@Component({
    selector: 'artboard-placeholder',
    templateUrl: './artboard-placeholder.component.html',
    styleUrls: ['./artboard-placeholder.component.scss'],
    standalone: false
})
export class ArtboardPlaceholderComponent implements OnInit, OnDestroy, DoCheck {
  private _oldPlaceholder;

  @Input() placeholder : any;

  constructor(private elementRef: ElementRef,
    private widgetRendererService: WidgetRendererService) { }

  ngOnInit(): void {
    this._oldPlaceholder = _.cloneDeep(this.placeholder);
    this.widgetRendererService.register(this.placeholder, $(this.elementRef.nativeElement));
  }

  ngOnDestroy(): void {
    this.widgetRendererService.unregister(this.placeholder, $(this.elementRef.nativeElement));
  }

  ngDoCheck(): void {
    if (!_.isEqual(this.placeholder, this._oldPlaceholder)) {
      if (this.placeholder.id !== this._oldPlaceholder.id) {
        this.widgetRendererService.unregister(this._oldPlaceholder, $(this.elementRef.nativeElement));
      }

      this.widgetRendererService.notifyChanges(this.placeholder, $(this.elementRef.nativeElement));
      if (this.placeholder.items && this.placeholder.items[0] && this._oldPlaceholder.items &&
        this._oldPlaceholder.items[0] && this.placeholder.items[0].additionalParams !==
        this._oldPlaceholder.items[0].additionalParams) {
        this.widgetRendererService.forceReload(this.placeholder, $(this.elementRef.nativeElement));
      }

      this._oldPlaceholder = _.cloneDeep(this.placeholder);
    }
  }

}
