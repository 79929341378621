import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { SlidesUrlValidationServiceService } from '../services/slides-url-validation-service.service';


@Component({
    selector: 'template-component-slides',
    templateUrl: './slides.component.html',
    styleUrls: ['./slides.component.scss'],
    standalone: false
})
export class SlidesComponent {

  spinner = false;
  componentId;
  src;
  duration;
  validationResult;
  
  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService, 
      private attributeDataFactory: AttributeDataService,
      private slidesUrlValidationService: SlidesUrlValidationServiceService,
      private changeDetectorRef: ChangeDetectorRef,
      private insecureUrl: InsecureUrl) {
    componentsFactory.registerDirective({
      type: 'rise-slides',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
        this.saveSrc(); //validate Slides URL
      }
    });
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this._directiveIsVisible() && !document.hidden) {
      this.saveSrc();
    }
  }

  _load() {
    this.src = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'src');
    this.duration = parseInt(this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'duration')) || 10;
  }

  saveDuration() {
    if (this.duration > 0) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'duration', this.duration);
    }
  }

  saveSrc() {
    if (this._validateSrcLocally()) {

      this.attributeDataFactory.setAttributeData(this.componentId, 'src', this.src);

      this.spinner = true;

      this.slidesUrlValidationService.validate(this.src)
        .then( (result) => {
          this.validationResult = result;
        })
        .finally( () => {
          this.spinner = false;
          this.changeDetectorRef.detectChanges();
        });
    }
    this.changeDetectorRef.detectChanges();
  }

  

  _directiveIsVisible() {
    // This directive is instantiated once by templateAttributeEditor
    // It becomes visible when <rise-slides> is selected
    return this.componentsFactory.selected && (this.componentsFactory.selected.type === 'rise-slides');
  }

  _validateSrcLocally() {

    //clear the error
    this.validationResult = '';

    var PUBLISHED_URL_REGEXP =
      /^(http:|https:)\/\/docs\.google\.com\/presentation\/d\/e\/([^\s]+)\/(pub|embed)(\?|$)/i;
    var BROWSER_URL_REGEXP = /^(http:|https:)\/\/docs\.google\.com\/presentation\/d\/([\w-_]+)/i;

    var _src = !this.src ? '' : this.src.trim();
    if (_src === '') {
      //empty string is allowed
      return true;
    }

    if (this.insecureUrl(_src)) {
      this.src = _src.replace('http://', 'https://');
    }

    //check if uses entered Published URL
    if (PUBLISHED_URL_REGEXP.test(_src)) {
      //nothing to do here - URL is formatted correctly
      return true;
    }

    //check if uses entered URL from browser navigation bar
    if (BROWSER_URL_REGEXP.test(_src)) {
      var slideId = BROWSER_URL_REGEXP.exec(_src)[2];
      this.src = this._slideIdToEmbedUrl(slideId);
      return true;
    }

    //check if uses entered Slide ID - there should be no occurrences of '/' in the string
    if (_src.indexOf('/') === -1) {
      this.src = this._slideIdToEmbedUrl(_src);
      return true;
    }

    this.validationResult = 'INVALID';
    return false;
  }

  _slideIdToEmbedUrl(slideId) {
    // There are 2 types of Slide ID: 
    //  1) Google doc ID i.e. ID copied from the browser's navigation bar
    //  2) ID of the published URL. This ID ususally starst with "2PACX-"
    // For this implementaion we assume user entered Google doc ID (option #1)
    return 'https://docs.google.com/presentation/d/' + slideId + '/embed';
  }
}
