import { Component, Input } from '@angular/core';

@Component({
    selector: 'last-modified',
    templateUrl: './last-modified.component.html',
    styleUrls: ['./last-modified.component.scss'],
    standalone: false
})
export class LastModifiedComponent {

  @Input() changeDate: Date;
  @Input() changedBy: string;

}