import { Component, ElementRef, OnInit, signal } from '@angular/core';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';

import {
  VideoStreamingMedia,
  VideoStreamingValidationStatus,
  VideoStreamingValidationService,
} from '../services/video-streaming-validation.service';

@Component({
    selector: 'template-component-video-streaming',
    templateUrl: './video-streaming.component.html',
    styleUrls: ['./video-streaming.component.scss'],
    standalone: false
})
export class VideoStreamingComponent implements OnInit {
  spinner = false;
  componentId: string;
  source: string;
  sourceStatus: VideoStreamingValidationStatus;
  volume: number;

  captionTracks = signal<VideoStreamingMedia[]>([]);
  selectedCaptionTrackIndex = signal<number>(null);
  audioTracks = signal<VideoStreamingMedia[]>([]);
  selectedAudioTrackIndex = signal<number>(null);

  constructor(
    private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataService: AttributeDataService,
    private insecureUrl: InsecureUrl,
    private videoStreamingService: VideoStreamingValidationService
  ) {
    componentsFactory.registerDirective({
      type: 'rise-video-streaming',
      element: this.elementRef.nativeElement,
      show: async () => {
        this.componentId = this.componentsFactory.selected.id;
        this.load();
      },
    });
  }

  load = async () => {
    this.source = this.attributeDataService.getAvailableAttributeData(
      this.componentId,
      'source'
    );

    this.volume =
      this.attributeDataService.getAvailableAttributeData(
        this.componentId,
        'volume'
      ) || 0;

    if (this.source) {
      this.spinner = true;

      try {
        this.sourceStatus = await this.videoStreamingService.validateHLSContent(
          this.source,
          this.captionTracks,
          this.audioTracks
        );
      } catch (error) {
        this.sourceStatus = 'INVALID';
      } finally {
        this.spinner = false;
      }
    }

    const selectedCaptionTrack =
      this.attributeDataService.getAvailableAttributeData(
        this.componentId,
        'captionTrack'
      );
    this.selectedCaptionTrackIndex.set(selectedCaptionTrack?.index ?? null);

    const selectedAudioTrack =
      this.attributeDataService.getAvailableAttributeData(
        this.componentId,
        'audioTrack'
      );
    this.selectedAudioTrackIndex.set(selectedAudioTrack?.index ?? null);
  };

  ngOnInit(): void {}

  validateSource() {
    //clear the error
    this.sourceStatus = undefined;

    const _source = !this.source ? '' : this.source.trim();
    if (_source === '') {
      //empty string is allowed
      return true;
    }

    if (this.insecureUrl(_source)) {
      this.source = _source.replace('http://', 'https://');
    } else {
      this.source = _source;
    }

    if (this.videoStreamingService.validateHLSUrl(_source)) {
      this.sourceStatus = 'VALID';
      return true;
    }

    this.sourceStatus = 'INVALID';
    return false;
  }

  async saveSource() {
    const savedSource = this.attributeDataService.getAvailableAttributeData(
      this.componentId,
      'source'
    );

    if (savedSource === this.source || !this.validateSource()) {
      return;
    }

    if (this.source) {
      this.spinner = true;
      this.sourceStatus = await this.videoStreamingService.validateHLSContent(
        this.source,
        this.captionTracks,
        this.audioTracks
      );
      this.spinner = false;

      if (this.sourceStatus !== 'VALID') {
        return;
      }
    } else {
      this.captionTracks.set([]);
      this.audioTracks.set([]);
    }

    this.selectedCaptionTrackIndex.set(null);
    this.selectedAudioTrackIndex.set(null);

    this.attributeDataService.setMultipleAttributeData(this.componentId, {
      source: this.source,
      captionTrack: null,
      audioTrack: null,
    });
  }

  saveVolume() {
    this.attributeDataService.setAttributeData(
      this.componentId,
      'volume',
      this.volume
    );
  }

  saveCaptionTrack(captionTrackIndex: number) {
    this.selectedCaptionTrackIndex.set(captionTrackIndex);
    const captionTrack =
      this.captionTracks()?.find(
        (captionTrack) =>
          captionTrack.index === this.selectedCaptionTrackIndex()
      ) || null;

    this.attributeDataService.setAttributeData(
      this.componentId,
      'captionTrack',
      captionTrack
    );
  }

  saveAudioTrack(audioTrackIndex: number) {
    this.selectedAudioTrackIndex.set(audioTrackIndex);
    const audioTrack =
      this.audioTracks()?.find(
        (audioTrack) => audioTrack.index === this.selectedAudioTrackIndex()
      ) || null;

    this.attributeDataService.setAttributeData(
      this.componentId,
      'audioTrack',
      audioTrack
    );
  }
}
