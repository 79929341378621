import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy, DoCheck, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

import { TemplateEditorService } from '../../services/template-editor.service';
import { ComponentsService } from '../../services/components.service';
import { BlueprintService } from '../../services/blueprint.service';
import { BrandingService } from '../../template-components/services/branding.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { StorageCacheBusterService } from '../../services/storage-cache-buster.service';

@Component({
    selector: 'template-editor-preview-holder',
    templateUrl: './template-editor-preview-holder.component.html',
    styleUrls: ['./template-editor-preview-holder.component.scss'],
    standalone: false
})
export class TemplateEditorPreviewHolderComponent implements AfterViewInit, DoCheck, OnDestroy {
  private DEFAULT_TEMPLATE_WIDTH = 800;
  private DEFAULT_TEMPLATE_HEIGHT = 600;
  private MOBILE_PREVIEW_HEIGHT = 200;
  private MOBILE_PREVIEW_HEIGHT_SHORT = 140;
  private MOBILE_MARGIN = 10;
  private DESKTOP_MARGIN = 20;
  private PREVIEW_INITIAL_DELAY_MILLIS = 1000;
  private RLC_STAGING_PARAM = 'rlcStage';
  private CT_STAGING_PARAM = 'ctStage';

  private subscription: any;

  private iframeLoaded = false;
  private debounceAttributeDataTimer;
  public editorPreviewUrl;

  private componentWasSelected = false;

  private debounceResizeTimer;

  @ViewChild('templateEditorPreviewParent') iframeParent;
  @ViewChild('templateEditorPreview') iframe;

  _getPreviewHolder() {
    return this.elementRef.nativeElement.parentElement;
  }

  _getIframeParent() {
    return this.iframeParent.nativeElement;
  }

  _getIframe(): any {
    return this.iframe.nativeElement;
  }

  onResize() {
    if (this.debounceResizeTimer) {
      clearTimeout(this.debounceResizeTimer);
    }

    this.debounceResizeTimer = setTimeout(() => {
      this._applyAspectRatio();
    }, 100);
  }

  constructor(private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private broadcaster: BroadcasterService,
    private companyStateService: CompanyStateService,
    private templateEditorFactory: TemplateEditorService,
    private componentsFactory: ComponentsService,
    private storageCacheBusterService: StorageCacheBusterService,
    private blueprintFactory: BlueprintService,
    private brandingFactory: BrandingService) {

    this.subscription = this.broadcaster.subscribe((event: string) => {
      switch (event) {
      case 'risevision.template-editor.presentationUnsavedChanges':
      case 'risevision.template-editor.logoFileMetadata':
      case 'risevision.template-editor.storageCacheLoaded':
        this._debounceAttributeData(this._postAttributeData);
        break;
      case 'risevision.company.updated':
      case 'risevision.company.selectedCompanyChanged':
      case 'risevision.template-editor.brandingUnsavedChanges':
        // ensure branding factory updates branding via the same handler
        setTimeout(() => {
          this._postDisplayData();
        });
        break;
      default:
        return;
      }
    });

    this._setEditorPreviewUrl();

  }

  ngAfterViewInit() {
    this._applyAspectRatio();
    setTimeout(this._applyAspectRatio.bind(this), this.PREVIEW_INITIAL_DELAY_MILLIS);
  }

  ngDoCheck(): void {
    if (this.componentWasSelected && !this.componentsFactory.selected) {
      this._applyAspectRatio();
    }

    this.componentWasSelected = !!this.componentsFactory.selected;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onLoad(event) {
    if (event.target.src != '') {
      this.iframeLoaded = true;

      this._postDisplayData();
      this._postAttributeData();
      this._postStartEvent();
    }
  }

  _debounceAttributeData(callback) {
    if (this.debounceAttributeDataTimer) {
      clearTimeout(this.debounceAttributeDataTimer);
    }

    this.debounceAttributeDataTimer = setTimeout(callback.bind(this), 500);
  }

  _getWindowHref() {
    return window.location.href;
  }

  _getContentStagingParams() {
    var componentsStage = '';
    var commonStage = '';
    var parsedUrl = new URL(this._getWindowHref());

    parsedUrl.searchParams.forEach((value, key) => {
      if (key === this.RLC_STAGING_PARAM) {
        componentsStage = '&' + this.RLC_STAGING_PARAM + '=' + value;
      }

      if (key === this.CT_STAGING_PARAM) {
        commonStage = '&' + this.CT_STAGING_PARAM + '=' + value;
      }
    });

    return componentsStage + commonStage;

  }

  _setEditorPreviewUrl() {
    if (!this.templateEditorFactory.presentation) {
      return;
    }

    var productCode = this.templateEditorFactory.presentation.productCode;
    var presentationId = this.templateEditorFactory.presentation.id;
    var companyId = this.templateEditorFactory.presentation.companyId;
    var contentStagingParams = this._getContentStagingParams();

    var url = environment.HTML_TEMPLATE_URL.replace('PRODUCT_CODE', productCode) + '?type=preview&cid=' + companyId + '&presentationId=' +
      presentationId + contentStagingParams;

    this.editorPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  _getTemplateWidth() {
    var width = this.blueprintFactory.blueprintData && this.blueprintFactory.blueprintData.width;

    return width ? parseInt(width) : this.DEFAULT_TEMPLATE_WIDTH;
  }

  _getTemplateHeight() {
    var height = this.blueprintFactory.blueprintData && this.blueprintFactory.blueprintData.height;

    return height ? parseInt(height) : this.DEFAULT_TEMPLATE_HEIGHT;
  }

  _getPreviewAreaWidth() {
    let previewHolder = this._getPreviewHolder();

    return previewHolder.clientWidth;
  }

  _getPreviewAreaHeight() {
    let previewHolder = this._getPreviewHolder();

    return previewHolder.clientHeight;
  }

  _getHeightDividedByWidth() {
    return this._getTemplateHeight() / this._getTemplateWidth();
  }

  _useFullWidth() {
    var offset = 2 * this.DESKTOP_MARGIN;
    var aspectRatio = this._getHeightDividedByWidth();
    var projectedHeight = (this._getPreviewAreaWidth() - offset) * aspectRatio;

    return projectedHeight < this._getPreviewAreaHeight() - offset;
  }

  _getWidthFor(height) {
    var value = height / this._getHeightDividedByWidth();

    return value;
  }

  _mediaMatches(mediaQuery) {
    return window.matchMedia(mediaQuery).matches;
  }

  getMobileWidth () {
    var isShort = this._mediaMatches('(max-height: 570px)');
    var layerHeight = isShort ? this.MOBILE_PREVIEW_HEIGHT_SHORT : this.MOBILE_PREVIEW_HEIGHT;

    var value = this._getWidthFor(layerHeight);

    return value.toFixed(0);
  }

  getDesktopWidth() {
    var height = this._getPreviewAreaHeight();

    return this._getWidthFor(height).toFixed(0);
  }

  _getFrameStyle(viewSize, templateSize) {
    var ratio = (viewSize / templateSize).toFixed(4);
    var width = this._getTemplateWidth();
    var height = this._getTemplateHeight();

    return 'width: ' + width + 'px;' +
      'height: ' + height + 'px;' +
      'transform:scale3d(' + ratio + ',' + ratio + ',' + ratio + ');';
  }

  _applyAspectRatio() {
    var frameStyle, viewHeight;
    var parentStyle = 'height: 100%; width: 100%;';
    var isMobile = this._mediaMatches('(max-width: 768px)');
    var offset = (isMobile ? this.MOBILE_MARGIN : this.DESKTOP_MARGIN) * 2;

    let iframeParent = this._getIframeParent();
    let iframe = this._getIframe();

    if (isMobile) {
      viewHeight = this._getPreviewAreaHeight() - offset;
      parentStyle = 'width: ' + this.getMobileWidth() + 'px';
      frameStyle = this._getFrameStyle(viewHeight, this._getTemplateHeight());
    } else if (this._useFullWidth()) {
      var viewWidth = this._getPreviewAreaWidth() - offset;

      frameStyle = this._getFrameStyle(viewWidth, this._getTemplateWidth());
    } else {
      viewHeight = this._getPreviewAreaHeight() - offset;

      frameStyle = this._getFrameStyle(viewHeight, this._getTemplateHeight());
    }

    iframeParent.setAttribute('style', parentStyle);
    iframe.setAttribute('style', frameStyle);
  }

  _updateLogoData(attributeData) {
    if (attributeData && attributeData.components) {
      var logoComponents = this.blueprintFactory.getLogoComponents();

      _.each(logoComponents, (logoComponent) => {
        var component = _.find(attributeData.components, {
          id: logoComponent.id
        });

        if (component && component.isLogo !== false) {
          component.metadata = this.brandingFactory.brandingSettings.logoFileMetadata;
        }
      });
    }
  }

  _postAttributeData() {
    var attributeData = _.cloneDeep(this.templateEditorFactory.presentation.templateAttributeData);

    this._updateLogoData(attributeData);

    this.storageCacheBusterService.updateStorageFileTimestamps(attributeData);

    var message = {
      type: 'attributeData',
      value: attributeData
    };
    this._postMessageToTemplate(message);
  }

  _postStartEvent() {
    this._postMessageToTemplate({
      type: 'sendStartEvent'
    });
  }

  _postDisplayData() {
    var company = this.companyStateService.getCopyOfSelectedCompany(true);

    var message = {
      type: 'displayData',
      value: {
        displayAddress: {
          city: company.city,
          province: company.province,
          country: company.country,
          postalCode: company.postalCode
        },
        companyBranding: {
          logoFile: this.brandingFactory.brandingSettings.logoFile,
          baseColor: this.brandingFactory.brandingSettings.baseColor,
          accentColor: this.brandingFactory.brandingSettings.accentColor
        }
      }
    };
    this._postMessageToTemplate(message);
  }

  _postMessageToTemplate(message) {
    if (!this.iframeLoaded) {
      return;
    }

    let iframe = this._getIframe();

    iframe.contentWindow.postMessage(JSON.stringify(message), TemplateEditorService.HTML_TEMPLATE_DOMAIN);
  }


}
