import { Component, DoCheck } from '@angular/core';

import { PlaceholderService } from '../../services/placeholder.service';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements DoCheck {

  public showPlaylist = true;
  private _oldPlaceholder;

  constructor(public placeholderService: PlaceholderService) { }

  ngDoCheck(): void {
    if (this._oldPlaceholder !== this.placeholderService.placeholder) {
      this._oldPlaceholder = this.placeholderService.placeholder;
      this.showPlaylist = true;
    }
  }
}
