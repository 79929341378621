import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';

import { EditorService } from '../../services/editor.service';
import { ArtboardService } from '../../services/artboard.service';
import { PlaceholderService } from '../../services/placeholder.service';
import { StateService } from '@uirouter/angular';

@Component({
    selector: 'artboard-presentation',
    templateUrl: './artboard-presentation.component.html',
    styleUrls: ['./artboard-presentation.component.scss'],
    standalone: false
})
export class ArtboardPresentationComponent implements OnInit {

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private stateService: StateService,
    public editorService: EditorService,
    public artboardService: ArtboardService,
    public placeholderService: PlaceholderService) { }

  ngOnInit(): void {
    this.artboardService.zoomFit();
  }

  mouseWheelFunc(e) {
    if (e.ctrlKey) {
      e.preventDefault();

      if (e.detail < 0 || e.wheelDelta > 0) {
        this.artboardService.zoomOut();
      } else {
        this.artboardService.zoomIn();
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event);
  }

  showEmptyState() {
    return !this.editorService.presentation.id && !this.editorService.hasUnsavedChanges &&
      !this.stateService.params.copyOf;
  }

  isPlaceholderSelected(placeholder: any) : boolean {
    return placeholder === this.placeholderService.placeholder;
  }

}
