<!-- Presentation Name -->
<stretchy-input class="presentation-name" [(ngModel)]="templateEditorFactory.presentation.name" ngDefaultControl></stretchy-input>
<div>
  <div class="btn-group" dropdown>
    <button id="moreSettingsButton" type="button"
      class="dropdown-toggle tw-flex tw-items-center tw-justify-center tw-ml-2 tw-rounded-full tw-p-2 tw-border tw-border-solid tw-border-gray-200 tw-bg-transparent focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
      [matMenuTriggerFor]="moreMenu" aria-label="More options">
      <mat-icon svgIcon="more-vertical" class="tw-rotate-90" />
    </button>
    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item id="changeTemplateButton" uiSref="apps.editor.add">New Presentation</button>
      <button mat-menu-item
      id="copyPresentationButton"
      [attr.disabled]="!templateEditorFactory.presentation.id"
      (click)="templateEditorFactory.copyPresentation()">Duplicate</button>
      <button mat-menu-item
      *requireRole="'!ap'"
      id="sharePresentationButton"
      [attr.disabled]="!templateEditorFactory.presentation.id"
      (click)="openSettings()">Save Presentation As Template</button>
      <button
        mat-menu-item
        id="deleteButton"
        [disabled]="!templateEditorFactory.presentation.id"
        (click)="confirmDelete()">Delete</button>
    </mat-menu>
  </div>
</div>
<div class="flex-row ml-auto hidden-xs">
  <div class="auto-save pr-4">
    <last-modified [changeDate]="templateEditorFactory.presentation.changeDate" [changedBy]="templateEditorFactory.presentation.changedBy"></last-modified>
    •
    @if (templateEditorFactory.hasContentEditorRole()) {
      <span id="autoSavingDesktop">
        <span [shown]="!templateEditorFactory.savingPresentation && templateEditorFactory.isUnsaved()">
          Unsaved changes
        </span>
        <span [shown]="templateEditorFactory.savingPresentation">
          Saving changes...
        </span>
        <span [shown]="!templateEditorFactory.savingPresentation && !templateEditorFactory.isUnsaved()">
          All changes saved <streamline-icon aria-hidden="true" name="checkmark" width="12" height="12"></streamline-icon>
        </span>
      </span>
    }
    @else {
      <span class="text-danger">
        You don’t have permission to edit
      </span>
    }
  </div>
  <button tabindex="0" id="publishButtonDesktop" *requireRole="'cp ap'" class="btn btn-primary btn-fixed-width"
    [disabled]="templateEditorFactory.isPublishDisabled()"
    (click)="templateEditorFactory.publish()">
    Publish
  </button>
</div>
