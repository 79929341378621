import { Component } from '@angular/core';

import { ModalService } from 'src/app/components/modals/modal.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { ComponentsService } from '../../services/components.service';

@Component({
    selector: 'template-editor-toolbar',
    templateUrl: './template-editor-toolbar.component.html',
    styleUrls: ['./template-editor-toolbar.component.scss'],
    standalone: false
})
export class TemplateEditorToolbarComponent {

  constructor(private modalService: ModalService,
    public templateEditorFactory: TemplateEditorService,
    public componentsService: ComponentsService) {}

  confirmDelete() {
    this.modalService.confirmDanger('Are you sure you want to delete this Presentation?',
      null,
      'Delete Forever'
    ).then(() => {
      this.templateEditorFactory.deletePresentation();
    });
  }

  openSettings() {
    let page = this.componentsService.getCurrentPage();
    if (!page || page.type !== 'rise-template-share-settings') {
      this.componentsService.editComponent({ type: 'rise-template-share-settings' });
    }
  }

}
