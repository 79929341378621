import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeAgo',
    standalone: false
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: Date | null): string {
    if (!value) {
      return '';
    }

    const now = new Date();
    const diffMs = now.getTime() - value.getTime();

    if (diffMs < 0) {
      return 'In the future';
    }

    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    let months = now.getMonth() - value.getMonth();
    let years = now.getFullYear() - value.getFullYear();
    if (months < 0) {
      years -= 1;
      months += 12;
    }
    months += years * 12;
    if (now.getDate() < value.getDate()) {
      months -= 1;
    }

    if (seconds < 10) {
      return 'A few seconds ago';
    } else if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (days < 7) {
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else if (months < 1) {
      return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
    } else if (years < 1) {
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    } else {
      return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    }
  }
}
