import { Component, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CompanyAllocationService } from '../../services/company-allocation.service';
import { Transition, TransitionService } from '@uirouter/angular';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
    selector: 'company-license-totals',
    templateUrl: './company-license-totals.component.html',
    styleUrls: ['./company-license-totals.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class CompanyLicenseTotalsComponent implements OnDestroy {
  private transitionHook: Function;
  private bypassUnsaved: boolean = false;

  constructor(
    public companyAllocationService: CompanyAllocationService,
    private transitionService: TransitionService,
    private modalService: ModalService,
    private parentForm: NgForm,
    public companyStateService: CompanyStateService
  ) {
    this.transitionHook = this.transitionService.onStart({}, (transition: Transition) => {
      if (this.bypassUnsaved) {
        return;
      }
      if (this.parentForm.dirty) {
        transition.abort();

        this.modalService.confirmDanger('Unsaved Changes',
          'You have unsaved changes. Do you want to Discard them?',
          'Discard',
          'Cancel'
        )
        .then(() => {
          this.bypassUnsaved = true;

          transition.router.stateService.go(transition.to().name, transition.params());
        })
        .catch(() => {});
      }
    });
  }

  ngOnDestroy(): void {
    this.transitionHook();
  }

}
