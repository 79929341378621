import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ComponentsService } from '../../services/components.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscriber } from 'rxjs';
import { StockLibraryService, type StockLibraryFile } from '../services/stock-library.service';
import { FileDownloader } from 'src/app/ajs-upgraded-providers';
import { UserService } from 'src/app/common-header/services/user.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'template-component-stock-library',
    templateUrl: './stock-library.component.html',
    styleUrl: './stock-library.component.scss',
    standalone: false
})
export class StockLibraryComponent implements OnInit {

  subscriber: Subscriber<StockLibraryFile>;
  orientation = 'horizontal';
  fileType: 'image' | 'video';
  filterConfig = {
    placeholder: 'Search from Pixabay'
  };
  search = {
    query: ''
  };
  total = 0;
  page = 1;
  loading = false;
  results = [];
  selectedIds: string[] = [];
  boundSearch = this.doSearch.bind(this);
  termsAccepted = false;
  fileUploadList: StockLibraryFile[];
  uploadManager: any;
  isUploading = false;
  isMediaSelector = false;
  offset = 0;

  @ViewChild('resultsGrid') resultsGrid: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private componentsService: ComponentsService,
    private httpClient: HttpClient,
    private userStateService: UserStateService,
    private userService: UserService,
    private fileDownloader: FileDownloader,
    private changeDetectorRef: ChangeDetectorRef,
    private attributeDataService: AttributeDataService
  ) {}

  ngOnInit(): void {
    this.componentsService.registerDirective({
      type: 'rise-stock-library',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.selectedIds = [];
        this.fileType = options?.fileType === 'video' ? 'video' : 'image';
        this.subscriber = options?.subscriber;
        this.isMediaSelector = options.isMediaSelector || false;
        this.doSearch();
      }
    });

    this.uploadManager = {
      onUploadStatus: (isUploading) => {
        this.isUploading = isUploading;
      },
      addFile: (file) => {
        this.subscriber.next(file);

        const data = this.attributeDataService.getAttributeData(this.componentsService.selected.id);
        const customization = data.customization || {};
        const updatedCustomization = {...customization, ...{
          top: (430 + this.offset) + 'px',
          left: (700 + this.offset) + 'px'
        }};
        this.attributeDataService.setAttributeData(
          this.componentsService.selected.id,
          'customization',
          updatedCustomization
        );
        this.offset += 30;

        if (this.isMediaSelector) {
          this.componentsService.showPreviousPage();
        }
      },
      isSingleFileSelector: () => true,
      skipOverwrite: true
    };

    this.termsAccepted = !!this.userStateService.getCopyOfProfile().settings?.pixabayTermsAccepted;
  }

  acceptTerms() {
    this.loading = true;
    this.userService.updateUser(this.userStateService.getUsername(), {
      settings: {
        pixabayTermsAccepted: true
      }
    }).then(() => {
      this.termsAccepted = true;
    }).finally(() => {
      this.loading = false;
    });
  }

  searchAssets() {
    this.loading = true;
    this.changeDetectorRef.detectChanges();

    let url;
    if (this.search.query || this.page > 4) {
      url = `${
          StockLibraryService.PIXABAY_API_URL
        }${
          this.fileType === 'video' ? 'videos/' : ''
        }?key=${
          environment.PIXABAY_API_KEY
        }${
          this.search.query ? `&q=${encodeURIComponent(this.search.query)}` : '&editors_choice=true'
        }${
          this.fileType === 'image' ? `&orientation=${this.orientation}` : ''
        }&safesearch=true&page=${
          this.page
        }&per_page=50`;
    } else {
      url = `${StockLibraryService.GCS_STORAGE_BUCKET}${
        this.fileType === 'image' ?
          this.orientation === 'horizontal' ?
            'images-landscape'
          : 'images-portrait'
        : 'videos'
      }.json`;

      if (this.page === 1) {
        this.page = 4;
      }
    }

    this.httpClient.get(url, {
        observe: 'response',
        responseType: 'json'
    }).subscribe((result) => {

      if (result.status === 429) {
        // Pixabay rate limit exceeded, wait 3s then try again
        setTimeout(() => {
          this.searchAssets();
        }, 3000);

      } else {
        if (this.search.query && this.page === 1 || !this.search.query && this.page < 5) {
          this.results = (result.body as any).hits;
          this.resultsGrid.nativeElement.scrollTop = 0;
        } else {
          this.results = this.results.concat((result.body as any).hits);
        }
        this.total = +(result.body as any).total;

        // Let some images load before hiding the spinner
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    });
  }

  doSearch() {
    this.page = 1;
    this.searchAssets();
  }

  loadResults() {
    if (this.results.length < this.total) {
      this.page += 1;
      this.searchAssets();
    }
  }

  private parseTags(tags: string): string {
    return tags.split(',').map((item) => item.trim().replaceAll(' ', '-')).join('-');
  }

  selectAsset(id: string) {
    if (this.termsAccepted) {
      const selected = this.results.find((result) => result.id === id);
      if (selected) {
        const pageURL = selected.pageURL.replace(/\/$/, '');
        // Images have descriptive pageURLs, but videos do not,
        // so we prepend the video's tags to make a more descriptive filename.
        const filename =
          (this.fileType === 'image' ? '' : this.parseTags(selected.tags) + '-') +
          pageURL.substring(pageURL.lastIndexOf('/') + 1) +
          (this.fileType === 'image' ? selected.largeImageURL.substring(selected.largeImageURL.lastIndexOf('.')) : '.mp4');

        this.loading = true;
        this.fileDownloader(this.fileType === 'image'
          ? selected.largeImageURL
          : selected.videos.medium.url,
          'Stock Files/' + filename
        )
          .then((file) => {
            this.fileUploadList = [file];
          })
          .catch((err) => {
            console.error(`Could not upload stock ${this.fileType}.`, err);
          })
          .finally(() => {
            this.loading = false;
          });

        this.selectedIds.push(id);
      }
    }
  }

  done() {
    this.componentsService.showPreviousPage();
    if (this.isMediaSelector) {
      this.componentsService.showPreviousPage();
    }
  }
}
