import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import {ControlContainer, FormsModule, NgForm, NgModel} from '@angular/forms';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  imports: [
    FormsModule
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  standalone: true
})
export class TimeInputComponent implements OnChanges {

  @Input() min?: number = 1;
  @Input() max?: number;
  @Input() name?: string;
  @Input() default?: number = 5;
  @Input() label?: string = 'Minutes';
  @ViewChild('timeInput') timeInput: NgModel;

  _value: number;

  @Input()
  get value(): number {
    return this._value * 60;
  }
  set value(seconds: number) {
    let minutes = seconds ? Math.round(seconds / 60) : 1;
    let validValue = minutes === undefined || minutes === null ? 1 : minutes;
    validValue = Math.max(this.min, validValue);
    this._value = Math.round(validValue);
  }

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Input() disabled: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue === undefined && this.default !== undefined) {
      this.onChange(this.default);
    }
  }

  onChange(newMinutes?: number) {
    if (newMinutes !== undefined) {
      this._value = newMinutes;
    }

    if (this._value < this.min) {
      this._value = this.min;
    }

    const seconds = this._value * 60;
    this.valueChange.emit(seconds);
  }
}
