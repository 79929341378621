import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, AfterViewInit, Output, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { DisplayControlService } from 'src/app/displays/services/display-control.service';
import { PlayerActionsService } from 'src/app/displays/services/player-actions.service';
import { PlayerLicenseService } from 'src/app/displays/services/player-license.service';
import { PlayerProService } from 'src/app/displays/services/player-pro.service';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { environment } from 'src/environments/environment';
import { DisplayService } from '../../services/display.service';
import { UpgradeNoticeComponent } from 'src/app/components/plans/upgrade-notice/upgrade-notice.component';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { CryptoUtilsService } from 'src/app/shared/services/crypto-utils.service';

@Component({
    selector: 'display-fields',
    templateUrl: './display-fields.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./display-fields.component.scss'],
    standalone: false
})
export class DisplayFieldsComponent implements OnInit, AfterViewInit {

  @Input() selectedSchedule: any = {};
  @Input() displayControlType: string;
  @Output() selectedScheduleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayControlTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChildren('UpgradeNotice') notices: QueryList<UpgradeNoticeComponent>;
  @ViewChild('TabGroup') tabGroup: MatTabGroup;

  isMobile = this.presentationUtilsService.isMobileBrowser();
  hideScreenshot = this.isMobile;
  isMonitoringAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_MONITORING);
  isDisplayControlAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_DISPLAY_CONTROL);
  isClassroomAlertsAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_CLASSROOM_ALERTS);
  isScreenSharingAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SCREEN_SHARING);
  showWebPlayer = false;
  timePickerKey = false;
  displayForm: NgForm;
  selectedTabIndex = 0;

  private _screenId;
  get screenId() {
    if (!this._showScreenId()) {
      return undefined;
    } else if (!this._screenId && this.displayService.display.id) {
      this._screenId = this.displayService.display.id;
      this._generateScreenId();
    }

    return this._screenId;
  }

  static readonly SCREEN_ID_COMPANIES = [
    'f114ad26-949d-44b4-87e9-8528afc76ce4', // Rise Vision Production
    'b428b4e8-c8b9-41d5-8a10-b4193c789443', // Rise Vision Staging
    '15839198-3483-41b5-ac34-e5f6959b6cb3', // Jenkins company
    'a83b6404-f4f5-400c-830c-4a9fb6c3d4f1', // Customer
    '14882e57-688a-46dc-babe-6ec2097fbfd0', // VenuEdge
    '2a40a480-ae66-4f53-a73f-0e01d8744c9b' // VenuEdge
  ];

  constructor(
    public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
    public playerActionsService: PlayerActionsService,
    public playerLicenseService: PlayerLicenseService,
    public playerProService: PlayerProService,
    public displayService: DisplayService,
    private displayControlService: DisplayControlService,
    private presentationUtilsService: PresentationUtilsService,
    private featuresService: FeaturesService,
    private modalService: ModalService,
    broadcasterService: BroadcasterService,
    private trackerService: TrackerService,
    private controlContainer: ControlContainer,
    private cryptoUtilsService: CryptoUtilsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    broadcasterService.on('risevision.company.updated', () => {
      const company = companyStateService.getCopyOfSelectedCompany(true);
      displayService.display.playerProAuthorized = displayService.display.playerProAuthorized ||
        company.playerProAvailableLicenseCount > 0 && displayService.display.playerProAssigned;
    });
  }

  ngOnInit(): void {
    this.displayForm = <NgForm>this.controlContainer;
  }

  ngAfterViewInit(): void {
    UpgradeNoticeComponent.hidePopovers(this.notices);
    this.tabGroup._handleClick = this.onTabClick.bind(this);
  }

  private readonly SCREEN_SHARING_TAB_INDEX = 2;

  private canSelectTab(index: number): boolean {
    return index !== this.SCREEN_SHARING_TAB_INDEX ||
      this.isScreenSharingAvailable ||
      !this.isScreenSharingVisible;
  }


  private onTabClick(tab: MatTab, header: MatTabHeader, index: number): void {
    if (this.canSelectTab(index)) {
      this.selectedTabIndex = Math.max(0, index);
    } else {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SCREEN_SHARING);
    }
  }

  scheduleSelected () {
    this.selectedScheduleChange.emit(this.selectedSchedule);

    this.displayService.confirmAssignLicense(this.selectedSchedule);
  }

  confirmLicensing () {
    return this.modalService.confirm('Assign license?',
        'Do you want to assign one of your licenses to this display?',
        'Yes', 'No')
      .then(() => {
        // Toggle license as if they clicked the checkbox
        this.displayService.display.playerProAuthorized = true;

        this.displayService.toggleProAuthorized();
      });
  }

  getWebPlayerUrl () {
    if (!this.displayService.display) {
      return '';
    }
    return environment.VIEWER_URL + '/?type=webplayer&id=' + this.displayService.display.id;
  }

  copyToClipboard (text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text);
    }
  }

  openTimePicker ($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  configureDisplayControl () {
    this.displayControlService.openDisplayControlModal(false);
  }

  toggleDisplayControl() {
    this.displayControlService.toggleDisplayControl();
  }

  trackDisplayMonitoring () {
    this.trackerService.displayEvent('Display Monitoring Updated',
      this.displayService.display.id,
      this.displayService.display.name,
      {
        monitoringEnabled: this.displayService.display.monitoringEnabled
      }
    );

    if (
      this.displayService.display.monitoringEnabled
      && this.displayService.display.settings
      && this.displayService.display.settings.setAsScreenSaver
    ) {
      this.displayService.display.settings.setAsScreenSaver = false;
    }
  }

  toggleScreensaver() {
    if (
      this.displayService.display.settings.setAsScreenSaver
      && this.displayService.display.monitoringEnabled
    ) {
      this.displayService.display.monitoringEnabled = false;
    }
  }

  promptUpgrade (feature) {
    this.featuresService.showUpgradePlanModal(feature);
  }

  getResolution() {
    if (this.playerProService.isAvocorRSeriesDisplay(this.displayService.display)) {
      return "3840x2160";
    }
    return `${this.displayService.display.width}x${this.displayService.display.height}`;
  }

  detectChanges(): void {
    this.changeDetectorRef.detectChanges();
    this.displayControlTypeChange.emit(this.displayControlType);
    if (this.displayControlType === 'hdmi-cec') {
      this.displayControlService.setDisplayControlToCec();
    }
    if (this.displayControlType === 'rs-232') {
      this.displayControlService.openDisplayControlModal();
    }
  }

  private get isPlayerVersionSetOrOnline(): boolean {
    return (
      Boolean(this.displayService.display.playerVersion)
      || this.displayService.display.onlineStatus === 'online'
    );
  }

  protected get shouldShowRotationOption() {
    return this.isPlayerVersionSetOrOnline
      && this.playerProService.isDeviceOrientationCompatiblePlayer(
        this.displayService.display
      );
  }

  protected get isMonitoringEnabledAndAvailable(): boolean {
    return (
      !this.displayService.showLicenseRequired()
      && this.displayService.display.monitoringEnabled
      && this.isMonitoringAvailable
    );
  }

  protected get isScreenSharingVisible(): boolean {
    return this.playerProService.isScreenSharingCompatiblePlayer(this.displayService.display);
  }

  _showScreenId(): boolean {
    return DisplayFieldsComponent.SCREEN_ID_COMPANIES.includes(this.companyStateService.getSelectedCompanyId());
  }

  async _generateScreenId() {
    const companyId = this.companyStateService.getSelectedCompanyId();
    const displayId = this.displayService.display.id;
    const combinedValues = `${displayId}:${companyId}`;

    try {
      this._screenId = await this.cryptoUtilsService.sha256base64hash(combinedValues);
    } catch (error) {
      console.log('Failed to generate hash:', error);
    }
  }
}
