import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { UserStateService } from 'src/app/auth/services/user-state.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';
import { environment } from 'src/environments/environment';
import { CompanyIcpService } from '../../services/company-icp.service';
import { checkCookies } from '../../services/cookie-tester.service';

@Component({
    selector: 'common-header',
    templateUrl: './common-header.component.html',
    styleUrls: ['./common-header.component.scss'],
    standalone: false
})
export class CommonHeaderComponent implements OnInit {

  @Input() hideNavigation: boolean = true;

  ENV_NAME = environment.ENV_NAME;
  cookieEnabled = true;

  @Output() openMobileMenu = new EventEmitter<void>();

  get isScreenShareApp(): any {
    return this.rvShareAppDetection.isScreenShareApp();
  }

  get isAssignedPublisher(): any {
    return this.userStateService.isAssignedPublisher();
  }

  constructor(
    public userStateService: UserStateService,
    private companyIcpService: CompanyIcpService,
    private rvShareAppDetection: RvshareAppDetectionService
  ) {
  }

  ngOnInit(): void {
    this.companyIcpService.init();

    if (!checkCookies()) {
      this.cookieEnabled = false;
    }
  }
}