import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ScheduleService } from '../../services/schedule.service';
import { ScheduleActionsService } from '../../services/schedule-actions.service';

@Component({
    selector: 'schedule-add',
    templateUrl: './schedule-add.component.html',
    styleUrls: ['./schedule-add.component.scss'],
    standalone: false
})
export class ScheduleAddComponent {

  @ViewChild('scheduleForm')
  public scheduleForm: NgForm;

  actions = this.scheduleActionsService.getActions();

  constructor(
    public scheduleService: ScheduleService,
    private scheduleActionsService: ScheduleActionsService,
  ) {
    this.actions.saveAction = {
      id: 'saveButton',
      label: 'Save',
      action: () => this.save(),
      disabled: this.scheduleForm?.invalid
    };
  }

  save() {
    if (!this.scheduleForm.valid || this.scheduleService.hasInsecureUrls()) {
      console.error('form not valid: ', this.scheduleForm.errors);
      return;
    }

    this.scheduleService.addSchedule();
  }

}
