import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScreenSharingService, type ScreenShareMember } from '../../services/screen-sharing.service';
import { RvshareAppDetectionService } from '../../services/rvshare-app-detection.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'screen-sharing-controls',
    templateUrl: './screen-sharing-controls.component.html',
    styleUrl: './screen-sharing-controls.component.scss',
    standalone: false
})
export class ScreenSharingControlsComponent {

  @Input() isStopped: boolean;
  @Input() isPaused: boolean;
  @Input() isModerator?: boolean;
  @Input() moderatorName?: string;
  @Output() startStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() pauseStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() resumeStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() stopStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() endSession: EventEmitter<void> = new EventEmitter<void>();

  get waiting(): boolean {
    return this.screenSharingService.isModerated && !this.isModerator && this.isStopped;
  }

  constructor(
    protected screenSharingService: ScreenSharingService,
    protected rvshareAppDetectionService: RvshareAppDetectionService
  ) { }

  start() {
    this.startStream.emit();
  }

  pause() {
    this.pauseStream.emit();
  }

  resume() {
    this.resumeStream.emit();
  }

  stop() {
    this.stopStream.emit();
  }

  end() {
    this.endSession.emit();
  }

  changeVolume(value: number) {
    this.screenSharingService.changeVolume(value);
  }

  onChangeQuality(event: MatSelectChange) {
    this.screenSharingService.changeQuality(event.value);
  }
}
