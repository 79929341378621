import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayStatus',
    standalone: false
})
export class DisplayStatusPipe implements PipeTransform {

  transform(onlineStatus) {
    switch (onlineStatus) {
    case 'online':
      return 'Online';
    case 'offline':
      return 'Offline';
    default:
      return 'Not Activated';
    }
  }

}
