import { Component, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';

@Component({
    selector: 'storage-breadcrumb',
    templateUrl: './storage-breadcrumb.component.html',
    styleUrls: ['./storage-breadcrumb.component.scss'],
    standalone: false
})
export class StorageBreadcrumbComponent {

  private _originalTree: any[] = [];
  tree: any[] = [];

  @Input() set folderPath(path: string) {
    this._originalTree = [{
      path: '',
      name: 'My Storage'
    }];
    const folders = path.split('/');
    let subPath = '';
    for (let folder of folders) {
      if (folder !== '') {
        subPath += folder + '/';
        let name = folder === StorageUtilsService.TRASH_PLACEHOLDER ? StorageUtilsService.TRASH_LABEL : folder;
        name = name.length > 20 ? name.substring(0, 20) + '...' : name;
        this._originalTree.push({
          path: subPath,
          name: name
        });
      }
    }
    this._fitToWidth();
  }

  @Output() folderClicked: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('window:resize', ['$event']) onResize () {
    this._fitToWidth();
  }

  constructor (
    private elementRef: ElementRef
  ) { }

  private _fitToWidth () {
    if (this._originalTree.length > 3) {
      const spacePerFolder = 280;
      const width = this.elementRef.nativeElement.parentNode.clientWidth;
      const steps = Math.min(this._originalTree.length - 1, Math.floor(width / spacePerFolder));
      const tree = [];
      tree.push(this._originalTree[0]);
      if (steps < this._originalTree.length - 1) {
        tree.push({
          name: '...'
        });
      }
      for (let i = steps; i > 0; i--) {
        tree.push(this._originalTree[this._originalTree.length - i]);
      }
      this.tree = tree;
    } else {
      this.tree = this._originalTree;
    }
  }

  clickFolder (path: string) {
    this.folderClicked.emit(path);
  }
}