import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Transition } from '@uirouter/angular';
import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';

import { UpdateSubscriptionService } from '../../services/update-subscription.service';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';
import { BillingUtilsService } from 'src/app/billing/services/billing-utils.service';

@Component({
    selector: 'update-subscription',
    templateUrl: './update-subscription.component.html',
    styleUrls: ['./update-subscription.component.scss'],
    standalone: false
})
export class UpdateSubscriptionComponent implements OnInit {

  @Input() redirectTo;

  billingPeriod = 'annually';

  couponCode = null;
  addCoupon;

  purchaseAction;

  @ViewChild('purchaseLicensesForm')
  public purchaseLicensesForm: NgForm;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public updateSubscriptionService: UpdateSubscriptionService,
    public subscriptionService: SubscriptionService,
    public billingUtils: BillingUtilsService,
    private transition: Transition,
    public helpWidgetFactory: HelpWidgetFactory) { }

  ngOnInit(): void {
    this.purchaseAction = this.transition.params().purchaseAction;

    this.updateSubscriptionService.init(this.purchaseAction, this.transition.params().productCode, this.transition.params().monthly);
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  _isFormValid() {
    var form = this.purchaseLicensesForm;
    return !form || form.valid;
  }

  applyCouponCode() {
    if (!this._isFormValid()) {
      return;
    }

    if (this.couponCode) {
      this.updateSubscriptionService.couponCode = this.couponCode;

      this.updateSubscriptionService.getEstimate()
        .then(() => {
          if (!this.updateSubscriptionService.apiError) {
            this.addCoupon = false;
          }
        });
    }
  }

  clearCouponCode() {
    this.couponCode = null;
    this.updateSubscriptionService.couponCode = null;
    this.addCoupon = false;

    if (this.updateSubscriptionService.apiError) {
      this.updateSubscriptionService.getEstimate();
    }
  }

  getEstimate() {
    if (!this._isFormValid()) {
      return;
    }

    return this.updateSubscriptionService.getEstimate();
  }

  completePayment() {
    if (!this._isFormValid()) {
      return;
    }

    return this.updateSubscriptionService.preparePayment()
      .then(() => this.updateSubscriptionService.completePayment())
      .then(() => {
        this.updateSubscriptionService.purchases.forEach((purchase) => {
          const subscriptionItem = this.subscriptionService.items.find((item) => item.subscription.id === purchase.subscriptionId);
          if (subscriptionItem) {
            subscriptionItem.subscription.has_scheduled_changes = this.purchaseAction === 'remove';
            subscriptionItem.subscription.changed_plan_id = undefined;
            subscriptionItem.subscription.changed_plan_quantity = this.purchaseAction === 'remove' ?
              purchase.licensesToRemove :
              undefined;
          }
        });
      });
  }

  close() {
    if (this.redirectTo) {
      this.transition.router.stateService.go(
        this.transition.from(),
        this.transition.params('from')
      );
    } else {
      const subscriptionIds = this.transition.params().subscriptionId.split(',');
      this.transition.router.stateService.go('apps.billing.subscription', {
        subscriptionId: subscriptionIds[0]
      });
    }
  }

}
