<div class="tw-px-6" rvSpinner="display-loader" [rvShowSpinner]="displayService.loadingDisplay">
  <form
    #displayForm="ngForm"
    role="form"
    id="forms.displayForm"
    name="displayDetails"
    (submit)="save()"
    novalidate>
    <common-actions
      [(title)]="displayService.display.name"
      [actions]="actions"
      [saving]="displayService.savingDisplay"
      [changeDate]="displayService.display.changeDate"
      [changedBy]="displayService.display.changedBy">
    </common-actions>
    <display-fields
      [(selectedSchedule)]="selectedSchedule"
      [(displayControlType)]="displayControlType"
    />
  </form>
</div>