import { Component } from '@angular/core';

import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';

@Component({
    selector: 'purchase-footer',
    templateUrl: './purchase-footer.component.html',
    styleUrls: ['./purchase-footer.component.scss'],
    standalone: false
})
export class PurchaseFooterComponent {

  constructor(public helpWidgetFactory: HelpWidgetFactory) { }

}