import { ChangeDetectorRef, Component, ElementRef, HostListener } from '@angular/core';

import { AttributeDataService } from '../../services/attribute-data.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { ComponentsService } from '../../services/components.service';
import { WebPageUrlValidationService } from '../services/web-page-url-validation.service';
import { WebPageService } from '../services/web-page.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

@Component({
    selector: 'template-component-web-page',
    templateUrl: './web-page.component.html',
    styleUrls: ['./web-page.component.scss'],
    standalone: false
})
export class WebPageComponent {

  spinner = false;
  componentId;
  refresh;
  unload;
  zoom;
  interactive;
  scrollbars;
  src;
  validationResult;

  credentialId;
  credentialReadOnly;
  usernameReadOnly;
  username;
  password;

  authentication;

  constructor(private elementRef: ElementRef,
      private webPageService: WebPageService,
      private componentsFactory: ComponentsService,
      private attributeDataFactory: AttributeDataService,
      private webPageUrlValidationService: WebPageUrlValidationService,
      private changeDetectorRef: ChangeDetectorRef,
      private templateEditorService: TemplateEditorService,
      public featuresService: FeaturesService,) {
    componentsFactory.registerDirective({
      type: 'rise-web-page',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
        this.srcChanged();
      },
    });
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (this._directiveIsVisible() && !document.hidden) {
      this.srcChanged();
    }
  }

  _load() {
    const refresh = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'refresh');
    const unloadVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'unload');
    const zoom = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'zoom');
    const interactiveVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'interactive');
    const scrollbarsVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'scrollbars');
    const authenticationVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'authentication');
    const credentialIdVal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'credential');

    this.src = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'src');
    this.unload = !unloadVal && unloadVal !== false ? true : unloadVal;
    this.zoom = zoom ? zoom.toString() : '100';
    this.interactive = !interactiveVal && interactiveVal !== false ? false : interactiveVal;
    this.scrollbars = !scrollbarsVal && scrollbarsVal !== false ? false : scrollbarsVal;
    this.refresh = refresh ? refresh.toString() : '0';
    if (refresh && authenticationVal === true) {
      this.refresh =  refresh >= 5 ? refresh.toString() : '60';
    }

    this.authentication = !!authenticationVal;
    this.credentialId = credentialIdVal;

    delete this.username;
    delete this.password;
    if (this.webPageService.getUsernameReadOnly(credentialIdVal)) {
      this.usernameReadOnly = this.webPageService.getUsernameReadOnly(credentialIdVal);
      this.credentialReadOnly = true;
    } else {
      delete this.usernameReadOnly;
      this.credentialReadOnly = false;
    }
  }

  srcChanged() {
    if (this._validateSrcLocally()) {
      this.saveAttributeData();

      this._validateSrcRemotely();
    }
    this.changeDetectorRef.detectChanges();
  }

  _getPreviewImagePath() {
    const presentationId = this.templateEditorService.presentation.id;
    const companyId = this.templateEditorService.presentation.companyId;
    const credentialId = this.credentialId;

    return this.webPageService.getPreviewImagePath(
      companyId,
      presentationId,
      credentialId);
  }

  saveAttributeData() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'src', this.src);
    this.attributeDataFactory.setAttributeData(this.componentId, 'refresh', parseInt(this.refresh, 10));
    this.attributeDataFactory.setAttributeData(this.componentId, 'unload', this.unload);
    this.attributeDataFactory.setAttributeData(this.componentId, 'zoom', parseInt(this.zoom, 10));

    if (!this.interactive || this.zoom > 100) {
      this.scrollbars = false;
    }

    this.attributeDataFactory.setAttributeData(this.componentId, 'interactive', this.interactive);
    this.attributeDataFactory.setAttributeData(this.componentId, 'scrollbars', this.scrollbars);
    this.attributeDataFactory.setAttributeData(this.componentId, 'authentication', this.authentication);
    this.attributeDataFactory.setAttributeData(this.componentId, 'credential', this.credentialId);

    const previewImagePath = this._getPreviewImagePath();
    this.attributeDataFactory.setAttributeData(this.componentId, 'previewImagePath', previewImagePath);
  }

  onAttributeChanged() {
    this.saveAttributeData();

    this.changeDetectorRef.detectChanges();
  }

  toggleInteractive() {
    this.interactive = !this.interactive;

    this.onAttributeChanged();
  }

  toggleScrollbars() {
    this.scrollbars = !this.scrollbars;

    this.onAttributeChanged();
  }

  _forceSave() {
    this.spinner = true;

    return this.webPageService.forceSave()
      .finally(() => {
        this.spinner = false;
      });
  }

  toggleAuthentication() {
    this.authentication = !this.authentication;

    if (!this.authentication) {
      this.credentialReadOnly = true;
    } else {
      const refresh = parseInt(this.refresh, 10);
      this.refresh =  refresh >= 5 ? refresh.toString() : '60';
    }

    this.saveAttributeData();
    this._validateSrcRemotely();
  }

  editCredential() {
    this.credentialReadOnly = false;
  }

  setInterimPreviewImage(which: "loading" | "could-not-load-preview") {
    const imagePath = `apps-risevision/${which}.png`;

    this.attributeDataFactory.setAttributeData(this.componentId, 'credential', this.credentialId);
    this.attributeDataFactory.setAttributeData( this.componentId, 'previewImagePath', imagePath);
  }

  saveAuthentication() {
    if (this.credentialId) {
      this.webPageService.removeCredential(this.credentialId);
      this.saveAttributeData();
    }

    // Generate a new credentialId every time
    this.credentialId = this.webPageService.saveCredential(this.username, this.password);

    this.setInterimPreviewImage("loading");

    this._forceSave()
      .then(() => {
        this.usernameReadOnly = this.username;
        this.credentialReadOnly = true;

        delete this.username;
        delete this.password;
      })
      .then(() => this.webPageService.updatePreview(this.credentialId, this.src))
      .then(() => this.saveAttributeData())
      .catch((error) => {
        console.log("Error updating preview", error);
        this.setInterimPreviewImage("could-not-load-preview");
      });
  }

  removeCredential() {
    if (this.credentialId) {

      this.webPageService.removeCredential(this.credentialId);

      this.credentialId = null;

      this.saveAttributeData();

      this._forceSave()
        .then(() => {
          delete this.usernameReadOnly;
          this.credentialReadOnly = false;
        });
    }
  }

  _isValidUrl(url) {
    let val;

    // Add https:// if no protocol parameter exists
    if (url.indexOf('://') === -1 || url.indexOf('://') > 5) {
      url = 'https://' + url;
    }

    try {
      val = new URL(url);
    } catch (error) {
      console.log(error);
      return false;
    }

    return val.protocol === "https:";
  }

  _directiveIsVisible() {
    // This directive is instantiated once by templateAttributeEditor
    // It becomes visible when <rise-web-page> is selected
    return this.componentsFactory.selected && (this.componentsFactory.selected.type === 'rise-web-page');
  }

  _validateSrcLocally() {
    //clear the error
    this.validationResult = '';

    let _src = !this.src ? '' : this.src.trim();
    if (_src === '') {
      //empty string is allowed
      return true;
    }

    //check if url is valid and secure
    if (this._isValidUrl(_src)) {
      return true;
    }

    this.validationResult = 'INVALID_URL';
    return false;
  }

  _validateSrcRemotely() {
    this.spinner = true;

    this.webPageUrlValidationService.validate(this.src, this.authentication)
      .then( (result) => {
        this.validationResult = result;
      })
      .finally( () => {
        this.spinner = false;
        this.changeDetectorRef.detectChanges();
      });
  }
}
