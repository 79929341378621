import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PresentationUtilsService } from '../../services/presentation-utils.service';

@Component({
    selector: 'embedding-presentations-modal',
    templateUrl: './embedding-presentations-modal.component.html',
    styleUrls: ['./embedding-presentations-modal.component.scss'],
    standalone: false
})
export class EmbeddingPresentationsModalComponent {

  presentation : any = {};
  embeddingPresentations = [];
  embeddingSchedules = [];
  isHtmlPresentation = this.presentationUtils.isHtmlPresentation;

  constructor(
    public dialogRef: MatDialogRef<EmbeddingPresentationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmbeddingPresentationsModalData,
    public presentationUtils: PresentationUtilsService
  ) {
    this.presentation = data.presentation;
    this.embeddingPresentations = data.embeddingPresentations;
    this.embeddingSchedules = data.embeddingSchedules;
  }

  close() {
    this.dialogRef.close();
  }
}

interface EmbeddingPresentationsModalData {
  presentation: any;
  embeddingPresentations: any[];
  embeddingSchedules: any[];
}
