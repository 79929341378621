import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import * as $ from 'jquery';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ArtboardService } from '../services/artboard.service';
import { WidgetRendererService } from '../services/widget-renderer.service';

@Directive({
    selector: '[placeholder-resize]',
    standalone: false
})
export class PlaceholderResizeDirective implements OnInit {
  @Input() placeholder: any;

  constructor(private elementRef: ElementRef,
    private broadcaster: BroadcasterService,
    private artboardService: ArtboardService,
    private widgetRendererService: WidgetRendererService) { }

  ngOnInit() : any {
    this.createResizer('sw-resize', [this.resizeDown, this.resizeLeft]);
    this.createResizer('ne-resize', [this.resizeUp, this.resizeRight]);
    this.createResizer('nw-resize', [this.resizeUp, this.resizeLeft]);
    this.createResizer('se-resize', [this.resizeDown, this.resizeRight]);
    this.createResizer('w-resize', [this.resizeLeft]);
    this.createResizer('e-resize', [this.resizeRight]);
    this.createResizer('n-resize', [this.resizeUp]);
    this.createResizer('s-resize', [this.resizeDown]);
  }

  $mouseDown;

  resizeUp($event) {
    let lowest = this.$mouseDown.top + this.$mouseDown.height;
    let top = this.$mouseDown.top + ($event.pageY - this.$mouseDown.pageY) / this.artboardService.zoomLevel;
    top = top > lowest ? lowest : top;
    let height = this.$mouseDown.top - top + this.$mouseDown.height;

    this.placeholder.top = top;
    this.placeholder.height = height;
  }

  resizeRight($event) {
    let width = this.$mouseDown.width + ($event.pageX - this.$mouseDown.pageX) / this.artboardService.zoomLevel;
    width = width > 0 ? width : 0;

    this.placeholder.width = width;
  }

  resizeDown($event) {
    let height = this.$mouseDown.height + ($event.pageY - this.$mouseDown.pageY) / this.artboardService.zoomLevel;
    height = height > 0 ? height : 0;

    this.placeholder.height = height;
  }

  resizeLeft($event) {
    let rightest = this.$mouseDown.left + this.$mouseDown.width;
    let left = this.$mouseDown.left + ($event.pageX - this.$mouseDown.pageX) / this.artboardService.zoomLevel;
    left = left > rightest ? rightest : left;
    let width = this.$mouseDown.left - left + this.$mouseDown.width;

    this.placeholder.left = left;
    this.placeholder.width = width;
  }

  createResizer(className, handlers) {
    const element = this.elementRef.nativeElement;
    let newElement = $('<div class="' + className + ' resize"></div>');
    $(element).append(newElement);
    newElement.on('mousedown', ($event) => {
      $event.preventDefault();
      $event.stopPropagation();

      let mousemove = ($event) => {
        $event.preventDefault();
        $event.stopPropagation();
        for (let i = 0; i < handlers.length; i++) {
          handlers[i].bind(this, $event)();
        }

        this.broadcaster.emit('placeholderUpdated');
      };

      let mouseup = () => {
        $event.preventDefault();
        $event.stopPropagation();
        $(document).off('mousemove', mousemove);
        $(document).off('mouseup', mouseup);
        this.widgetRendererService.forceReload(this.placeholder, $(element));
      };

      $(document).on('mousemove', mousemove);
      $(document).on('mouseup', mouseup);

      this.$mouseDown = $event;
      this.$mouseDown.top = element.offsetTop;
      this.$mouseDown.left = element.offsetLeft;
      this.$mouseDown.width = element.offsetWidth;
      this.$mouseDown.height = element.offsetHeight;
    });
  }

}
