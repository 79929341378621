<div class="flex-row">
  <div class="input-stretchy"
    contenteditable
    [formControl]="inputControl"
    (keydown)="inputKeyDown($event)"
    (blur)="_setNonEditable()"
    name="stretchyInput"></div>
  <div>
    <button tabindex="0" id="editButton" aria-label="Set name editable" [disabled]="isEditingInput" type="button" class="btn-icon ml-3" (click)="setEditable()">
      <streamline-icon ariaLabel="Set name editable" class="streamline-component-icon" name="pencil" width="16" height="16"></streamline-icon>
    </button>
  </div>
</div>
