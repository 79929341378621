import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { EditorService } from '../../services/editor.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ArtboardService } from '../../services/artboard.service';
import { PlaceholderService } from '../../services/placeholder.service';

@Component({
    selector: 'artboard',
    templateUrl: './artboard.component.html',
    styleUrls: ['./artboard.component.scss'],
    standalone: false
})
export class ArtboardComponent implements OnDestroy {
  heightIncrement = 2 * ArtboardService.PRESENTATION_BORDER_SIZE;
  widthIncrement = 2 * ArtboardService.PRESENTATION_BORDER_SIZE;

  subscription = null;

  constructor(changeDetectorRef: ChangeDetectorRef,
    broadcaster: BroadcasterService,
    public editorService: EditorService,
    public placeholderService: PlaceholderService,
    public artboardService: ArtboardService) {
      this.subscription = broadcaster.on('placeholderUpdated', () => {
        changeDetectorRef.detectChanges();
      });
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
