import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoUtilsService {

  constructor() { }

  async sha256base64hash(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert ArrayBuffer to Uint8Array
    const hashArray = new Uint8Array(hashBuffer);

    // Convert to Base64 using modern method
    const base64Hash = btoa(String.fromCharCode(...hashArray))
      .replace(/\+/g, '-') // URL-safe base64
      .replace(/\//g, '_')
      .replace(/=+$/, ''); // Remove padding

    return base64Hash;
  }
}
