import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild, ViewChildren } from '@angular/core';

import { StateService } from '@uirouter/angular';
import { BigQueryLogging, ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { TemplateApiService } from 'src/app/api/services/template-api.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { EditorService } from '../../services/editor.service';
import { TemplatesService } from '../../services/templates.service';

import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';
import { PresentationUtilsService } from '../../services/presentation-utils.service';

import { ProductDetailsComponent } from '../product-details/product-details.component';

import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { cloneDeep } from 'lodash';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { UpgradeNoticeComponent } from 'src/app/components/plans/upgrade-notice/upgrade-notice.component';

@Component({
    selector: 'add-presentation',
    templateUrl: './add-presentation.component.html',
    styleUrls: ['./add-presentation.component.scss'],
    standalone: false
})
export class AddPresentationComponent {

  static readonly USAGE_ALL = 'ALL';
  static readonly USAGE_K12 = 'PRIMARY_SECONDARY_EDUCATION';
  static readonly POWERBI_PRESENTATION = 'full-screen-powerbi_v1';

  _defaultCount = 1000;

  isEducationCustomer = this.companyStateService.isEducationCustomer();
  isIndustrialCustomer = this.companyStateService.isIndustrialCustomer();
  isTeacher = this.userStateService.isAssignedPublisher();

  private templateUsage(item: any): number {
    if (!item.usage) {
      return 0;
    }
    const k12Usage = item.usage[AddPresentationComponent.USAGE_K12] || 0;
    return this.companyStateService.isK12Customer() ?
      k12Usage :
      Math.max(item.usage[AddPresentationComponent.USAGE_ALL] || 0, k12Usage) - k12Usage;
  }

  search: any = {
    count: this._defaultCount,
    doSearch: () => {
      this._logFilterUsage();

      if (!this.showSharedTemplates) {
        this.products.doSearch();
      } else {
        this.doSharedTemplatesSearch();
      }
    },
    templatesFilter: {},
    onListUpdate: () => {
      if (!this.search.query) {
        const unweighted = [];
        const weighted = (this.products.items.list as any[]).filter((item) => {
          if (item.defaultOrderWeight != null) {
            return true;
          }
          unweighted.push(item);
          return false;
        });
        const other = [];
        const industry = unweighted.filter((item) => {
          if (this.templatesService.isIndustryTemplate(item)) {
            return true;
          }
          other.push(item);
          return false;
        });

        industry.sort((a, b) => {
          return this.templateUsage(b) - this.templateUsage(a);
        });
        other.sort((a, b) => {
          return this.templateUsage(b) - this.templateUsage(a);
        });

        this.products.items.list = [
          ...weighted,
          ...industry,
          ...other
        ];
      } else {
        const other = [];
        const industry = (this.products.items.list as any[]).filter((item) => {
          if (this.templatesService.isIndustryTemplate(item)) {
            return true;
          }
          other.push(item);
          return false;
        });

        this.products.items.list = [
          ...industry,
          ...other
        ];
      }

      if (this._searchTemplate('Blank Presentation')) {
        this.products.items.list.push({
          name: 'Blank Presentation',
          isBlank: true
        });
      }

      this.filteredProducts = this.products.items.list;

      this._updateProductFilters();
      this.datesAvailable = this.templatesService.loadDates(this.filteredProducts);
      this.templatesService.loadUpcomingEvents(4);

      if (this.userStateService.isAssignedPublisher() && this.categoryFilters.templateCategories.find(category => category.name === 'Classroom')) {
        this.search.templatesFilter.templateCategories = ['Classroom'];
        setTimeout(() => {
          this.toggleFilter('templateCategories', 'Classroom');
          this.preloadFilter = true;
        });
      }

      this.doSearch();
    }
  };

  products = this.ScrollingListService(this.listTemplates.bind(this), this.search);

  templates = this.ScrollingListService(this.templateApiService.listPresentationTemplates.bind(this.templateApiService), {
    sortBy: 'name',
    reverse: false,
    count: this._defaultCount,
    onListUpdate: () => {
      this.doSharedTemplatesSearch();

      this.changeDetectorRef.detectChanges();
    }
  });

  filterConfig = {
    placeholder: 'Search for Templates',
    id: 'storeProductsSearchInput'
  };

  preloadFilter = false;
  datesAvailable = false;
  templateGroups: { name: string, templates: any[] }[];
  private _selectedDate: Date | null;

  get selectedDate(): Date | null {
    return this._selectedDate;
  }
  set selectedDate(value: Date | null) {
    this._selectedDate = value;
    this._logFilterUsage();
  }

  filterAppleTv = false;
  categoryFilters : any = {};
  filteredProducts = [];
  newTemplates = [];
  filtered = false;
  focusSearch: boolean = false;

  showSharedTemplates = false;
  filteredSharedTemplates = [];

  @ViewChild(UpgradeNoticeComponent) upgradeNotice: UpgradeNoticeComponent;
  @ViewChild('TemplatesGrid') templatesGrid: ElementRef;
  @ViewChildren('FilterCheckbox') filterCheckboxes: ElementRef[];

  get searchFilterKeys() {
    return Object.keys(this.search.templatesFilter);
  }

  get filteredResults() {
    return this.selectedDate || this.filtered || this.search.query || this.filterAppleTv;
  }

  get templateFilters() {
    return {
      ...this.search.templatesFilter,
      selectedDate: this.selectedDate ? this.templatesService.formatDateName(this.selectedDate) : null,
      searchQuery: this.search.query || '',
      appleTv: !!this.filterAppleTv
    };
  }

  isAlertTemplate(template: any): boolean {
    return template.templateContentTypes?.includes("Common Alert Protocol");
  }

  constructor(
    public templatesService: TemplatesService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: ModalService,
    private ScrollingListService: ScrollingListService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private editorService: EditorService,
    private templateEditorService: TemplateEditorService,
    private presentationUtils: PresentationUtilsService,
    private templateApiService: TemplateApiService,
    private trackerService: TrackerService,
    public featuresService: FeaturesService,
    private bigQueryLogging: BigQueryLogging,
    private stateService: StateService,
    private dialogRef: MatDialogRef<AddPresentationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedDate: Date }
  ) {
    if (data.selectedDate) {
      this.selectedDate = data.selectedDate;
    }
  }

  listTemplates (search, cursor): Promise<{}> {
    if (this.templatesService.templates.length && !this.search.query) {
      setTimeout(() => {
        this.focusSearch = true;
      });
      return Promise.resolve({
        items: this.templatesService.templates
      });
    } else {
      return this.templateApiService.listTemplates(search, cursor).then((result) => {
        if (!this.search.query) {
          this.templatesService.assignTemplates(result.items);
        }
        this.focusSearch = true;
        return result;
      });
    }
  }

  private _isFeaturedTemplate (template) {
    return this.templatesService.isIndustryTemplate(template) && (
      this.isTeacher && template.teacherTemplateOfTheWeek ||
      !this.isTeacher && template.templateOfTheWeek
    );
  }

  slugify(value) {
    return value.toLowerCase().replaceAll(' ', '-');
  }

  get sharedTemplatesAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SHARED_TEMPLATES);
  }

  showUpgradePlanModal(): void {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SHARED_TEMPLATES).then((result) => {
      if (result) {
        this.close();
      }
    });
  }

  _templateCategoryFilter(templates: any[], category: string): { name: string, count: number }[] {
    const categoryArrays: string[][] = templates.map(templates => templates[category]);
    const categories: string[] = [].concat.apply([], categoryArrays);

    const categoryCounts = {};
    categories.forEach(category => {
      if (category) {
        if (categoryCounts[category]) {
          categoryCounts[category] += 1;
        } else {
          categoryCounts[category] = 1;
        }
      }
    });

    return Object.keys(categoryCounts).sort().map(category => {
      return {
        name: category,
        count: categoryCounts[category]
      };
    });
  }

  _updateProductFilters() {
    if (!this.categoryFilters.templateCategories && this.products.items.list.length) {
      this.categoryFilters = {
        templateCategories: this._templateCategoryFilter(this.products.items.list, 'templateCategories'),
        templateContentTypes: this._templateCategoryFilter(this.products.items.list, 'templateContentTypes')
      };
    }
  }

  clearDate (): void {
    this.selectedDate = null;
    this.doSearch();
  }

  removeFilter (group: string, filter: string): void {
    if (this.search.templatesFilter[group]) {
      const filterIndex = this.search.templatesFilter[group].indexOf(filter);
      if (filterIndex >= 0) {
        this.search.templatesFilter[group].splice(filterIndex, 1);
        if (this.search.templatesFilter[group].length === 0) {
          delete this.search.templatesFilter[group];
        }
      }
    }
  }

  toggleFilter (group: string, filter: string): void {
    const checkboxId = 'checkbox-' + group + '-' + this.slugify(filter);
    const checkbox = this.filterCheckboxes.find(check => (check as unknown as MatCheckbox).id === checkboxId);
    if (checkbox) {
      (checkbox as unknown as MatCheckbox).toggle();
    }
  }

  clearFilter (name: string): void {
    const filter = name.split(':');
    this.removeFilter(filter[0], filter[1]);
    this.toggleFilter(filter[0], filter[1]);
    this.doSearch();
  }

  clearAllFilters (): void {
    this.searchFilterKeys.forEach((group) => {
      for (let f = this.search.templatesFilter[group].length - 1; f >= 0; f--) {
        const filter = this.search.templatesFilter[group][f];
        this.removeFilter(group, filter);
        this.toggleFilter(group, filter);
      }
    });
    this.filterAppleTv = false;
    this.clearDate();
  }

  setFilter (checkbox: MatCheckboxChange, group: string, filter: string): void {
    if (checkbox.checked) {
      if (!this.search.templatesFilter[group]) {
        this.search.templatesFilter[group] = [];
      }
      if (this.search.templatesFilter[group].indexOf(filter) < 0) {
        this.search.templatesFilter[group].push(filter);
      }
    } else {
      this.removeFilter(group, filter);
    }
    this._logFilterUsage();
    this.doSearch();
  }

  private _filterTemplate = (template: any): boolean => {
    for (let key of this.searchFilterKeys) {
      if (!template[key]) {
        return false;
      }
      for (let filter of this.search.templatesFilter[key]) {
        if (!template[key].includes(filter)) {
          return false;
        }
      }
    }
    return true;
  };

  private _searchTemplate = (template: any): boolean => {
    return !this.search?.query || JSON.stringify(template).toLowerCase().indexOf(this.search.query.toLowerCase()) !== -1;
  };

  private _logTimeout: number;
  private _logFilterUsage() {
    clearTimeout(this._logTimeout);

    this._logTimeout = setTimeout(() => {
      this.bigQueryLogging.logEvent('Template filter applied', JSON.stringify(this.templateFilters));
    }, 2000) as unknown as number;
  }

  doSearch() {
    let filteredProducts: any[];

    if (this.selectedDate) {
      filteredProducts = cloneDeep(this.templatesService.sortedTemplates);
      const index = this.templatesService.nextSortedIndex(this.selectedDate);
      if (index > 0) {
        const removed = filteredProducts.splice(0, this.templatesService.nextSortedIndex(this.selectedDate));
        filteredProducts.splice(filteredProducts.length, 0, ...removed);
      }
    } else {
      filteredProducts = this.products.items.list;
    }

    if (this.filterAppleTv) {
      filteredProducts = filteredProducts.filter(item => item.supportsAppleTV);
    }

    this.filtered = false;
    for (let key of this.searchFilterKeys) {
      if (this.search.templatesFilter[key].length > 0) {
        this.filtered = true;
        break;
      }
    }

    if (this.filtered) {
      filteredProducts = this.selectedDate ?
        filteredProducts.filter((item) => {
          item.templates = item.templates.filter((template) => {
            return this._filterTemplate(template);
          });
          return item.templates.length > 0;
        }) :
        filteredProducts.filter((item) => {
          return this._filterTemplate(item);
        });
    }

    if (!this.filtered && !this.selectedDate && !this.search.query) {
      const addNewTemplates = this.newTemplates.length === 0;
      filteredProducts = filteredProducts.filter((item) => {
        if (this._isFeaturedTemplate(item)) {
          if (addNewTemplates) {
            this.newTemplates.push(item);
          }
          return false;
        }
        return true;
      });
    }

    if (this.selectedDate) {
      this.templateGroups = filteredProducts;
      this.filteredProducts = [];
    } else {
      this.filteredProducts = filteredProducts;
    }

    this.changeDetectorRef.detectChanges();
    this.templatesGrid.nativeElement.scrollTop = 0;
  }

  doSharedTemplatesSearch() {
    let filteredTemplates;

    if (this.sharedTemplatesAvailable) {
      filteredTemplates = this.templates.items.list;
    } else {
      filteredTemplates = [];
    }

    if (this.search.query) {
      filteredTemplates = filteredTemplates.filter((item) => {
        return this._searchTemplate(item);
      });
    }

    this.filteredSharedTemplates = filteredTemplates;
  }

  toggleAppleTv() {
    this.filterAppleTv = !this.filterAppleTv;

    this.doSearch();
  }

  clearAppleTv() {
    this.filterAppleTv = false;

    this.doSearch();
  }

  toggleTemplatesSource() {
    this.showSharedTemplates = !this.showSharedTemplates;

    if (!this.showSharedTemplates) {
      this.products.doSearch();
    } else {
      this.trackerService.presentationEvent('Add Presentation from Shared Template');

      this.doSharedTemplatesSearch();
    }
  }

  requiresUpgrade(item) {
    return item.productId?.includes(AddPresentationComponent.POWERBI_PRESENTATION) && !this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_POWER_BI);
  }

  add(item) {
    if (this.requiresUpgrade(item)) {
      this.featuresService.showUpgradePlanModal(
        item.productId.includes(AddPresentationComponent.POWERBI_PRESENTATION) ?
          FeaturesService.FEATURE_POWER_BI
        : ''
      ).then((result) => {
        if (result) {
          this.close();
        }
      });

      return;
    }

    if (this.isAlertTemplate(item)) {
      this.stateService.go('apps.displays.alerts');
    }
    else if (item.id) {
      if (this.presentationUtils.isHtmlPresentation(item)) {
        this.templateEditorService.copySharedPresentation(item.id);
      } else {
        this.editorService.copyTemplate(item.id);
      }
    } else {
      this.editorService.addFromProduct(item, { ...this.templateFilters, addedFrom: 'Template Gallery' });
    }

    this.close();
  }

  select(product) {
    if (this.requiresUpgrade(product)) {
      this.featuresService.showUpgradePlanModal(
        product.productId.includes(AddPresentationComponent.POWERBI_PRESENTATION) ?
          FeaturesService.FEATURE_POWER_BI
        : ''
      ).then((result) => {
        if (result) {
          this.close();
        }
      });

      return;
    }
    if (product.id || this.isAlertTemplate(product)) {
      this.add(product);

      return;
    }

    this.modalService.showLargeDialog(
      ProductDetailsComponent,
      { product },
      { panelClass: ['product-preview'] }
    )
    .then(() => {
      this.add(product);

      this.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

}
