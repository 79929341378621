import { Component } from '@angular/core';

import { EditorService } from '../../services/editor.service';
import { ArtboardService } from '../../services/artboard.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent {

  constructor(public editorService: EditorService,
    public artboardService: ArtboardService) { }

}
