import { Component, ElementRef } from '@angular/core';

import * as _ from 'lodash';

import { EditorService } from 'src/app/editor/services/editor.service';

import { ComponentsService } from '../../services/components.service';
import { PlaylistComponentService } from '../services/playlist-component.service';

@Component({
    selector: 'component-presentation-selector',
    templateUrl: './presentation-selector.component.html',
    styleUrls: ['./presentation-selector.component.scss'],
    standalone: false
})
export class PresentationSelectorComponent {

  public componentId: string;

  filterConfig = {
    placeholder: 'Search Presentations',
    id: 'te-playlist-search'
  };

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    public playlistComponentFactory: PlaylistComponentService,
    private editorService: EditorService ) {

    this.componentsFactory.registerDirective({
      type: 'rise-presentation-selector',
      element: this.elementRef.nativeElement,
      show: () => {
        this.playlistComponentFactory.load();
      }
    });

  }

  addTemplates() {
    this.playlistComponentFactory.addTemplates();

    this.componentsFactory.showPreviousPage();
  }

  createNewTemplate() {
    this.editorService.addPresentationModal();
  }

}
