import { ChangeDetectorRef, Component, DoCheck, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import * as angular from 'angular';

import { EditorService } from '../../services/editor.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { ArtboardService } from '../../services/artboard.service';
import { PlaceholderService } from '../../services/placeholder.service';
import { StateService, TransitionService } from '@uirouter/angular';

@Component({
    selector: 'workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
    standalone: false
})
export class WorkspaceComponent implements DoCheck, OnDestroy {

  private IGNORE_FIELDS = [
    'revisionStatus',
    'revisionStatusName',
    'changeDate',
    'changedBy',
    'statusMessage',
    'subscriptionStatus'
  ];
  private subscription: any;
  private transitionHook: any;
  private _oldPresentation: any;

  private _initializing = true;
  private _bypassUnsaved = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private stateService: StateService,
    private transitionService: TransitionService,
    private broadcaster: BroadcasterService,
    private modalService: ModalService,
    private editorService: EditorService,
    public artboardService: ArtboardService,
    public placeholderService: PlaceholderService) {

    this.editorService.hasUnsavedChanges = false;

    this.subscription = this.broadcaster.subscribe({
      next: (event: String) => {
        switch (event) {
        case 'presentationUpdated':
          this._setUnsavedChangesAsync(false);
          break;
        case 'presentationDeleted':
          this._setUnsavedChanges(false);
          break;
        default:
          return;
        }
      }
    });

    window.onbeforeunload = (e: Event) => {
      if (this.editorService.hasUnsavedChanges && this.editorService.hasContentEditorRole()) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = true;
      }
    };

    this.transitionHook = this.transitionService.onStart({
      to: (state: any) => state.name.indexOf('apps.editor.workspace') === -1
    }, (trans: any) => {
      if (this._bypassUnsaved) {
        return;
      }
      if (this.editorService.hasUnsavedChanges && this.editorService.hasContentEditorRole()) {
        trans.abort();

        this.modalService.confirmDanger('Unsaved Changes',
          'You have unsaved changes. Do you want to Discard them?',
          'Discard'
        )
        .then(() => {
          this._bypassUnsaved = true;

          this.stateService.go(trans.to().name, trans.to().params);
        })
        .catch(() => {});
      }
    });
  }

  ngDoCheck(): void {
    if (!this.editorService.hasContentEditorRole()) {
      return;
    } else if (!this.editorService.presentation?.layout) {
      return;
    } else if (!this._oldPresentation || this._initializing) {
      this._oldPresentation = _.cloneDeep(this.editorService.presentation);

      if (this._initializing) {
        setTimeout(() => {
          this._initializing = false;
        });
      }
    } else if (!this._isEqualIgnoringFields(this.editorService.presentation, this._oldPresentation)) {
      this._oldPresentation = _.cloneDeep(this.editorService.presentation);

      this.editorService.hasUnsavedChanges = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.transitionHook();

    window.onbeforeunload = undefined;
  }

  _setUnsavedChanges (state: any) {
    this.editorService.hasUnsavedChanges = state;

    this.changeDetectorRef.detectChanges();
  };

  _setUnsavedChangesAsync (state: any) {
    setTimeout(() => {
      this._setUnsavedChanges(state);
    });
  };

  _isEqualIgnoringFields(o1, o2) {
    if (typeof o1 === 'object') {
      if (typeof o2 === 'object') {
        if (angular.isArray(o1)) {
          if (!angular.isArray(o2)) {
            return false;
          }
          if (o1.length !== o2.length) {
            return false;
          }
          for (var k = 0; k < o1.length; k++) {
            if (!this._isEqualIgnoringFields(o1[k], o2[k])) {
              return false;
            }
          }
          return true;
        } else {
          for (var j in o1) {
            if (this.IGNORE_FIELDS.indexOf(j) === -1 && j.charAt(0) !== '$') {
              if (!this._isEqualIgnoringFields(o1[j], o2[j])) {
                return false;
              }
            }
          }
          return true;
        }
      } else {
        return false;
      }
    } else {
      if (!o1 && !o2) {
        return true;
      }

      return angular.equals(o1, o2);
    }
  }

}
