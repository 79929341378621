import { Component } from '@angular/core';
import { Transition } from '@uirouter/angular';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { BillingApiService } from 'src/app/api/services/billing-api.service';
import { InvoiceService } from '../../services/invoice.service';

@Component({
    selector: 'unpaid-invoices',
    templateUrl: './unpaid-invoices.component.html',
    styleUrls: ['./unpaid-invoices.component.scss'],
    standalone: false
})
export class UnpaidInvoicesComponent {

  unpaidInvoices = this.scrollingListService(this.billingApiService.getUnpaidInvoices.bind(this.billingApiService), {
    companyId: this.transition.params().cid,
    token: this.transition.params().token.replaceAll(" ", "+"),
    name: 'Unpaid Invoices'
  });

  constructor(
    private transition: Transition,
    private scrollingListService: ScrollingListService,
    private billingApiService: BillingApiService,
    public invoiceService: InvoiceService
  ) {
    this.invoiceService.generateToken();
  }
}
