<div class="container tw-p-8" rvSpinner="screen-share-loader" [rvShowSpinner]="!availableDisplays">
  @if (availableDisplays) {
    @if (!userStateService.isModerator() || availableDisplays.length < 1) {
      <div class="moderator-notice aligner flex-col">
        @if (!userStateService.isModerator()) {
          @if (userStateService.isUserAdmin()) {
            <img src="/images/screen-sharing/permissions.png" width="200" height="200" alt="User Permissions">
            <h4>Permission Needed</h4>
            <p class="align-center">
              Good news! You are an admin, and can give yourself permission to use Screen Share Moderation. Please go to User Settings and check the role “Screen Share Moderator”.
            </p>
            @if (!rvshareAppDetectionService.isScreenShareApp()) {
              <a mat-flat-button color="primary" class="my-4 px-4" uiSref="apps.user.details">See My User Settings</a>
            }
          }
          @else {
            <img src="/images/screen-sharing/padlock.png" width="200" height="200" alt="Permission Needed">
            <h4>Permission Needed</h4>
            <p class="">
              You don&rsquo;t have permission to use Screen Share Moderated Session. Please ask the admin to assign you the role of “Screen Share Moderator.”
            </p>
          }
        }
        @else {
          <img src="/images/screen-sharing/settings.png" width="200" height="200" alt="Display Settings">
          <h4>No Displays with Moderator Mode</h4>
          <div>
            <p>
              To enable Moderator Mode:
            </p>
            <ol>
              <li>Go to <strong>Displays</strong> and select a display.</li>
              <li>In the General tab, toggle <strong>License this display</strong>.</li>
              <li>In the Screen Sharing tab, toggle <strong>Moderator Mode</strong>.</li>
            </ol>
          </div>
          @if (!rvshareAppDetectionService.isScreenShareApp()) {
            <a mat-flat-button color="primary" class="my-4 px-4" uiSref="apps.displays.list">See Displays</a>
          }
        }
      </div>
    }
    @else {
      <div class="moderator-join-layout">
        <div>
          @if (screenSharingService.channelError) {
            <div class="madero-style alert alert-danger mb-3">
              <div class="flex flex-row align-start">
                <mat-icon aria-hidden="true" fontIcon="fa-times-circle" class="icon-left"></mat-icon>
                <p class="mb-0">
                  {{ screenSharingService.channelError }}
                </p>
              </div>
            </div>
          }
          <p class="py-4">Choose a display to start a moderated session and invite participants to share their screen.</p>
        </div>
        <div>
          <div class="border-container light-border">
            <table mat-table [dataSource]="availableDisplays">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="pl-2">Display Name</div>
                </th>
                <td mat-cell *matCellDef="let display">
                  <div class="flex flex-row gap-2">
                    @if (savingFavorite === display.id) {
                      <mat-spinner diameter="15" class="ml-2" style="margin-right: 6.5px"></mat-spinner>
                    }
                    @else {
                      <button
                        mat-icon-button
                        class="small-icon-button mr-1"
                        (click)="$event.stopPropagation();toggleFavorite(display.id)"
                        [attr.aria-label]="isFavored(getDisplay(display.id)) ? 'Remove from Favorites' : 'Add to Favorites'"
                      >
                        @if (isFavored(getDisplay(display.id))) {
                          <mat-icon fontIcon="fa-star" class="rise-blue"></mat-icon>
                        }
                        @else {
                          <mat-icon fontIcon="fa-star-o" class="rise-gray"></mat-icon>
                        }
                      </button>
                    }
                    <div>{{ display.name }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef width="180">Status</th>
                <td mat-cell *matCellDef="let display">
                  <div class="flex flex-row gap-2 font-weight-bold">
                    <div class="flex-grow u_capitalize" [ngClass]="{ 'rise-green': display.onlineStatus === 'online', 'rise-gray': display.onlineStatus !== 'online' }">
                      {{ display.onlineStatus.replace('_', ' ') }}
                    </div>
                    @if (display.onlineStatus === 'online') {
                      <a mat-icon-button aria-label="Start Session" class="u_lh-1" uiSref="apps.screen-sharing.moderator-room" [uiParams]="{displayId: display.id, rvshare: rvshareAppDetectionService.isScreenShareApp()}">
                        <mat-icon aria-label="Start Session" class="large bold" svgIcon="chevron-right"></mat-icon>
                      </a>
                    }
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-row [ngClass]="{ 'u_clickable': row.onlineStatus === 'online' }" *matRowDef="let row; columns: tableColumns" [uiSref]="row.onlineStatus === 'online' ? 'apps.screen-sharing.moderator-room' : ''" [uiParams]="{displayId: row.id, rvshare: rvshareAppDetectionService.isScreenShareApp()}"></tr>
            </table>
          </div>
        </div>
      </div>
    }
  }
</div>
