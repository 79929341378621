import { Component, Input, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { SharedSchedulePopoverComponent } from '../shared-schedule-popover/shared-schedule-popover.component';

@Component({
    selector: 'share-schedule-button',
    templateUrl: './share-schedule-button.component.html',
    styleUrl: './share-schedule-button.component.scss',
    standalone: false
})
export class ShareScheduleButtonComponent {

  @Input() buttonClass: string;
  isActionSheetOpen = false;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('sharePopover') sharePopover: SharedSchedulePopoverComponent;

  constructor(
    private actionSheet: MatBottomSheet,
    private currentPlanService: CurrentPlanService
  ) {}

  openMenu() {
    if (!this.currentPlanService.isPlanActive()) {
      return this.currentPlanService.showUnlockThisFeatureModal();
    }

    this.menuTrigger.openMenu();
    this.sharePopover.focusInput();
  }

  openActionSheet() {
    if (!this.currentPlanService.isPlanActive()) {
      return this.currentPlanService.showUnlockThisFeatureModal();
    }

    const sheetRef = this.actionSheet.open(SharedSchedulePopoverComponent);
    this.isActionSheetOpen = true;

    sheetRef.instance.focusInput();
    sheetRef.instance.dismissEvent.subscribe(() => {
      this.actionSheet.dismiss();
      this.isActionSheetOpen = false;
    });
  }

  dismiss() {
    if (this.isActionSheetOpen) {
      this.actionSheet.dismiss();
    }
    if (this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }
}
