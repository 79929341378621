import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'storage-list-view',
    templateUrl: './storage-list-view.component.html',
    styleUrls: ['./storage-list-view.component.scss'],
    standalone: false
})
export class StorageListViewComponent {

  @Input() files: any[];
  @Input() folderPath: string = '';
  @Input() filesDetails;
  @Input() fileListVisible;
  @Input() search;
  @Input() selectAll: boolean = false;
  @Input() isEmpty: boolean;
  @Output() selectAllCheckboxes: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFiles: EventEmitter<void> = new EventEmitter<void>();

  constructor (
    public storageUtilsService: StorageUtilsService,
    public storageService: StorageService
  ) { }

  triggerFileClick (file) {
    this.fileClick.emit(file);
  }

  changeSort (field: string) {
    if (this.search.sortBy === field) {
      this.search.reverse = !this.search.reverse;
    } else {
      this.search.sortBy = field;
    }
  }

  get foldersAndFiles() {
    return [
      ...this.storageUtilsService.filterFolders(this.files, this.search, this.storageService.storageFull),
      ...this.storageUtilsService.filterFiles(this.files, this.search, this.storageService.storageFull, true),
    ];
  }

}