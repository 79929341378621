import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { PurchaseService } from '../../services/purchase.service';
import { PurchaseStepsService } from '../../services/purchase-steps.service';
import { AddressService } from 'src/app/shared/services/address.service';

import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
    selector: 'billing-address',
    templateUrl: './billing-address.component.html',
    styleUrls: ['./billing-address.component.scss'],
    standalone: false
})
export class BillingAddressComponent {

  billingAddress = this.purchaseService.purchase.billingAddress;
  contact = this.purchaseService.purchase.contact;

  @ViewChild('billingAddressForm')
  public billingAddressForm: NgForm;

  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  constructor(private purchaseService: PurchaseService,
    public purchaseStepsService: PurchaseStepsService,
    private addressService: AddressService) { }

  validateAddress() {
    if (!this.billingAddressForm.valid) {
      return;
    }

    this.purchaseService.loading = true;

    this.addressService.validateAddress(this.billingAddress)
      .finally(() => {
        this.purchaseService.loading = false;

        if (!this.billingAddress.validationError) {
          this.addressService.updateContact(this.contact);
          this.addressService.updateAddress(this.billingAddress, this.contact);

          this.purchaseStepsService.setNextStep();
        }
      });
  }

}