<div class="tw-pb-4">
  <div rvSpinner="schedule-loader" [rvShowSpinner]="scheduleService.loadingSchedule">

    <form #scheduleForm="ngForm" id="forms.scheduleForm" role="form" name="forms.scheduleForm" (ngSubmit)="save()" autocomplete="on" novalidate>

      <common-actions
        [(title)]="scheduleService.schedule.name"
        [actions]="actions"
        [saving]="scheduleService.savingSchedule">
      </common-actions>

      <div class="tw-px-6">
        <schedule-fields></schedule-fields>
      </div>

    </form><!-- scheduleForm -->
  </div><!--container-->
</div>
