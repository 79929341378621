import { Directive, HostListener, Input } from '@angular/core';

import * as $ from 'jquery';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { ArtboardService } from '../services/artboard.service';

@Directive({
    selector: '[placeholder-drag]',
    standalone: false
})
export class PlaceholderDragDirective {
  @Input() placeholder: any;

  constructor(private broadcaster: BroadcasterService,
    private artboardService: ArtboardService) {}

  startX = 0;
  startY = 0;
  mouseMoveBind = this.mouseMove.bind(this);
  mouseUpBind = this.mouseUp.bind(this);

  @HostListener('mousedown', ['$event']) onMouseDown(event: any) {
    event.preventDefault();
    this.startX = event.pageX - this.placeholder.left * this.artboardService.zoomLevel;
    this.startY = event.pageY - this.placeholder.top * this.artboardService.zoomLevel;
    $(document).on('mousemove', this.mouseMoveBind);
    $(document).on('mouseup', this.mouseUpBind);
  }

  mouseMove(event) {
    this.placeholder.top = (event.pageY - this.startY) / this.artboardService.zoomLevel;
    this.placeholder.left = (event.pageX - this.startX) / this.artboardService.zoomLevel;

    this.broadcaster.emit('placeholderUpdated');
  }

  mouseUp() {
    $(document).off('mousemove', this.mouseMoveBind);
    $(document).off('mouseup', this.mouseUpBind);
  }
}
