<div>
  <div class="row my-5">
    <div class="col-xs-12 col-sm-2 pb-5 pb-md-0">
      <div class="rise-logo"><img src="https://widgets.risevision.com/apps/logos/rise-logo.svg"></div>
    </div>
    <div class="col-xs-12 col-sm-10">
      <div class="button-row text-right my-0">
        <share-url-button></share-url-button>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <h4>Unpaid Invoices</h4>
  </div>
  <div rvSpinner="unpaid-invoice-loader" [rvShowSpinner]="unpaidInvoices.loadingItems"></div>
  <div id="errorBox" [shown]="unpaidInvoices.apiError" class="madero-style alert alert-danger" role="alert">
    <p><strong>{{unpaidInvoices.errorMessage}}</strong></p>
    {{unpaidInvoices.apiError}}
  </div>
  <div id="errorBox" [shown]="invoiceService.apiError" class="madero-style alert alert-danger" role="alert">
    <strong>{{invoiceService.apiError}}</strong>
  </div>
  <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom" scrollingList (scrollEndEvent)="unpaidInvoices.load()">
    <table id="unpaidInvoicesListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell col-xs-1">Status</th>
          <th class="table-header__cell col-xs-1">Date</th>
          <th class="table-header__cell col-xs-2">Description</th>
          <th class="table-header__cell col-xs-5">Amount</th>
          <th class="table-header__cell col-xs-2">Invoice</th>
          <th class="table-header__cell col-xs-1">&nbsp;</th>
          <th class="table-header__cell col-xs-2">Ship To</th>
        </tr>
      </thead>
      <tbody class="table-body">
        @for (item of unpaidInvoices.items.list; track item) {
          <tr class="table-body__row">
            <td class="table-body__cell">
              <streamline-icon ariaLabel="Unpaid invoice" class="status unpaid" name="exclamation" width="5" height="15" [shown]="item.invoice.status !== 'paid'"></streamline-icon>
              <streamline-icon ariaLabel="Paid invoice" class="status paid" name="checkmark" width="17" height="14" [shown]="item.invoice.status === 'paid'"></streamline-icon>
            </td>
            <td class="table-body__cell">{{item.invoice.date * 1000 | date:'d-MMM-yyyy'}}</td>
            <td class="table-body__cell font-weight-bold">
              Invoice #{{item.invoice.id}}
            </td>
            <td class="table-body__cell">
              {{(item.invoice.amount_due || item.invoice.total) / 100 | currency}}
            </td>
            <td class="table-body__cell">
              <a class="madero-link u_clickable" (click)="invoiceService.downloadInvoice(item.invoice.id)">
                <img src="../images/icon-download.svg" width="20" height="20">
              </a>
            </td>
            <td class="table-body__cell py-0">
              @if (item.invoice.status !== 'paid') {
                <a class="btn btn-default btn-pay-now" uiSref="apps.billing.invoice" [uiParams]="({invoiceId: item.invoice.id, token: invoiceService.invoiceToken})" target="_blank">Pay Now</a>
              }
            </td>
            <td class="table-body__cell">
              @if (item.invoice.billing_address?.company !== item.invoice.shipping_address?.company) {
                <span>{{item.invoice.shipping_address?.company}}</span>
              }
            </td>
          </tr>
        }
        <tr [shown]="unpaidInvoices.items.list.length === 0">
          <td colspan="6" class="text-center">You do not have any Unpaid Invoices.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="u_margin-lg text-center">
    <label>Need help with something?</label>
    <p><a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360041149991-Payment-instructions-and-common-questions-" target="_blank">Common Billing & Payment Questions</a></p>
  </div>
</div><!--container-->
