import { Component } from '@angular/core';

import { UserStateService } from 'src/app/auth/services/user-state.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';

@Component({
    selector: 'company-buttons',
    templateUrl: './company-buttons.component.html',
    styleUrls: ['./company-buttons.component.scss'],
    standalone: false
})
export class CompanyButtonsComponent {


  constructor(public userStateService: UserStateService,
    public rvshareAppDetectionService: RvshareAppDetectionService
  ) { }

  isScreenShareApp() {
    return this.rvshareAppDetectionService.isScreenShareApp();
  }

}