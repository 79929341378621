import { Component } from '@angular/core';

@Component({
    selector: 'schedules-viewport',
    templateUrl: './schedules-viewport.component.html',
    styleUrls: ['./schedules-viewport.component.scss'],
    standalone: false
})
export class SchedulesViewportComponent {

}
