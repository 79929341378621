import { Component, OnDestroy, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { RvshareAppDetectionService } from '../../services/rvshare-app-detection.service';
import { MessageType, ScreenSharingService } from '../../services/screen-sharing.service';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/components/modals/modal.service';
import { ModalStackService } from 'src/app/components/modals/modal-stack.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
    selector: 'screen-sharing',
    templateUrl: './screen-sharing.component.html',
    styleUrls: ['./screen-sharing.component.scss'],
    standalone: false
})
export class ScreenSharingComponent implements OnDestroy, OnInit {
  joinCode = '';
  joinedSubscription: Subscription;
  invitedSubscription: Subscription;
  joined: boolean;
  codeLength = 6;
  pinLength = 4;
  codeBlank: boolean;
  nameBlank: boolean;
  paused: boolean;
  stopped: boolean;
  requirePin: boolean = false;
  pinCode: string = '';
  pinMissing: boolean = false;

  @ViewChild('codeInput') codeInput: CodeInputComponent;
  @ViewChild('pinInput') pinInput: CodeInputComponent;
  @ViewChild('nameInput') nameInput: ElementRef;
  @HostListener('window:hashchange') onHashChange() {
    if (this.rvshareAppDetectionService.isScreenShareApp()) {
      const error = this.rvshareAppDetectionService.getConnectionError();

      if (error) {
        this.screenSharingService.channelError = this.rvshareAppDetectionService.getConnectionError();
        this.screenSharingService.reset();
      }
    }
  }

  get moderatorName(): string {
    if (!this.screenSharingService.isModerated) {
      return "";
    }
    const mods = this.screenSharingService.participants.filter((item) => item.memberType === "moderator");
    let label = "your moderator";
    if (mods.length === 1) {
      return mods[0].memberDisplayName ? `${label}: <strong>${mods[0].memberDisplayName}</strong>` : label;
    } else if (mods.length > 1) {
      label = `one of ${label}s`;
      const names = mods.map((item) => item.memberDisplayName).filter((item) => !!item).map((name) => `<strong>${name}</strong>`);
      return names.length > 0 ? `${label}: ${names.join(', ')}` : label;
    }
    return label;
  }

  constructor(
    protected screenSharingService: ScreenSharingService,
    protected rvshareAppDetectionService: RvshareAppDetectionService,
    private modalService: ModalService,
    protected userStateService: UserStateService
  ) { }


  ngOnInit(): void {
    this._reset();
    if (!this.rvshareAppDetectionService.isScreenShareApp()) {
      try {
        this.rvshareAppDetectionService.resetScreenShareApp();
      } catch (error) {
        console.error('Failed to reset screen share app state:', error);
      }
    }

    this.joinedSubscription = this.screenSharingService.joinedSubject.subscribe((value) => {
      if (value === MessageType.JOINED_CHANNEL) {
        this.stopped = !this.screenSharingService.mediaStream;
        this.joined = true;
        this.requirePin = false;
      } else if (value === MessageType.ANSWER) {
        this.stopped = false;
        this.paused = false;
      } else if (value === MessageType.CLIENT_STREAM_CLOSED) {
        this.stopped = true;
        this.paused = false;
      } else if (value === MessageType.CHANNEL_CLOSED || value === MessageType.ERROR) {
        this.joined = false;

        ModalStackService.closeAll();

        setTimeout(() => {
          this.codeInput.focusOnField(0);
        });
      } else if (value === MessageType.REQUEST_PIN) {
        this.requirePin = true;
      }
    });

    this.invitedSubscription = this.screenSharingService.invitedSubject.subscribe((value) => {
      if (!this.joined) { return; }

      if (value) {
        this.share();
      } else {
        this.stop();
      }
    });
  }

  _reset() {
    this.stopped = false;
    this.paused = false;
    this.rvshareAppDetectionService.resetConnectionError();
  }

  ngOnDestroy(): void {
    this.screenSharingService.reset();
    this.joinedSubscription.unsubscribe();
    this.invitedSubscription.unsubscribe();
  }

  isDisplayMediaAvailable(): boolean {
    return "navigator" in window &&
      "mediaDevices" in navigator &&
      "getDisplayMedia" in navigator.mediaDevices;
  }

  isScreenSharingSupported(): boolean {
    return this.isDisplayMediaAvailable() || this.rvshareAppDetectionService.isScreenShareApp();
  }

  join() {
    this._reset();
    if (this.requirePin) {
      this.pinMissing = (this.pinCode?.length ?? 0) < this.pinLength;
      if (this.pinMissing) {
        this.pinInput.focusOnField(this.pinCode ? this.pinCode.length : 0);
      } else {
        this.screenSharingService.join(this.joinCode, '', false, this.pinCode);
      }
    } else {
      this.codeBlank = this.joinCode.length < this.codeLength;
      this.nameBlank = !this.nameInput?.nativeElement.value;
      if (this.codeBlank) {
        this.codeInput.focusOnField(this.joinCode.length);
      } else if (this.nameBlank) {
        this.nameInput.nativeElement.focus();
      } else {
        this.screenSharingService.join(this.joinCode, this.nameInput.nativeElement.value);
      }
    }
  }

  codeChanged(code: string) {
    this.joinCode = code.toUpperCase();
  }

  codeCompleted(code: string) {
    this.codeBlank = false;
    if (!this.screenSharingService.joinCodeError) {
      this.nameInput.nativeElement.focus();
    }
  }

  pinChanged(code: string) {
    this.pinCode = code.toUpperCase();
  }

  pinCompleted(code: string) {
    this.pinMissing = false;
  }

  start() {
    this.paused = false;
    this.stopped = true;

    this.screenSharingService.start();
  }

  pause() {
    this.paused = true;
    this.stopped = false;

    this.screenSharingService.pause();
  }

  resume() {
    this.paused = false;
    this.stopped = false;

    this.screenSharingService.stream();
  }

  stop() {
    if (!this.stopped) {
      this.screenSharingService.stop();
    }

    this.stopped = true;
    this.paused = false;
  }

  end() {
    this.modalService.confirmDanger('Exit Session?',
      'You will need to enter the session code again to re-enter this room.',
      'Exit Room', 'Cancel'
    ).then(() => {
      if (this.screenSharingService.isModerated) {
        this.screenSharingService.leave();
      } else {
        this.screenSharingService.reset();
      }
    });
  }

  share() {
    this.modalService.confirm('Share your Screen?',
      'The moderator has invited you to share your screen. Please click "Share Now" to start sharing.',
      'Share Now', 'Cancel'
    ).then(() => {
      this.screenSharingService.start();
    }).catch(() => {
      this.screenSharingService.stop();
    });
  }
}
