import { Injectable } from '@angular/core';

import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  /**
   * This regex matches on 4 groups.
   * It uses Unicode character class matching (`\p{L}` and `\p{N}`) to attempt to match international domain names.
   * It also matches explicitly on ASCII ranges in case Unicode character classes are not recognized.
   *
   * (http[s]?:\/\/){0,1}               starts with http:// or https:// (may be omitted)
   * ([A-Za-z0-9\p{L}\p{N}-]+\.)+       any sequence of characters, numbers or hyphens followed by a dot (repeated one or more times)
   * ([A-Za-z0-9\p{L}\p{N}]{2,61})      any sequence of 2-61 letters or numbers (top-level domain, or last part of an IP address)
   * ([\/?#:][A-Za-z0-9\p{L}\p{N}\-_?#&:~@!$',;=\.\[\]\(\)\*\+%]*)*    valid delimiter, followed by any sequence of valid URL characters (repeated zero or more times)
   */
  static WEBSITE_REGEX = /^(http[s]?:\/\/){0,1}([A-Za-z0-9\p{L}\p{N}-]+\.)+([A-Za-z0-9\p{L}\p{N}]{2,61})([\/?#:][A-Za-z0-9\p{L}\p{N}\-_?#&:~@!$',;=\.\[\]\(\)\*\+%]*)*$/u;
  static EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  static CERTIFICATE_REGEX = /^-----BEGIN CERTIFICATE-----[\s\S]*-----END CERTIFICATE-----$/;
}

angular.module('risevision.apps.services')
  .value('WEBSITE_REGEX', ValidationService.WEBSITE_REGEX)
  .value('EMAIL_REGEX', ValidationService.EMAIL_REGEX)
  .value('CERTIFICATE_REGEX', ValidationService.CERTIFICATE_REGEX);