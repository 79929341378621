<ng-template #overridesPopover>
  @for (override of scheduleOverrides; track override) {
    <span class="u_block">{{ override }}</span>
  }
</ng-template>

<div resizeObserver (resizeEvent)="onHeaderResize($event)">
  <div class="tw-flex tw-flex-col tw-px-6 tw-w-full md:!tw-flex-row tw-justify-between md:tw-items-center tw-py-10">
    <display-license-counts showOnlineCount="true"></display-license-counts>
    <mat-slide-toggle labelPosition="before" class="md:!tw-w-fit sm:!tw-w-full" id="allCompaniesCheckbox" (ngModelChange)="toggleAllCompanies($event)" [ngModel]="allCompanies">
      Show all sub-companies
    </mat-slide-toggle>
  </div>

  <div [shown]="displays.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{displays.errorMessage}}</strong></p>
    {{displays.apiError}}
  </div>
  <mat-divider class="light" />
  <div class="tw-flex tw-flex-col md:tw-items-center md:!tw-flex-row tw-py-8 tw-px-6 tw-gap-8">
    <search-filter #searchFilter class="tw-w-full" [filterConfig]="filterConfig" [search]="search" [doSearch]="displays.doSearch"></search-filter>
    @if (userStateService.hasRole('da') && !(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)) {
      <a id="displayAddButton" uiSref="apps.displays.add" mat-flat-button color="primary" class="tw-min-w-72 tw-whitespace-nowrap">
        <mat-icon svgIcon="add" />
        Add Display
      </a>
    }
  </div>
  <batch-operations [listObject]="displays"></batch-operations>
</div>
<mat-divider class="light" />
<div
  class="tw-px-3.5"
  #displaysListContainer
  rvSpinner="displays-list-loader"
  [rvShowSpinner]="displays.loadingItems">
  <cdk-virtual-scroll-viewport
    #scrollViewport
    itemSize="68"
    class="scrollable-list horizontal-scroll rounded-rows">
    <table id="displaysListTable" class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell col-xs-4">
            <div class="flex-row fixed-width">
              <mat-checkbox class="mr-2" (click)="displays.selectAll()" [ngModel]="search.selectAll" />
              <div class="u_clickable" id="tableHeaderName" (click)="displays.sortBy('name')">
                Name
                @if (search.sortBy === 'name') {
                  <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
                }
              </div>
            </div>
          </th>
          @if (allCompanies) {
            <th id="tableHeaderCompanyName" class="table-header__cell">
              Company
            </th>
          }
          <th class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('licensed')">
            Licensed
            @if (search.sortBy === 'licensed') {
              <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
            }
          </th>
          <th id="tableHeaderStatus" class="table-header__cell col-xs-1 u_clickable" (click)="displays.sortBy('onlineStatus')">
            Status
            @if (search.sortBy === 'onlineStatus') {
              <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
            }
          </th>
          <th id="tableHeaderLastConnection" class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('lastActivityDate')">
            Last Connection
            @if (search.sortBy === 'lastActivityDate') {
              <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
            }
          </th>
          <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('scheduleName')">
            Schedule
            @if (search.sortBy === 'scheduleName') {
              <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
            }
          </th>
          <th class="table-header__cell col-xs-2 u_clickable" (click)="displays.sortBy('changeDate')">
            Last Modified
            @if (search.sortBy === 'changeDate') {
              <i class="fa" [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" [ngClass]="{'fa-caret-up': !search.reverse , 'fa-caret-down': search.reverse }"></i>
            }
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        @if (!(!displays.loadingItems && displays.items.list.length === 0 && search.query === undefined)) {
          <tr *cdkVirtualFor="let display of displays.items.list; let idx = $index"
            uiSref="apps.displays.change"
            [uiParams]="{displayId: display.id, companyId: display.companyId}"
            class="table-body__row clickable"
            [class.selected]="display.selected"
            [ngClass]="{ 'bg-lighter-gray' : selectedCompayId !== display.companyId }"
          >
            <td class="table-body__cell display-name">
              <div class="flex-row">
                <mat-checkbox class="mr-2" (click)="$event.stopPropagation(); displays.select(display)" [ngModel]="display.selected" />
                <a uiSref="apps.displays.change"
                  [uiParams]="{displayId: display.id, companyId: display.companyId}"
                  (click)="$event.stopPropagation()"
                  class="madero-link tw-font-medium u_ellipsis-lg">
                  {{display.name}}
                </a>
              </div>
            </td>
            @if (allCompanies) {
              <td id="companyName-{{display.id}}" class="table-body__cell"><span>{{display.companyName}}</span></td>
            }
            <td class="table-body__cell">
              <ng-container *ngTemplateOutlet="licenseStatus; context: { status: getLicenseStatus(display) }" />
            </td>
            <td class="table-body__cell display-status">
              @if (displayService.showLicenseRequired(display) && display.onlineStatus !== 'not_activated') {
                <span>
                  License Required
                </span>
              }
              @else {
                <ng-container *ngTemplateOutlet="onlineStatus; context: { status: display.onlineStatus }" />
              }
            </td>
            <td class="table-body__cell">
              @if (displayService.showLicenseRequired(display)) {
                <span>
                  License Required
                </span>
              }
              @else {
                <ng-container *ngTemplateOutlet="lastConnection; context: { lastActivityDate: display.lastActivityDate, onlineStatus: display.onlineStatus }" />
              }
            </td>
            <td class="table-body__cell display-schedule">
              <div class="u_nowrap flex-row">
                @if (displayService.hasSchedule(display)) {
                  <a class="u_ellipsis-md schedule-view" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: display.scheduleId, cid: display.companyId }">
                    {{display.scheduleName}}
                  </a>
                }
                @else {
                  <span class="u_ellipsis-md tw-text-gray-700">
                    {{ display.overrideScheduleNames ? 'No Default Schedule' : 'No Schedule Selected' }}
                  </span>
                }
                @if (display.overrideScheduleNames) {
                  <mat-chip class="badge schedule u_clickable ml-2"
                    [popover]="overridesPopover"
                    (onShown)="showPopover(display.overrideScheduleNames)"
                    containerClass="madero-style"
                    outsideClick="true"
                    (click)="$event.stopPropagation()"
                    placement="bottom right">
                    +{{display.overrideScheduleNames.length}}
                  </mat-chip>
                }
              </div>
            </td>
            <td class="table-body__cell">
              <change-details [changeDate]="display.changeDate" [changedBy]="display.changedBy" />
            </td>
          </tr>
        }
        <!-- If no search results -->
        @if (!displays.loadingItems && displays.items.list.length === 0) {
          <tr class="placeholder">
            <td colspan="6">
              <div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-mb-20">
                @if (search.query === undefined) {
                  <img src="../images/empty-display.svg">
                  <h3>Activate your first display</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    To see your presentations on your display, click “Add Display” and follow the instructions.</p>
                  <a id="displayAddButton"
                    uiSref="apps.displays.add"
                    mat-button
                    color="primary">
                      Add Display
                  </a>
                } @else {
                  <img src="../images/no-results.svg">
                  <h3>No results found</h3>
                  <p class="tw-block tw-w-1/2 tw-text-center tw-text-gray-600 tw-text-wrap">
                    No items match your search criteria. Try to search by another keyword.</p>
                  <button mat-button
                    color="primary"
                    (click)="searchFilter.reset()">
                      Clear results
                  </button>
                }
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #licenseStatus let-status="status">
  @if (status === 'Yes') {
    <mat-icon class="bold" svgIcon="check" />
    <span class="cdk-visually-hidden">Yes</span>
  } @else {
    <span
      class="tw-text-gray-700"
      [ngClass]="{'tw-text-green-700 tw-font-medium': status === 'Free'}">
      {{status}}
    </span>
  }
</ng-template>

<ng-template #lastConnection
  let-lastActivityDate="lastActivityDate"
  let-onlineStatus="onlineStatus">
  @if (onlineStatus === 'online') {
    <span class="tw-text-gray-700">Connected</span>
  } @else if(!lastActivityDate) {
    <span class="tw-text-gray-700">−</span>
  } @else {
    <change-details [changeDate]="lastActivityDate"></change-details>
  }
</ng-template>

<ng-template #onlineStatus let-status="status">
  <mat-chip
    class="badge"
    [ngClass]="{
      'badge-green': status === 'online',
      'badge-yellow': status === 'not_activated'
    }">
    {{status | displayStatus}}
  </mat-chip>
</ng-template>
