import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'install',
    templateUrl: './install.component.html',
    styleUrl: './install.component.scss',
    standalone: false
})
export class InstallComponent {

  constructor(public location: Location) {}
}
