import { Component, DoCheck, HostBinding, Input, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
    selector: 'thumbnail-image',
    templateUrl: './thumbnail-image.component.html',
    styleUrls: ['./thumbnail-image.component.scss'],
    standalone: false
})
export class ThumbnailImageComponent implements OnInit, DoCheck {

  @Input() folderPath: string = '';
  @Input() file: any;

  @HostBinding('class.small')
  @Input()
  small =false;

  fileDiffer: KeyValueDiffer<string, any>;
  classes = [];
  imgClasses = '';
  isDisabled = false;
  isPlaceholder = true;
  isVideo = false;
  isImage = false;
  isFolder = false;
  imgSrc = 'https://widgets.risevision.com/apps/logos/storage-file-icon%402x.png';

  constructor (
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService,
    private keyValueDiffers: KeyValueDiffers
  ) { }

  ngOnInit(): void {
    this.fileDiffer = this.keyValueDiffers.find(this.file).create();
  }

  ngDoCheck(): void {
    const fileChanges = this.fileDiffer.diff(this.file);
    if (fileChanges) {
      this.fileChanged(fileChanges);
    }
  }

  fileChanged(changes: KeyValueChanges<string, any>) {
    this.isVideo = false;
    this.isImage = false;
    this.isFolder = false;
    this.classes = ['list-item'];
    this.isPlaceholder = false;
    if (this.file.selected || this.file.isChecked) {
      this.classes.push('list-item--selected');
    }

    if (this.storageUtilsService.fileIsFolder(this.file)) {
      this.imgSrc = this.getExtensionImage('folder');
      this.isFolder = true;
      this.classes.push('list-item_folder');
    } else {
      this.classes.push('single-item');
      this.classes.push('list-type_image');
      if (this.file.isThrottled) {
        this.classes.push('list-item--throttled');
      } else {
        if (!this.storageService.canSelect(this.file) ||
          this.storageService.isDisabled(this.file)) {
          this.classes.push('list-item--disabled');
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }

        if (this.file.metadata && this.file.metadata.thumbnail) {
          this.isPlaceholder = false;
          this.isImage = true;
          this.imgSrc = this.file.metadata.thumbnail + '?_=' +
            (this.file.timeCreated && this.file.timeCreated.value);
          if (this.imgSrc.indexOf('http://') === 0) {
            this.imgSrc = this.imgSrc.replace('http://', 'https://');
          }
        } else if (this.storageUtilsService.fileIsImage(this.file)) {
          this.isPlaceholder = true;
          this.imgSrc = this.getExtensionImage('image');
        } else if (this.storageUtilsService.fileIsVideo(this.file)) {
          this.isPlaceholder = false;
          this.isVideo = true;
          this.imgSrc = this.file.mediaLink + (this.file.mediaLink.indexOf('?') > -1 ? "&t=3" : "?t=3");
        } else if (this.storageUtilsService.fileIsDocument(this.file)) {
          this.isPlaceholder = true;
          const extension = this.storageUtilsService.getFileType(this.file.name);
          this.imgSrc = this.getExtensionsDocument(extension);
        } else if (this.storageUtilsService.fileIsScript(this.file)) {
          this.isPlaceholder = true;
          this.imgSrc = this.getExtensionImage('script');
        } else if (this.storageUtilsService.fileIsFont(this.file)) {
          this.isPlaceholder = true;
          this.imgSrc = this.getExtensionImage('font');
        } else {
          this.isPlaceholder = true;
          this.imgSrc = this.getExtensionImage('other');
        }
      }
    }

    this.imgClasses = this.isDisabled && !this.file.isThrottled ?
      'disabled' : '';
  }

  private getExtensionsDocument(extension: string) {
    const ext = extension.toLowerCase();
    if (['pptx', 'ppt'].includes(ext))
      return this.getExtensionImage('ppt');
    if (['docx', 'doc'].includes(ext))
      return this.getExtensionImage('doc');
    if (['xlsx', 'xls'].includes(ext))
      return this.getExtensionImage('xls');
    return this.getExtensionImage(ext);
  }

  private getExtensionImage(extension: string) {
    const ext = extension.toLowerCase();
    return `../images/thumbnails/${ext}.svg`;
  }

}
