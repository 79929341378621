import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[focusMe]',
  standalone: true,
})
export class FocusMeDirective {

  @Input() set focusMe(trigger: boolean) {
    if (trigger) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      }, 0);
    }
  }

  constructor( private elementRef: ElementRef) {
  }

}