import { Component } from '@angular/core';
import { TransitionService } from '@uirouter/angular';
import { RouteMetadata, RouteMetadataService } from '../../services/route-metadata.service';

@Component({
    selector: 'page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
    standalone: false
})
export class PageTitleComponent {
  routeMetadata: RouteMetadata;

  constructor(
    private transitionService: TransitionService,
    private routeMetadataService: RouteMetadataService
  ) {
    this.transitionService.onSuccess({}, () => {
      this.updateTitle();
    });

    this.updateTitle();
  }

  private updateTitle(): void {
    this.routeMetadata = this.routeMetadataService.getActiveRouteMetadata();
  }
}
