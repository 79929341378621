import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FocusMeDirective } from '../focus-me/focus-me.directive';

@Component({
  selector: 'search-filter',
  standalone: true,
  imports: [NgClass, MatIconModule, FormsModule, FocusMeDirective],
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Input() filterConfig: any = {};
  @Input() search: any = {};
  @Input() doSearch!: any;
  @Input() localSearch: any = false;
  @Input() focus: boolean = false;

  searchTimeout: any = null;

  constructor() {}

  searchAction(ms: number) {
    if (this.localSearch) {
      this.doSearch();
    } else {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(this.doSearch, ms);
    }
  }

  reset() {
    if (this.search.query) {
      this.search.query = '';
      this.doSearch();
    }
  }

}