import { Component, Output, EventEmitter } from '@angular/core';

import { PurchaseService } from '../../services/purchase.service';
import { CreditCardService } from '../../services/credit-card.service';

@Component({
    selector: 'checkout-success',
    templateUrl: './checkout-success.component.html',
    styleUrls: ['./checkout-success.component.scss'],
    standalone: false
})
export class CheckoutSuccessComponent {

  purchase = this.purchaseService.purchase;
  paymentMethods = this.creditCardService.paymentMethods;

  get purchasedMultiple(): boolean {
    return this.purchaseService.purchase.volumePlan?.licenses > 0 && this.purchaseService.purchase.unlimitedPlan?.licenses > 0;
  }

  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(private purchaseService: PurchaseService,
    private creditCardService: CreditCardService) { }

}